<template>
  <el-row type="flex" :gutter="10" class="search-container">
    <el-col :span="24">
      <div class="searchForm" :class="showLabel ? 'p-3 mt-2' : ''">
        <label for="search" v-if="showLabel">اختر اسم المورد</label>
        <el-input
          readonly
          v-model="placeholder"
          @focus="resetSearch"
          v-if="supplier"
        />
        <el-select
          v-else
          @change="supplierSelected"
          ref="supplierQuery"
          class="search supplierQuery"
          v-model="value"
          loading-text="جاري تحميل البيانات ..."
          no-data-text="لا توجد بيانات متاحه"
          filterable
          popper-class="searchDropdown"
          remote
          :clearable="true"
          :placeholder="placeholder"
          :remote-method="remoteMethod"
          :loading="suppliersLoading"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: right">
              {{ item.name }} - {{ item.address }}</span
            >
          </el-option>
          <el-option value="0" label="اضافة مورد جديد">
            <el-button
              v-if="addSupplier"
              class="addCustomer"
              @click="handleSearchEnter"
            >
              إضافة مورد جديد</el-button
            >
          </el-option>
        </el-select>
      </div>
    </el-col>
    <el-dialog
      title="تسجيل مورد جديد"
      :visible.sync="addFormVisible"
      @opened="handleOpenDialog"
      @closed="handleCloseDialog"
    >
      <el-form :rules="rules" ref="supplierForm" :model="suppliersForm">
        <el-row :gutter="15" type="flex" style="flex-wrap: wrap">
          <el-col :span="8">
            <el-form-item label="الاسم" prop="name">
              <el-input
                type="text"
                class="supplier-field-name"
                ref="supplierName"
                autofocus
                v-model="suppliersForm.name"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="الرصيد" prop="balance">
              <el-input type="number" v-model="suppliersForm.balance" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="الهاتف" prop="phone">
              <el-input v-model="suppliersForm.phone" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="العنوان" prop="address">
              <el-input v-model="suppliersForm.address" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="handleAddNewSupplier"
          >تسجيل المورد
        </el-button>
        <el-button class="mr-3" @click="handleCloseDialogForm">إلغاء</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axiosApi from "@/plugins/axios";

export default {
  name: "searchSupplier",
  props: ["showLabel", "supplier", "addSupplier", "searchOnly"],
  data() {
    return {
      placeholder: this.supplier ? this.supplier.name : "بحث الموردين",
      value: null,
      selectedLabel: "",
      options: [],
      addFormVisible: false,
      rules: {
        name: [{ required: true, message: "حدد اسم المورد", trigger: "blur" }],
        balance: [
          { required: true, message: "حدد الرصيد الافتتاحي", trigger: "blur" },
        ],
      },
      suppliersForm: {
      name: '',
      balance: '',
      address: '',
      phone: '',
    },
    selectedSupplier: null,
    };
  },
  computed: mapGetters([
    "suppliersLoading",
    "suppliersList",
    "showFormDialog",
  ]),
  mounted() {
    this.getSuppliers();
  },
  methods: {
    ...mapActions([
      "getSuppliers",
      "getCreateSupplier",
      "getSupplierForm",
      "getShowFormDialog",
      "getSelectedSupplier",
    ]),
    setValue(value) {
      this.getSelectedSupplier(value);
      this.value = value;
      if (this.value != null) {
        this.placeholder = value.name;
      } else {
        this.placeholder = "";
      }
      if (this.value) {
        setTimeout(() => {
          this.$forceUpdate();
          document.querySelector(".productQuery input").focus();
        }, 100);
      } else {
        setTimeout(() => {
          this.$refs.supplierQuery.focus();
        }, 200);
      }
    },
    resetSearch() {
      this.setValue(null);
      this.$emit("selectedSupplier", {
        supplier: null,
      });
    },
    supplierSelected() {
      if (this.value != "0") {
        const selectedSupplier = this.options.filter((supplier) => {
          if (supplier.id == this.value) {
            return supplier;
          }
        })[0];
        this.getSelectedSupplier(selectedSupplier);
        if (selectedSupplier) {
          this.placeholder = selectedSupplier.name;
          setTimeout(() => {
            document.querySelector(".productQuery input").focus();
          }, 100);
        }
        this.$emit("selectedSupplier", {
          supplier: selectedSupplier,
        });
      } else {
        this.value = "";
        this.handleSearchEnter();
      }
    },
    handleSearchEnter() {
      this.addFormVisible = true;

      this.suppliersForm.name = this.selectedLabel;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.selectedLabel = query;
        this.loading = true;
        this.options = this.suppliersList.filter((supplier) =>
          supplier.name.includes(query)
        );
      } else {
        this.options = this.suppliersList;
      }
    },
    handleAddNewSupplier() {
      this.$refs.supplierForm.validate((valid) => {
        if (valid) {
          this.isLoading = false;
          const loading = this.$loading({
            lock: true,
            text: "جاري تحميل البيانات",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          if (!this.isLoading) {
            axiosApi
              .post("suppliers", this.suppliersForm)
              .then((res) => {
                this.$notify({
                  title: "تمت العملية بنجاح",
                  message: "تم حفظ بيانات العميل بنجاح",
                  type: "success",
                  duration: 1500,
                });
                this.remoteMethod(res.data.name);
                this.selectedSupplier = res.data;
                this.placeholder = res.data.name;

                this.value = res.data.id;

                this.$emit("selectedSupplier", {
                  supplier: res.data,
                });

                this.selectedLabel = "";
                this.$refs["supplierForm"].resetFields();
                this.handleCloseDialogForm();
                
              })
              .catch(() => {
                this.$notify({
                  title: "حدث خطأ",
                  message: "عفوا رقم الهاتف مستخدم لحساب اخر ",
                  type: "error",
                  duration: 1500,
                });
              })
              .finally(() => {
                this.isLoading = true;
                loading.close();
              });
          }
        } else {
          return false;
        }
      });
    },
    handleOpenDialog() {
      this?.$refs?.supplierForm?.resetFields();
      this?.$refs?.supplierForm?.clearValidate();
      this?.$refs?.supplierName?.focus();
    },
    handleCloseDialog() {
      this?.$refs?.supplierForm?.resetFields();
      this?.$refs?.supplierForm?.clearValidate();
    },
    handleCloseDialogForm() {
      this.suppliersForm = {
        name: "",
        balance: "",
        address: "",
        phone: "",
      };
      this.addFormVisible = false;
    },
  },
  watch: {
    supplier(val) {
      this.placeholder = val.name
      this.selectedSupplier = val
      if (!val) {
        this.placeholder = ''
        this.selectedSupplier = null
      }
    }
  },
};
</script>

<style lang="scss">
.search-container {
  background-color: #ebf9fc;
  border-radius: 6px;
  padding: 20px;
  padding-right: 13px;
  margin: 10px 0 !important;
}
</style>
