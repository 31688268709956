<template>
  <div class="search-container">
    <input
      type="text"
      @focus="resetSelectedProduct"
      class="form-control"
      readonly
      v-model="placeholder"
      id=""
      v-if="selectedProduct"
    />

    <el-select
      v-else
      @change="productSelected"
      class="search productQuery darkBorder"
      @focus="options = []"
      v-model="value"
      :loading-text="loadingMsg"
      :no-data-text="noDataMsg"
      filterable
      remote
      :clearable="true"
      placeholder="بحث الخامات بالاسم"
      :remote-method="remoteMethod"
      :loading="loading"
    >
      <div class="container-fluid">
        <el-row type="flex" class="headerSearch">
          <el-col :span="10" class="p-0 text-start">
            <span> المخزون</span>
          </el-col>
          <el-col :span="14" class="p-0">
            <span>اسم الخامة</span>
          </el-col>
        </el-row>
      </div>
      <el-option
        ref="productQuery"
        v-for="(item, index) in options"
        :key="index"
        :label="item.name"
        :value="item.id"
      >
        <div class="container-fluid" v-if="options.length > 0">
          <el-row type="flex" class="searchBody">
            <el-col :span="10" class="p-0 text-start">
              <Span
                :class="[
                  item?.selectedInventory?.pivot?.qty <= 0 ||
                  item?.inventories.length === 0
                    ? 'red'
                    : 'number',
                ]"
                >{{ numberToFixed(item?.selectedInventory?.pivot?.qty ?? 0) }}
              </Span>
            </el-col>
            <el-col :span="14" class="p-0">
              <span> {{ item.name }}</span>
            </el-col>
          </el-row>
        </div>
      </el-option>
      <el-option v-if="allowCreateNewProduct && options.length === 0" value="0"
        >إضافة خامة جديد</el-option
      >
    </el-select>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  props: [
    "fromInventory",
    "toInventory",
    "showStockZero",
    "allowCreateNewProduct",
  ],

  data() {
    return {
      noDataMsg: "لا توجد بيانات متاحه",
      loadingMsg: " ... جاري تحميل البيانات",
      options: [],
      placeholder: "",

      value: null,
      loading: false,
      selectedProduct: null,
      addFormVisible: false,
      productForm: {
        name: "",
        material_category_id: "",
        type: "",
      },
      rules: {
        name: [{ required: true, message: "الاسم مطلوب", trigger: "blur" }],
        material_category_id: [
          { required: true, message: "التصنيف مطلوب", trigger: "blur" },
        ],
        type: [{ required: true, message: "النوع مطلوب", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    numberToFixed(number) {
      return Math.floor(number * 100) / 100;
    },
    addNewProduct() {
      this.$emit("addNewProduct");
    },
    setValue(value) {
      this.value = value;
      this.selectedProduct = value;
      if (this.value != null) {
        this.placeholder = value.name;
      } else {
        this.placeholder = "";
      }
    },
    resetOptions() {
      document.querySelector(".productQuery input").blur();
      this.options = [];
    },
    resetSelectedProduct() {
      this.selectedProduct = null;
      this.placeholder = "";
      this.$emit("selectedProduct", {
        product: this.selectedProduct,
      });
      setTimeout(() => {
        document.querySelector(".productQuery input").focus();
      }, 100);
    },
    productSelected() {
      if (this.value > 0) {
        this.selectedProduct = this.options.filter((product) => {
          if (product.id == this.value) {
            return product;
          }
        })[0];
        this.setValue(this.selectedProduct);
        this.$emit("selectedProduct", {
          product: this.selectedProduct,
        });
      } else {
        this.value = "";
        this.addNewProduct();
      }
    },
    remoteMethod(query) {
      if (this.fromInventory != null && this.toInventory) {
        if (query !== "") {
          this.selectedLabel = query;
          this.$emit("productQueryChange", {
            productQuery: this.selectedLabel,
          });
          this.loading = true;
          axiosApi
            .get(
              `materials/search?name=${query}&supplier=1&inventory_id=${this.fromInventory.id}`
            )
            .then((res) => {
              this.loading = false;
              let hasStock = [];
              if (this.showStockZero) {
                hasStock = res.data;
              } else {
                hasStock = res.data.filter((product) => {
                  product.selectedInventory = product.inventories.filter(
                    (inventory) => {
                      return (
                        inventory.pivot.inventory_id === this.fromInventory.id
                      );
                    }
                  )[0];
                  if (
                    product.inventories.length > 0 &&
                    product.selectedInventory.pivot.qty > 0
                  ) {
                    return product;
                  }
                });
              }

              this.options = hasStock;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.options = [];
        }
      } else {
        this.$message({
          type: "error",
          message: "عفوا حدد المخزن اولا",
        });

        document.querySelector(".productQuery input").value = "";
      }
    },
  },
};
</script>
