export const columns = [
  {
    width: "4%",
    hasCondition: false,
    name: "#",
  },
  {
    width: "25%",
    hasCondition: false,
    name: "المنتج",
  },
  {
    width: "8%",
    hasCondition: false,
    name: "الكمية",
  },
  {
    width: "8%",
    hasCondition: false,
    name: "سعر الشراء",
  },
  {
    width: "8%",
    hasCondition: false,
    name: "أخر سعر",
  },
  {
    width: "8%",
    hasCondition: false,
    name: "الاجمالي",
  },
  {
    width: "5%",
    hasCondition: false,
    name: "_",
  },
];

export const movementsColumns = [
  {
    width: "4%",
    hasCondition: false,
    name: "#",
  },
  {
    width: "25%",
    hasCondition: false,
    name: "المنتج",
  },
  {
    width: "8%",
    hasCondition: false,
    name: "الكمية",
  },
  {
    width: "8%",
    hasCondition: false,
    name: "المتاح",
  },
  {
    width: "5%",
    hasCondition: false,
    name: "_",
  },
];