<template>
  <el-row
    :gutter="15"
    style="flex-wrap: wrap;     max-height: 70vh;
    overflow-y: scroll;"
    type="flex"
    align="middle"
  >
    <el-col v-for="(product, index) in products" :key="index" :lg="6" :md="8" :sm="12">
      <div class="mb-2 meunItem" style="min-height: 80px; padding: 0;">
        <div style="text-align: center; min-height: 45px; display: flex; align-items: center; justify-content: center;">
          {{ product.name }}
        </div>
        <el-row type="flex" style="justify-content: center !important;" justify="center" class="prices">
          <div
            class="price"
            v-for="(price, index) in product.prices"
            :key="index"
            @click="
              addItem(
                product,
                price
              )
            "
          >
          <span v-if="selectedOrderType.id==3">
            {{ price.sala_price }}

          </span>
          <span v-else>
            {{ price.price }}

          </span>
          </div>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    selectedOrderType:Object,
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    addItem(item, price) {
      console.log('item ',item)
      this.$emit("addItem", item, price);
    },
  },
};
</script>
