<template>
  <div> 
      <ShowProductsCategories />
  </div>
</template>

<script>
import ShowProductsCategories from '@/components/Products/categories';
export default {
    components:{
        ShowProductsCategories
    }
}
</script>

<style>

</style>