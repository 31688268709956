<template>
    <el-row type="flex" :gutter="10" class="footer">
      <el-col :xl="8" :lg="8" :md="7" :sm="24">
        <div class="bg-White alignment px-3 mt-2" v-if="invoice.products.length > 0">
          <el-row :gutter="5">
            <el-col :span="12">
              <label for="">خصم بالقيمة</label>
              <el-input
                @change="discountChange"
                @focus="$event.target.select()"
                v-model="invoice.total_discount"
                placeholder="قيمة الخصم"
                align="middle"
              >
              </el-input>
            </el-col>
            <el-col :span="12" v-if="invoice.products.length > 0">
              <label for="">خصم بالنسبة %</label>
              <el-input
                @change="discountPreChange"
                @focus="$event.target.select()"
                v-model="invoice.percentage_discount"
                placeholder="الخصم بالنسبة المئوية"
              >
              </el-input>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :xl="7" :lg="7" :md="8" :sm="24">
        <div class="bg-White alignment p-3 mt-2" v-if="invoice.products.length > 0">
          <p class="d-mob">
            اجمالي {{ invoiceTitle }}
            <span v-if="invoice.customer">{{ $numberToFixed(invoice.total_sale_cost) + " ج.م " }}</span>
            <span v-else>{{ $numberToFixed(invoice.total_buy_cost) + " ج.م " }}</span>
          </p>
          <p>
            الكمية
            <span class="num">{{ invoice.total_qty }}</span>
          </p>
          <p class="px-3 d-mob-none" v-if="invoice.products.length > 0">
            {{ invoiceTitle }}
            <span v-if="invoice.customer">{{ $numberToFixed(invoice.total_sale_cost) + " ج.م " }}</span>
            <span v-else>{{ $numberToFixed(invoice.total_buy_cost) + " ج.م " }}</span>
          </p>
          <p class="px-3 d-mob-none" v-if="invoice.total_discount > 0">
            بعد الخصم
            <span v-if="invoice.customer">{{
              $numberToFixed(invoice.total_sale_cost - invoice.total_discount) + " ج.م "
            }}</span>
            <span v-else>{{ $numberToFixed(invoice.total_buy_cost - invoice.total_discount) + " ج.م " }}</span>
          </p>
          <p class="px-3" v-if="showHidden && $accessLinks('show_buy_cost_in_sale')">
            الربح
            <span>{{
              $numberToFixed(invoice.total_sale_cost - invoice.total_discount - invoice.total_buy_cost) + " ج.م "
            }}</span>
          </p>
        </div>
      </el-col>
      <el-col :xl="9" :lg="9" :md="9" :sm="24">
        <div class="bg-White mt-2">
          <el-button
            v-show="invoice.products.length > 0"
            class="save"
            :loading="isLoading"
            @click="showPaymentModal"
            :disabled="disabled"
            >حفظ {{ invoiceTitle }}</el-button
          >
          <span :class="[invoice.products.length > 0 ? '' : 'mr-auto']" class="pl-3 d-mob-none">
            <el-popconfirm
              confirm-button-text="موافق"
              cancel-button-text="إلغاء"
              icon="el-icon-info"
              icon-color="red"
              :title="`هل تريد مسح ${invoiceTitle}؟`"
              @confirm="resetInvoiceData"
            >
              <div slot="reference">
                <el-button class="exit mr-3">مسح {{ invoiceTitle }}</el-button>
              </div>
            </el-popconfirm></span
          >
        </div>
      </el-col>
    </el-row>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  export default {
    props: {
      invoice: {
        type: Object,
        default: () => {
          return {
            inventory_id: JSON.parse(localStorage.getItem("user_inventories"))[0].id,
            paied: 0,
            VAT: 0,
            customer: null,
            total_cost: 0,
            total_buy_cost: 0,
            total_sale_cost: 0,
            total_qty: 0,
            total_discount: 0,
            percentage_discount: 0,
            products: [],
            price_strategy_id: "",
            notes: "",
            can_deliver: 0,
            load_id: null
          };
        }
      },
      showHidden: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      invoiceTitle: {
        type: String,
        default: "الفاتورة"
      },
      invoiceType: {
        type: String,
        default: "sale"
      }
    },
    methods: {
      ...mapActions(["setLocalStorage", "setLocalStoragePurchases"]),
      discountChange() {
        this.invoice.percentage_discount = (
          parseFloat(this.invoice.total_discount / this.invoice.total_buy_cost) * 100
        ).toFixed(2);
        if (isNaN((+this.invoice.total_discount / +this.invoice.total_buy_cost) * 100)) {
          this.invoice.percentage_discount = 0;
        }
        this.setLocalStoragePurchases(this.invoiceType);
      },
      discountPreChange() {
        this.invoice.total_discount = parseFloat(
          (this.invoice.percentage_discount * this.invoice.total_buy_cost) / 100
        ).toFixed(2);
        this.setLocalStoragePurchases(this.invoiceType);
      },
      openNotesModal() {
        this.$emit("openNotesModal");
      },
      showPaymentModal() {
        this.$emit("showPaymentModal");
      },
      resetInvoiceData() {
        this.$emit("resetInvoiceData");
      }
    },
    computed: {
      disabled() {
        return this.invoice.supplier ? false : true
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .footer {
    span {
      display: block;
    }
  }
  </style>
  