<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>تقرير التشغيل</h2>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-table
        class="mt-2"
        align="right"
        v-if="tableData.length > 0"
        :data="tableData"
        border
        style="width: 100%"
        height="90vh"
      >
        <el-table-column type="index" label="#" width="50" />
        <el-table-column label="رقم التشغيله" prop="id">
          <template slot-scope="scope">
            {{
              "تجهيز عدد " +
              scope.row.out_materials[0].pivot.qty +
              " " +
              scope.row.out_materials[0].name
            }}
          </template>
        </el-table-column>
        <el-table-column label="التكلفه">
          <template slot-scope="scope">
            {{ scope.row.out_materials[0].pivot.cost }} ج م
          </template>
        </el-table-column>
        <el-table-column label="التوقيت">
          <template slot-scope="scope">
            {{ scope.row.created_at | moment(" D/MM/YYYY | h:mm A") }}
          </template>
        </el-table-column>
        <el-table-column label="بواسطة">
          <template slot-scope="scope">
            {{ scope.row.user.name }}
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-row type="flex" justify="center">
              <el-col :span="20">
                <el-table
                  class="mt-2"
                  align="right"
                  :data="scope.row.in_materials"
                  border
                  style="width: 100%"
                >
                <el-table-column type="index" label="#" />
                <el-table-column label="الخامه" prop="name"></el-table-column>
                <el-table-column label="الكميه">
                  <template slot-scope="scope">
                    {{ scope.row.pivot.qty }}
                  </template>
                </el-table-column>
              </el-table>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  name: "OprationsReport",
  data: () => {
    return {
      tableData: [],
      pagination: {
        current_page: 1,
        last_page: 1,
      },
    };
  },
  mounted() {
    this.getOperationData();
  },
  methods: {
    getOperationData() {
      const loading = this.$loading();
      axiosApi
        .get("operations")
        .then((res) => {
          this.pagination.current_page = res.data.current_page;
          this.pagination.last_page = res.data.last_page;
          if (this.tableData.length > 0) {
            this.tableData = [...this.tableData, ...res.data.data];
          } else {
            this.tableData = res.data.data;
          }
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>
