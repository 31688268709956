<template>
  <div class="tables-list">
    <el-button
      v-for="table in allTables"
      :key="table.id"
      :class="
        table.status === 2 ? 'busy' : table.status === 1 ? 'free' : 'hold'
      "
      @click="setTableToOrder(table)"
    >
      {{ table.name }}
    </el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["allTables"]),
  methods: {
    setTableToOrder(table) {
      this.$emit("setTableToOrder", table)
    },
  },
};
</script>

<style lang="scss" scoped>
.tables-list {
  display: flex;
  flex-wrap: wrap;
  button {
    width: 120px;
    margin: 10px 0 10px 10px !important;
    padding: 15px 0;
    color: #fff;
    &.busy {
      background-color: #f56c6c;
    }
    &.hold {
      background-color: #409eff;
    }
    &.free {
      background-color: #67c23a;
    }
  }
}
</style>
