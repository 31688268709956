<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="8" :md="7" :xs="20" class="title">
          <h2>فاتورة مشتريات</h2>
        </el-col>
        <el-col :lg="8" :md="9">
          <div class="buttons-container">
            <el-button
              icon="el-icon-plus"
              class="plus"
              @click="handleAddPurchases"
            ></el-button>
            <el-button
              v-for="(invoice, index) in purchases"
              :key="index"
              :class="{
                'tab-btn': true,
                'active-tab': selectedPurchaseIndex == index,
              }"
              @click="handleSetSelectedPurchaseIndex(index)"
              >{{ index + 1 }}</el-button
            >
          </div>
        </el-col>
        <el-col :lg="8" :md="8" class="header-action">
          <el-button
            v-if="purchases[selectedPurchaseIndex]?.supplier"
            :type="supplierBalance >= 0 ? 'success' : 'danger'"
          >
            {{ supplierBalance >= 0 ? "دائن" : "مدين" }} |
            {{
              `${
                supplierBalance >= 0
                  ? $numberToFixed(supplierBalance)
                  : $numberToFixed(supplierBalance * -1)
              } ج.م`
            }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" :gutter="10">
      <el-col :span="8">
        <SearchSuppliers
          :showLabel="false"
          :searchOnly="false"
          @selectedSupplier="selectedSupplier"
          :supplier="purchases[selectedPurchaseIndex]?.supplier"
          ref="searchSuppliersComponent"
        />
      </el-col>
      <el-col :span="8">
        <ProductSearch
          :allowCreateNewProduct="true"
          @addNewProduct="showAddModel"
          :showStockZero="true"
          ref="searchProductsComponent"
          :supplier="purchases[selectedPurchaseIndex].supplier"
          @selectedProduct="selectedProduct"
          @productQueryChange="productQueryChange"
          :product="product"
        />
      </el-col>
      <el-col :span="4">
        <div class="search-container">
          <el-popover
            :ref="`new-product-qty}`"
            placement="top"
            trigger="manual"
            :visible-arrow="true"
            content="الكمية غير صحيحة"
            v-model="invoiceData.showQtyToolTip"
            :offset="5"
            popper-class="bg-danger"
          >
          </el-popover>
          <input
            :class="invoiceData.showQtyToolTip ? 'error' : ''"
            class="form-control qty"
            type="number"
            @focus="$event.target.select()"
            v-model="invoiceData.qty"
            placeholder=" الكمية"
            min="0"
            v-popover="`new-product-qty}`"
            @keyup.enter="validateQty"
            ref="searchFaturaComponent"
          />
          <!-- @change="validateQty"-->
        </div>
      </el-col>
      <el-col :span="4">
        <div class="search-container">
          <el-popover
            :ref="`${invoiceData?.product?.id}-${invoiceData?.product?.type}`"
            placement="left"
            trigger="manual"
            content="ادخل رقم صحيح"
            v-model="invoiceData.showBuyCostToolTip"
            popper-class="bg-danger"
          >
          </el-popover>
          <input
            type="number"
            ref="buy_cost"
            v-model="invoiceData.buyPrice"
            @change="validateBuyCost"
            @focus="$event.target.select()"
            @keyup.enter="addNewItem"
            v-popover="
              `${invoiceData?.product?.id}-${invoiceData?.product?.type}`
            "
            class="form-control buy_cost"
            placeholder="سعر الخامة"
          />
        </div>
      </el-col>
    </el-row>
    <el-row class="table mt-2 px-3">
      <InvoicesTable
        :invoice="purchases[selectedPurchaseIndex]"
        @selectedProduct="selectedProduct"
        @showAddModel="showAddModel"
        @productQueryChange="productQueryChange"
        @addNewItemPurchases="addNewItem"
      />
    </el-row>
    <InvoicesFooter
      :invoice="purchases[selectedPurchaseIndex]"
      :isLoading="isLoading"
      @showPaymentModal="showPaymentModal"
      @resetInvoiceData="resetInvoiceData"
      invoiceType="purchases"
    />
    <el-dialog
      title="تسجيل خامة جديد"
      :visible.sync="addProductFormVisible"
      v-if="productForm"
    >
      <el-form :model="productForm" :rules="rules" ref="form">
        <el-row type="flex" style="flex-wrap: wrap" :gutter="10">
          <el-col :xl="12" :lg="12" :md="6" :sm="12">
            <el-form-item label="الاسم " prop="name">
              <el-input
                class="formInput1"
                autofocus
                v-model="productForm.name"
                autocomplete="off"
                @keyup.enter.native="focusNext"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="التصنيف" prop="material_category_id">
              <el-select v-model="productForm.material_category_id">
                <el-option
                  v-for="category in materialCategories"
                  :key="category.id"
                  :value="category.id"
                  :label="category.name"
                  >{{ category.name }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="النوع" prop="type">
              <el-select v-model="productForm.type">
                <el-option value="1" label="تامة">تامة</el-option>
                <el-option value="2" label="مصنعه">مصنعه</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="handleAddNewProduct"
          >تسجيل خامة
        </el-button>
        <el-button class="mr-3" @click="addProductFormVisible = false"
          >إلغاء</el-button
        >
      </span>
    </el-dialog>
    <div v-if="confirmSave == true" class="dialog">
      <div class="bodySec">
        <p @click="() => (confirmSave = false)">
          <span><i class="far fa-times-circle"></i></span> اغلاق
        </p>
      </div>
      <div class="content">
        <div class="headerSection mt-mob-2">
          <p>الرصيد السابق</p>
          <p class="mr-auto">
            {{
              $numberToFixed(
                Number(purchases[selectedPurchaseIndex].supplier.balance)
              ) + " ج.م "
            }}
          </p>
        </div>
        <div class="headerSection mt-2">
          <p>اجمالي الفاتوره</p>
          <p class="mr-auto">
            {{
              $numberToFixed(
                Number(purchases[selectedPurchaseIndex].total_buy_cost) -
                  Number(purchases[selectedPurchaseIndex].total_discount)
              ) + " ج.م "
            }}
          </p>
        </div>
        <div class="headerSection mt-2">
          <p>اجمالي المدونية</p>
          <p class="mr-auto">
            {{
              $numberToFixed(
                Number(purchases[selectedPurchaseIndex].total_buy_cost) -
                  Number(purchases[selectedPurchaseIndex].total_discount) +
                  Number(purchases[selectedPurchaseIndex].supplier.balance)
              ) + " ج.م "
            }}
          </p>
        </div>
        <div class="mt-2">
          <el-radio-group
            v-model="setPaied"
            style="justify-content: start !important"
          >
            <el-radio-button label="without">حفظ بدون توريد</el-radio-button>
            <el-radio-button label="with">توريد</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="setPaied == 'with'">
          <el-row>
            <el-col> <p class="label">المبلغ المدفوع</p></el-col>
            <el-col>
              <input
                class="form-control"
                @focus="$event.target.select()"
                @keyup.enter="addPurchase"
                id="paied"
                v-model="purchases[selectedPurchaseIndex].total_paid"
              />
            </el-col>
          </el-row>
        </div>
        <el-button
          class="mt-3 btn-green1 btn-save"
          type="success"
          @click="addPurchase"
          >حفظ</el-button
        >
        <el-button
          type="danger"
          @click="() => (confirmSave = false)"
          class="exit mr-2"
          >الغاء</el-button
        >
      </div>

      <br />
    </div>
  </div>
</template>

<script>
import SearchSuppliers from "../../components/suppliers/search.vue";
import InvoicesTable from "../../components/invoices/InvoicesTable.vue";
import InvoicesFooter from "../../components/invoices/InvoicesFooter.vue";
import { mapGetters, mapActions } from "vuex";
import axiosApi from "@/plugins/axios.js";
import ProductSearch from "@/components/Products/Search.vue";

export default {
  name: "NewPurchases",
  components: { SearchSuppliers, InvoicesTable, ProductSearch, InvoicesFooter },
  computed: {
    ...mapGetters(["purchases", "selectedPurchaseIndex", "invoiceData"]),
    supplierBalance() {
      return this.purchases[this.selectedPurchaseIndex]?.supplier?.balance;
    },
  },
  data() {
    return {
      product: null,
      productQuery: "",
      units: [],
      selectedUnitId: "",
      selectedUnit: null,
      selectedProductStock: "",
      addProductFormVisible: false,
      isLoading: false,
      addFormVisible: false,
      productForm: {
        name: "",
        material_category_id: "",
        type: "",
      },
      rules: {
        name: [{ required: true, message: "الاسم مطلوب", trigger: "blur" }],
        material_category_id: [
          { required: true, message: "التصنيف مطلوب", trigger: "blur" },
        ],
        type: [{ required: true, message: "النوع مطلوب", trigger: "blur" }],
      },
      confirmSave: false,
      setPaied: "without",
      total_paid: 0,
      materialCategories: [],
    };
  },
  mounted() {
    this.getMaterialCategories();
  },
  methods: {
    ...mapActions([
      "addNewPurchases",
      "setSelectedPurchaseIndex",
      "resetProduct",
      "clearInvoiceData",
    ]),
    getMaterialCategories() {
      axiosApi.get("material_categories").then((res) => {
        this.materialCategories = res.data;
      });
    },
    showPaymentModal() {
      this.confirmSave = true;
    },
    handleAddPurchases() {
      this.addNewPurchases("purchases");
      this.handleSetSelectedPurchaseIndex(this.purchases.length - 1);
    },
    handleSetSelectedPurchaseIndex(index) {
      this.setSelectedPurchaseIndex(index);
    },
    selectedProduct(e) {
      this.product = e.product;
      this.invoiceData.product = e.product;
      this.productQuery = e.query;
      this.setProduct();
    },
    setProduct() {
      if (this.product != null) {
        setTimeout(() => {
          document.querySelector(".qty").focus();
        }, 200);
      }
    },
    showAddModel() {
      this.addProductFormVisible = true;
      setTimeout(() => {
        this.productForm.name = this.productQuery;
        // this.$refs.searchFaturaComponent.setValue(null, this.productQuery);
      }, 100);
    },
    productQueryChange(e) {
      this.productQuery = e.productQuery;
    },
    setLocalStorage() {
      localStorage.setItem("purchases", JSON.stringify(this.purchases));
    },
    addNewItem() {
      if (this.invoiceData.product != null && !this.isLoading) {
        if (this.invoiceData.qty > 0) {
          let id = this.invoiceData.product.id;
          let vm = this;
          var product = this.purchases[
            this.selectedPurchaseIndex
          ].products.filter(function (elem) {
            if (elem.id == id && elem.price == vm.invoiceData.buyPrice)
              return elem;
          });

          if (product.length > 0) {
            this.purchases[this.selectedPurchaseIndex].products.splice(
              this.purchases[this.selectedPurchaseIndex].products.findIndex(
                (e) => e.id == id && e.price == vm.invoiceData.buyPrice
              ),
              1
            );
            this.purchases[this.selectedPurchaseIndex].products.push({
              id: product[0].id,
              name: product[0].name,
              price: Number(this.invoiceData.buyPrice),
              discount: Number(this.invoiceData.discount),
              lastPurchaseCost: product[0].lastPurchaseCost,
              qty: Number(product[0].qty) + Number(this.invoiceData.qty),
              invalidBuyCost: false,
              inValidWholesalePrice: false,
              invalidSalePrice: false,
            });
            let invoiceData = {
              product: null,
              qty: null,
              buyPrice: null,
            };
            this.productQuery = "";
            this.resetProduct(invoiceData);
            this.$refs.searchProductsComponent.setValue(null);
            this.invoiceData.qty = null;
            this.invoiceData.buyPrice = null;
            
            this.setLocalStorage();
            this.$forceUpdate();
            setTimeout(() => {
              this.calcTotal();
            }, 100);
          } else {
            this.purchases[this.selectedPurchaseIndex].products.push({
              id: this.invoiceData.product.id,
              name: this.invoiceData.product.name,
              price: Number(this.invoiceData.buyPrice),
              discount: Number(this.invoiceData.discount),
              lastPurchaseCost: this.invoiceData.product.lastPurchaseCost,
              qty: Number(this.invoiceData.qty),
              invalidBuyCost: false,
            });
            this.setLocalStorage();
            setTimeout(() => {
              this.calcTotal();
            }, 100);
            this.invoiceData.qty = null;
            this.invoiceData.buyPrice = null;
            this.productQuery = "";
            this.resetProduct(this.invoiceData);
            this.$refs.searchProductsComponent.setValue(null);
            this.$forceUpdate();
          }
        }
      }
    },
    calcTotal() {
      let factory_cost = 0;
      var qty = 0;
      let buy = 0;
      for (
        var i = 0;
        i < this.purchases[this.selectedPurchaseIndex].products.length;
        i++
      ) {
        this.purchases[this.selectedPurchaseIndex].products[
          i
        ].invalidBuyCost = false;
        this.$forceUpdate();
        if (this.purchases[this.selectedPurchaseIndex].products[i].qty == "") {
          this.purchases[this.selectedPurchaseIndex].products[i].qty = 1;
        }
        buy = Number(
          this.purchases[this.selectedPurchaseIndex].products[i].qty *
            (this.purchases[this.selectedPurchaseIndex].products[i].price -
              this.purchases[this.selectedPurchaseIndex].products[i].discount)
        );
        factory_cost = factory_cost + buy;
        qty =
          qty +
          Number(this.purchases[this.selectedPurchaseIndex].products[i].qty);
        console.log(
          "invalidBuyCost -- 1",
          this.purchases[this.selectedPurchaseIndex].products[i].invalidBuyCost
        );
      }
      setTimeout(() => {
        for (
          var i = 0;
          i < this.purchases[this.selectedPurchaseIndex].products.length;
          i++
        ) {
          if (
            this.purchases[this.selectedPurchaseIndex].products[i].price <= 0
          ) {
            this.purchases[this.selectedPurchaseIndex].products[
              i
            ].invalidBuyCost = true;
            console.log(
              "invalidBuyCost -- 2",
              this.purchases[this.selectedPurchaseIndex].products[i]
                .invalidBuyCost
            );
          } else {
            this.purchases[this.selectedPurchaseIndex].products[
              i
            ].invalidBuyCost = false;
            console.log(
              "invalidBuyCost -- 3",
              this.purchases[this.selectedPurchaseIndex].products[i]
                .invalidBuyCost
            );
          }
        }
      }, 100);
      this.checkQty();
      this.purchases[this.selectedPurchaseIndex].total_buy_cost =
        Number(factory_cost);
      this.purchases[this.selectedPurchaseIndex].total_qty = Number(qty);
      this.discountChange();
      this.setLocalStorage();
    },
    checkQty() {
      for (
        var i = 0;
        i < this.purchases[this.selectedPurchaseIndex].products.length;
        i++
      ) {
        if (
          Number(this.purchases[this.selectedPurchaseIndex].products[i].qty) ==
          0
        ) {
          this.disabled = true;
          return 1;
        } else {
          this.disabled = false;
        }

        if (this.purchases[this.selectedPurchaseIndex].products[i].qty == "") {
          this.purchases[this.selectedPurchaseIndex].products[i].qty = 1;
        }
      }
    },
    discountChange() {
      this.purchases[this.selectedPurchaseIndex].percentage_discount = (
        parseFloat(
          this.purchases[this.selectedPurchaseIndex].total_discount /
            this.purchases[this.selectedPurchaseIndex].total_buy_cost
        ) * 100
      ).toFixed(2);
      if (
        isNaN(
          (+this.purchases[this.selectedPurchaseIndex].total_discount /
            +this.purchases[this.selectedPurchaseIndex].total_buy_cost) *
            100
        )
      ) {
        this.purchases[this.selectedPurchaseIndex].percentage_discount = 0;
      }
      this.setLocalStorage();
    },
    validateBuyCost() {
      if (this.invoiceData.buyPrice <= 0) {
        this.invoiceData.showBuyCostToolTip = true;
      } else {
        this.invoiceData.showBuyCostToolTip = false;
      }
    },
    selectedSupplier(e) {
      console.log("🚀 ~ selectedSupplier ~ e:", e)
      this.purchases[this.selectedPurchaseIndex].supplier = e.supplier;
      this.invoiceData.supplier = e.supplier;
      this.setLocalStorage();
      if (e.supplier != null) {
        document.querySelector(".productQuery input").focus();
      }
    },
    handleAddNewProduct() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: false,
            spinner: "el-icon-loading",
            background: "rgba(255,255,255,.7)",
            customClass: "fullscreen-loading",
          });
          axiosApi
            .post("materials", this.productForm)
            .then((res) => {
              this.$notify({
                title: "تمت العملية بنجاح",
                message: "تم حفظ بيانات العميل بنجاح",
                type: "success",
                duration: 1500,
              });
              this.selectedProduct({
                product: res.data,
              });
              this.invoiceData.product = res.data;
              this.addProductFormVisible = false;
              this.productForm = {
                name: "",
                material_category_id: "",
                type: "",
              }
            })
            .finally(() => {
              loading.close();
            });
        }
      });
    },
    validateQty() {
      document.querySelector(".buy_cost").focus();
    },
    addPurchase() {
      if (!this.isLoading) {
        if (this.purchases[this.selectedPurchaseIndex].products.length > 0) {
          this.isLoading = true;
          const loading = this.$loading({
            lock: true,
            text: "جاري تحميل البيانات",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          const purchasesData = {
            supplier_id: this.purchases[this.selectedPurchaseIndex].supplier.id,
            inventory_id: 1,
            discount: this.purchases[this.selectedPurchaseIndex].total_discount,
            total_paid: this.purchases[this.selectedPurchaseIndex].total_paid,
            materials: this.purchases[this.selectedPurchaseIndex].products.map(
              (product) => {
                return {
                  material_id: product.id,
                  qty: product.qty,
                  buy_cost: product.price,
                };
              }
            ),
          };
          axiosApi
            .post("purchases", purchasesData)
            .then(() => {
              this.$notify.success({
                title: "تمت العملية بنجاح",
                message: "تم حفظ بيانات الفاتورة بنجاح",
                type: "success",
                duration: 1500,
              });
              this.confirmSave = false;
              this.resetInvoiceData();
            })
            .catch((err) => {
              console.log(err.response.status);
              if (err.response.status === 403) {
                this.$notify.error({
                  title: "حدث خطأ",
                  message: `عفوا لا يوجد رصيد || رصيد الخزنه ${err.response.data.balance}`,
                  duration: 1500,
                });
              } else {
                this.$notify.error({
                  title: "حدث خطأ",
                  message: "عفوا تأكد من بيانات الفاتورة",
                  duration: 1500,
                });
              }
            })
            .finally(() => {
              loading.close();
              this.isLoading = false;
            });
        }
      }
    },
    resetInvoiceData() {
      // this.products = null;
      this.invoiceData.supplier = null;
      this.$refs.searchSuppliersComponent.setValue(null);
      
      if (this.purchases.length > 1) {
        this.purchases.splice(this.selectedPurchaseIndex, 1);
        this.setSelectedPurchaseIndex(this.purchases.length - 1);
      } else {
        this.purchases[this.selectedPurchaseIndex].products = [];
        this.purchases[this.selectedPurchaseIndex].supplier = null;
        this.purchases[this.selectedPurchaseIndex].total_cost = 0;
        this.purchases[this.selectedPurchaseIndex].total_paid = 0;
        this.purchases[this.selectedPurchaseIndex].total_buy_cost = 0;
        this.purchases[this.selectedPurchaseIndex].total_sale_cost = 0;
        this.purchases[this.selectedPurchaseIndex].total_qty = 0;
        this.purchases[this.selectedPurchaseIndex].total_discount = 0;
        this.purchases[this.selectedPurchaseIndex].percentage_discount = 0;
        this.setSelectedPurchaseIndex(0);
      }
      setTimeout(() => {
        document.querySelector(".supplierQuery input").value = "";
        document.querySelector(".supplierQuery input").focus();
      }, 100);
      this.clearInvoiceData();
      this.setLocalStorage();
      this.$forceUpdate();
    },
    discountPreChange() {
      this.purchases[this.selectedPurchaseIndex].total_discount = parseFloat(
        (this.purchases[this.selectedPurchaseIndex].percentage_discount *
          this.purchases[this.selectedPurchaseIndex].total_buy_cost) /
          100
      ).toFixed(2);
      this.setLocalStorage();
    },
  },
  watch: {
    setPaied: function () {
      this.purchases[this.selectedPurchaseIndex].total_paid = 0;
      setTimeout(() => {
        document.getElementById("paied").focus();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #ebf9fc;
  border-radius: 6px;
  padding: 20px;
  padding-right: 13px;
  h2 {
    margin: 0;
  }
}
.buttons-container {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  .plus {
    border: none;
    margin-left: 10px;
    top: -25px;
    right: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 3px #dddddd;
    color: #707070;
    font-size: 25px;
    padding: 0;
  }
  .tab-btn {
    background: #ebf9fc;
    padding: 0;
    border: none;
    color: #333;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin: 0 1px;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    outline: none;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
    }
    &.active-tab {
      background-color: #fe5634;
      color: #fff;
    }
  }
}
.table {
  background-color: #ebf9fc;
  padding: 10px;
  border-radius: 6px;
  .el-table {
    margin-top: 0px;
  }
}
.header-action {
  display: flex;
  .result {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #011547;
    font-family: Cairo;
    font-size: 16px;

    p {
      text-align: center;
      margin: 0;

      span {
        color: #011547;
        font-family: "Open Sans", sans-serif;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}
</style>
