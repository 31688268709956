<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title header-item">
          <h2>سجل الموردين</h2>
          <!-- <el-page-header @back="goBack" content="سجل الموردين" title="رجوع"></el-page-header> -->
        </el-col>
        <el-col :lg="18" :md="17">
          <el-row type="flex" class="search" :gutter="10">
            <el-col :span="14">
              <el-date-picker
                v-model="dateRange"
                @change="filterReport"
                type="daterange"
                range-separator="الي"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
                start-placeholder="بداية الفترة"
                end-placeholder="نهاية الفترة"
                style="width: 100%"
              />
            </el-col>
            <el-col :span="5">
              <div class="bg-white">
                <el-page-header @back="goBack" title="رجوع"></el-page-header>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-table
      class="mt-2"
      align="right"
      v-if="report.length > 0"
      :data="report"
      border
      style="width: 100%"
    >
      <el-table-column label="#" type="index" width="50"> </el-table-column>
      <el-table-column sortable width="100" label="السند" prop="operation_id">
      </el-table-column>
      <el-table-column sortable label="التوقيت" prop="created_at">
        <template slot-scope="scope">
          {{ scope.row.created_at | moment(" Do / MM / YYYY | h:mm A") }}
        </template>
      </el-table-column>
      <el-table-column width="350" label="بيان" prop="description">
      </el-table-column>
      <el-table-column label="القيمه">
        <template slot-scope="scope">
          <el-tag :type="scope.row.amount < 0 ? 'success' : 'danger'">{{
            scope.row.amount
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="بواسطة" prop="user.name"> </el-table-column>
      <el-table-column label="الرصيد">
        <template slot-scope="scope">
          {{ Number(scope.row.account).toFixed(3) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  name: "SupplierAccount",
  data() {
    return {
      report: [],
      startFilter: false,
      dateRange: null,
    };
  },
  mounted() {
    this.getReport();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    filterReport() {
      this.startFilter = true;
      this.getReport();
    },
    numberToFixed(number) {
      let result = Math.floor(number * 100) / 100;
      return result < 0 ? result * -1 : result;
    },
    getReport() {
      const loading = this.$loading({
        lock: true,
        text: false,
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,.7)",
        customClass: "fullscreen-loading",
      });
      let params = {};

      if (this.dateRange) {
        params = {
          ...params,
          start: this.dateRange[0],
          end: this.dateRange[1],
        };
      }

      if (this.startFilter) {
        this.report = [];
      }
      const id = this.$route.params.id;
      axiosApi
        .get(`suppliers/${id}/account`, { params })
        .then((res) => {
          if (this.report.length > 0) {
            this.report = [...this.report, ...res.data.logs.data];
          } else {
            this.report = res.data.logs.data;
          }
          let sum = 0;
          this.report.map((value) => {
            sum += value["amount"];
            value["account"] = this.numberToFixed(sum);
          });
        })
        .finally(() => {
          loading.close();
          this.startFilter = false;
        });
    },
  },
};
</script>
