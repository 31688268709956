<template>
  <div class="operations">
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>تشغيله جديدة</h2>
        </el-col>
        <el-col :span="4">
          <el-button plain type="primary" @click="handleSubmitMaterial"
            >حفظ</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" :gutter="15">
      <el-col :span="6">
        <div class="search-container">
          <el-select
            v-model="inMaterialName"
            placeholder="بحث الخامة"
            value-key="value"
            :filterable="true"
            @change="setSelectedIn"
            :disabled="disabled"
            clearable
            :remote="true"
            :remote-method="(e) => handleSearch(e)"
          >
            <el-option
              v-for="item in nativeMaterialList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
            <el-option v-if="nativeMaterialList.length === 0" value="0" label="اضافة خامة جديدة"></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="search-container">
          <el-input
            placeholder="الكمية"
            class="product-name"
            type="number"
            v-model="inMaterialQty"
            ref="materialQty"
            @keypress.enter.native="addInMaterial"
            :disabled="disabled"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="search-container">
          <el-select
            v-model="outMaterialName"
            placeholder="بحث التشغيله"
            value-key="value"
            :filterable="true"
            @change="setSelectedOut"
            ref="materialName"
            clearable
            :remote="true"
            :remote-method="(e) => handleSearch(e)"
          >
            <el-option
              v-for="item in compoundMaterialList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
            <el-option v-if="compoundMaterialList.length === 0" value="0" label="اضافة خامة جديدة"></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="search-container">
          <el-input
            placeholder="الكمية"
            class="product-name"
            type="number"
            v-model="outMaterialQty"
            ref="materialQty"
            @keypress.enter.native="addOutMaterial"
          />
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="10">
      <el-col :span="12">
        <h3 class="text-center">المدخلات</h3>
        <el-table
          class="mt-2"
          align="right"
          :data="inMaterialList"
          border
          style="width: 100%"
          height="70vh"
        >
          <el-table-column label="#" type="index" width="50" />
          <el-table-column align="right" prop="name" label="اسم الخامة"> </el-table-column>
          <el-table-column prop="qty" label="الكمية" width="150">
            <template slot-scope="scope">
              <el-input type="number" v-model="scope.row.qty" />
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <h3 class="text-center">المخرجات</h3>
        <el-table
          class="mt-2"
          align="right"
          :data="outMaterialList"
          border
          style="width: 100%"
          height="70vh"
        >
          <el-table-column label="#" type="index" width="50" />
          <el-table-column prop="name" align="right" label="اسم الخامة"> </el-table-column>
          <el-table-column prop="qty" label="الكمية" width="150">
            <template slot-scope="scope">
              <el-input type="number" v-model="scope.row.qty" />
            </template>
          </el-table-column>
          <el-table-column label="اجراءات" width="100">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="() => deleteMaterial(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog
      title="تسجيل خامة جديد"
      :visible.sync="addProductFormVisible"
      v-if="productForm"
    >
      <el-form :model="productForm" :rules="rules" ref="form">
        <el-row type="flex" style="flex-wrap: wrap" :gutter="10">
          <el-col :xl="12" :lg="12" :md="6" :sm="12">
            <el-form-item label="الاسم " prop="name">
              <el-input
                class="formInput1"
                autofocus
                v-model="productForm.name"
                autocomplete="off"
                @keyup.enter.native="focusNext"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="التصنيف" prop="material_category_id">
              <el-select v-model="productForm.material_category_id">
                <el-option
                  v-for="category in materialCategories"
                  :key="category.id"
                  :value="category.id"
                  :label="category.name"
                  >{{ category.name }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="النوع" prop="type">
              <el-select v-model="productForm.type">
                <el-option value="1" label="تامه">تامه</el-option>
                <el-option value="2" label="مصنعه">مصنعه</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="handleAddNewProduct"
          >تسجيل خامة
        </el-button>
        <el-button class="mr-3" @click="addProductFormVisible = false"
          >إلغاء</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios";
export default {
  name: "OperationsPage",
  data: () => {
    return {
      inMaterialName: "",
      outMaterialName: "",
      inMaterialQty: "",
      outMaterialQty: "",
      inMaterialList: [],
      outMaterialList: [],
      materialList: [],
      materialForm: {
        out_materials: [],
        in_materials: [],
      },
      selectedMaterial: null,
      disabled: false,
      addProductFormVisible: false,
      productForm: {
        name: "",
        material_category_id: "",
        type: "",
      },
      rules: {
        name: [{ required: true, message: "الاسم مطلوب", trigger: "blur" }],
        material_category_id: [
          { required: true, message: "التصنيف مطلوب", trigger: "blur" },
        ],
        type: [{ required: true, message: "النوع مطلوب", trigger: "blur" }],
      },
      materialCategories: [],
      searchValue: '',
    };
  },
  mounted() {
    this.getMaterialCategories();
  },
  computed: {
    nativeMaterialList() {
      return this.materialList.filter((material) => material.type === 2);
    },
    compoundMaterialList() {
      return this.materialList.filter((material) => material.type === 1);
    },
  },
  methods: {
    handleSearch(e) {
      this.searchValue = e;
      if (e !== "") {
        axiosApi
          .get("materials/search", { params: { name: e, supplier_id: "1", inventory_id: 1 } })
          .then((res) => {
            this.materialList = res.data;
          });
      } else {
        this.materialList = [];
      }
    },
    setSelectedIn(e) {
      if (e == "0" && e !== "") {
        this.addProductFormVisible = true;
        this.productForm.name = this.searchValue;
        this.inMaterialName = ""
      } else {
        this.selectedMaterial = this.materialList.filter(
          (material) => material.id == this.inMaterialName
        )[0];
      }
    },
    getMaterialCategories() {
      axiosApi.get("material_categories").then((res) => {
        this.materialCategories = res.data;
      });
    },
    setSelectedOut(e) {
      if (e == "0") {
        this.addProductFormVisible = true;
        this.productForm.name = e;
      }
      this.selectedMaterial = this.materialList.filter(
        (material) => material.id == this.outMaterialName
      )[0];
    },
    addInMaterial() {
      this.inMaterialList = [
        {
          name: this.selectedMaterial.name,
          id: this.selectedMaterial.id,
          qty: this.inMaterialQty,
        },
      ];
      this.inMaterialName = "";
      this.inMaterialQty = "";
      this.$refs.materialName.focus();
      this.outMaterialList = this.selectedMaterial.ingredients.map(ingredient => {
        return {
          name: ingredient.child.name,
          id: ingredient.child_id,
          qty: ingredient?.qty,
        }
      });
    },
    addOutMaterial() {
      const materialIndex = this.outMaterialList.findIndex(
        (material) => material.id == this.outMaterialName
      );
      if (materialIndex === -1) {
        this.outMaterialList.push({
          name: this.selectedMaterial.name,
          id: this.selectedMaterial.id,
          qty: this.outMaterialQty,
        });
      } else {
        const qty =
          +this.outMaterialList[materialIndex].qty + +this.outMaterialQty;
        this.outMaterialList.splice(materialIndex, 1, {
          ...this.outMaterialList[materialIndex],
          qty,
        });
      }
      this.outMaterialName = "";
      this.outMaterialQty = "";
      this.$refs.materialName.focus();
    },
    deleteMaterial(index) {
      this.outMaterialList.splice(index, 1);
    },
    handleSubmitMaterial() {
      this.materialForm.in_materials = this.outMaterialList.map((material) => {
        return {
          id: material.id,
          qty: +material.qty,
        };
      });
      this.materialForm.out_materials = this.inMaterialList.map((material) => {
        return {
          id: material.id,
          qty: +material.qty,
        };
      });
      if (
        this.materialForm.out_materials.length > 0 &&
        this.materialForm.in_materials.length > 0
      ) {
        const loading = this.$loading({
          lock: true,
          text: false,
          spinner: "el-icon-loading",
          background: "rgba(255,255,255,.7)",
          customClass: "fullscreen-loading",
        });
        axiosApi
          .post("operations", this.materialForm)
          .then(() => {
            this.materialForm.in_materials = "";
            this.materialForm.out_materials = [];
            this.inMaterialList = [];
            this.outMaterialList = [];
            this.$notify({
              title: "تمت العملية بنجاح",
              message: "تم حفظ بيانات التشغيل بنجاح",
              type: "success",
              duration: 1500,
            });
          })
          .finally(() => {
            loading.close();
          });
      }
    },
    handleAddNewProduct() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: false,
            spinner: "el-icon-loading",
            background: "rgba(255,255,255,.7)",
            customClass: "fullscreen-loading",
          });
          axiosApi
            .post("materials", this.productForm)
            .then((res) => {
              this.$notify({
                title: "تمت العملية بنجاح",
                message: "تم حفظ بيانات الخامة بنجاح",
                type: "success",
                duration: 1500,
              });
              this.addProductFormVisible = false;
              this.materialList.push(res.data);
              this.selectedMaterial = res.data;
              this.materialForm.material_id = res.data.id;
            })
            .finally(() => {
              loading.close();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.operations {
  .el-table {
    td {
      &.is-right {
        .cell {
          text-align: right;
        }
      }
    }
    th {
      &.is-right {
        .cell {
          text-align: right;
        }
      }
    }
  }
}
</style>