var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app","dir":"rtl"}},[_c('div',{staticClass:"heaven-content",style:({
      'padding-left': _vm.isAuth() && _vm.$route.name != 'printOrder' ? '50px' : '0',
    })},[(
        _vm.isAuth() &&
        _vm.$route.name != 'printOrder' &&
        _vm.$route.name != 'printBarcode'
      )?_c('Siderbar'):_vm._e(),_c('router-view',{attrs:{"dir":"rtl"}})],1),_c('transition',[(_vm.notification)?_c('section',{staticClass:"d-flex push-notify",staticStyle:{"direction":"rtl"},on:{"click":function($event){return _vm.$router.push('/pendding-orders')}}},[_c('div',{staticStyle:{"margin-left":"15px"}},[_c('img',{staticClass:"mt-2",attrs:{"src":require("./images/heven-logo.png"),"alt":""}})]),_c('div',[_c('h6',{staticClass:"mt-2 mb-0",staticStyle:{"text-align":"right"}},[_vm._v("طلب جديد")]),_c('p',{staticClass:"mb-0",staticStyle:{"text-align":"right"}},[_vm._v(" هناك طلب جديد في الطلبات المعلقة ")])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }