<template>
  <div id="app" dir="rtl">
    <div
      :style="{
        'padding-left': isAuth() && $route.name != 'printOrder' ? '50px' : '0',
      }"
      class="heaven-content"
    >
      <Siderbar
        v-if="
          isAuth() &&
          $route.name != 'printOrder' &&
          $route.name != 'printBarcode'
        "
      />

      <router-view dir="rtl" />
    </div>

    <transition>
      <section
        class="d-flex push-notify"
        v-if="notification"
        @click="$router.push('/pendding-orders')"
        style="direction: rtl"
      >
        <div style="margin-left: 15px">
          <img class="mt-2" src="./images/heven-logo.png" alt="" />
        </div>
        <div>
          <h6 style="text-align: right" class="mt-2 mb-0">طلب جديد</h6>
          <p style="text-align: right" class="mb-0">
            هناك طلب جديد في الطلبات المعلقة
          </p>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import Siderbar from "@/components/sidebar";
// Scheme must be registered before the app is ready

// import Pusher from "pusher-js"; // import Pusher
// import Echo from "laravel-echo";

import axiosApi from "@/plugins/axios.js";

export default {
  components: { Siderbar },

  name: "App",
  data() {
    return {
      notificationAudio: null,
      notification: null,
    };
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("heavenDashboardUser"));
    },
  },
  created() {
    this.getPreparationTime();
    // setInterval(() => {
    //   this.getOrdersDeliverd();
    // }, 60000)
    // window.Pusher = reqsssssuire("pusher-js");
    // window.Echo = new Echo({
    //   broadcaster: "pusher",
    //   key: "04015aa9c0a283574261",
    //   cluster: "eu",
    //   disableStats: true,
    //   encrypted: true,
    //   forceTLS: false,
    //   authEndpoint: "http://haven-test.mongz-cashier.com/api/broadcasting/auth",
    //   auth: {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("heavenDashboardToken"),
    //     },
    //   },
    // });

    // this.notificationAudio = new Audio(require('./assets/notify.ogg'));

    // window.Echo.private(`branch_${this.user.branch_id}`).listen("BroadcastOrderEvent", (e) => {
    //   this.notificationAudio.play()

    //   console.log('--------- order notification ------',e.order)

    //   this.notification = e.order;
    //   setTimeout(() => {
    //       this.notification = null;
    //       console.log("close notification");
    //   }, 10000);

    // });
  },
  methods: {
    getOrdersDeliverd() {
      console.log("orders-delivered");
      axiosApi.get(`/orders-delivered`);
    },

    getPreparationTime() {
      axiosApi.get(`/settings`).then((res) => {
        this.$store.commit("SET_ORDER_SETTINGS", res.data);
        localStorage.setItem("preparation_time", res.data.preparation_time);
      });
    },
    goToPendingProducts() {
      this.$router.push("/pendding-orders");
    },
    printBarCode() {
      const { ipcRenderer } = require("electron");

      ipcRenderer.send("printBarCode");
    },
    isAuth() {
      return (
        localStorage.getItem("heavenDashboardToken") &&
        localStorage.getItem("heavenDashboardUser")
      );
    },
  },
};
</script>

<style lang="scss">
@import "./styles/bootstrap.scss";
@import "./styles/main.scss";
.fullscreen-loading {
  i {
    font-size: 35px !important;
  }
}
.push-notify {
  cursor: pointer;
  direction: rtl;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 4px 25px 0 #0000001a;
  max-width: 370px;
  padding: 18px 19px;
  grid-gap: 15px;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px;
  border: 2px solid #a9a9ae4a;
  border-radius: 10px;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  h6 {
    font-size: 15px;
    font-family: "Cairo";
    max-width: 270px;
    white-space: nowrap;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #168ecd;
  }

  p {
    text-align: right;
    max-width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-top: 5px;
    color: #8d8d8d;
  }
}
.el-select-dropdown {
  z-index: 9999999999 !important;
}

@font-face {
  font-family: "CairoBold";
  src: url("./assets/fonts/Cairo/Cairo-Bold.ttf");
}
@font-face {
  font-family: "CairoSemiBold";
  src: url("./assets/fonts/Cairo/Cairo-SemiBold.ttf");
}
@font-face {
  font-family: "CairoRegular";
  src: url("./assets/fonts/Cairo/Cairo-Regular.ttf");
}
h1,
h2,
h3 {
  font-family: "CairoBold";
}
h4,
h5,
h6 {
  font-family: "CairoSemiBold";
}
p,
span,
input,
label,
div,
li {
  font-family: "CairoRegular";
}

h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
  margin-bottom: 3px;
}
.el-select {
  width: 100%;
}
.vs-dialog {
  text-align: center !important;
}
.el-notification {
  direction: rtl !important;
}
.el-dropdown-menu {
  li {
    text-align: right !important;
  }
}
.el-select-dropdown {
  text-align: right;
}
.edit-delete {
  text-align: center;
}

.delete-dialog {
  .con-content {
    text-align: center;
  }
  .con-content p {
    display: inline-block !important;
  }
  .con-content h6 {
    display: inline-block !important;
  }
}

.vs-dialog__header {
  display: block;
  text-align: center;
  font-family: "CairoBold";
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 0;
  padding-bottom: 0;
}

:root {
  --danger: #e74c3c;
  --bs-danger: #e74c3c;
  --success: #2ecc71;
  --bs-success: #2ecc71;
}

.categories-selection .nav.nav-pills.card-header-pills {
  padding: 0;
  list-style: none;
  display: flex;
  li {
    padding: 0 10px;
  }
}

.categories-selection .nav-pills .nav-link.active,
.categories-selection .nav-pills .show > .nav-link {
  padding: 0px 13px;
  text-decoration: none !important;
}

.vs-button__content {
  padding-top: 2px !important;
}

.vs-dialog__header {
  padding-bottom: 0 !important;
  h4,
  h6 {
    padding: 0;
    margin: 0;
  }
}

.el-table {
  td {
    border: 1px solid #ebeef5 !important;
  }
}
.categories-selection .nav-pills .nav-link {
  text-decoration: none;
}
.el-message--success {
  text-align: center !important;
  justify-content: center;
}
.el-message--danger {
  text-align: center !important;
  justify-content: center;
}

.el-notification {
  z-index: 999999 !important;
}

.el-picker-panel.el-date-picker.el-popper {
  z-index: 99999999 !important;
}
.el-dialog__headerbtn {
  left: 20px !important;
  right: auto !important;
}
</style>
