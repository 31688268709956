<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title header-item">
          <h2>قائمة الموردين</h2>
        </el-col>
        <el-col :lg="6" :md="6" class="header-item">
          <el-input
            v-model="suppliersName"
            @keyup.native="filterCustomer"
            placeholder="بحـث باسـم المورد"
          />
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" border height="90vh">
      <el-table-column label="#" type="index" width="50"> </el-table-column>
      <el-table-column
        label="الاسم"
        prop="name"
        width="220px"
      ></el-table-column>
      <el-table-column
        label="الهاتف"
        prop="phone"
        width="150px"
      ></el-table-column>
      <el-table-column label="العنوان" prop="address"></el-table-column>
      <el-table-column label="الحساب" width="150px">
        <template slot-scope="scope">
          <span>{{ $numberToFixed(Number(scope.row.balance)) + " ج.م " }}</span>
        </template>
      </el-table-column>
      <el-table-column label="اجراء" width="110px">
        <template slot-scope="scope">
          <el-row :gutter="10">
            <el-col :lg="12">
              <el-button
                size="mini"
                type="warning"
                title="تعديل"
                icon="el-icon-edit"
                @click="editSuppliers(scope.row)"
              >
              </el-button>
            </el-col>
            <el-col :lg="12">
              <el-popconfirm
              v-if="scope.row.balance==0"
                confirm-button-text="موافق"
                cancel-button-text="إلغاء"
                icon="el-icon-info"
                icon-color="red"
                :title="'هل تريد حذف ' + ' ' + scope.row.name + ' ؟'"
                @confirm="deleteSuppliers(scope.row.id)"
              >
                <div slot="reference">
                  <el-button
                    title="حذف"
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                  ></el-button>
                </div>
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
  
      :fullscreen="false"
      title="تعديل بيانات المورد"
      :visible.sync="editFormVisible"
      v-if="selectedSupplier"
    >
      <el-form
        :rules="rules"
        ref="NewSuppliers"
        :model="selectedSupplier"
        label-width="120px"
        label-position="top"
      >
        <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
          <el-col :xl="10" :lg="10" :md="24" :sm="24">
               <label
                    class="mt-3 mb-3"
                  >
                    الاسم
                  </label>
            <el-form-item  prop="name">
             
              <el-input
                class="input3"
                @focus="$event.target.select()"
                v-model="selectedSupplier.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
               <label
                    class="mt-3 mb-3"
                  >
                رقم    الهاتف
                  </label>
            <el-form-item prop="phone">
              <el-input
                class="input4"
                id="phone"
                @focus="$event.target.select()"
                v-model="selectedSupplier.phone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :lg="8" :md="12" :sm="12">
             <label
                    class="mt-3 mb-3"
                  >
                    العنوان
                  </label>
            <el-form-item prop="address">
              <el-input
                class="input5"
                id="address"
                @focus="$event.target.select()"
                v-model="selectedSupplier.address"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="update">حفظ التعديلات</el-button>
        <el-button class="mr-3" @click="editFormVisible = false"
          >إلغاء</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  data() {
    return {
      tableData: [],
      editFormVisible: false,
      selectedSupplier: {},
      rules: {
        type: [
          { required: true, message: "حدد نوع حساب العميل", trigger: "blur" },
        ],
        name: [{ required: true, message: "حدد اسم العميل", trigger: "blur" }],
        account: [
          { required: true, message: "حدد الرصيد الافتتاحي", trigger: "blur" },
        ],
        price_type: [
          { required: true, message: "حدد سياسة التسعير ", trigger: "blur" },
        ],
        address: [{ required: true, message: "حدد العنوان ", trigger: "blur" }],
        phone: [
          { required: true, message: "حدد رقم الهاتف", trigger: "blur" },
          { min: 11, max: 11, message: "حدد رقم هاتف صحيح", trigger: "change" },
        ],
      },
      suppliersName: "",
      excelFields: {
        الاسم: "name",
        الحساب: "totalBalance",
        الهاتف: "phone",
        العنوان: "address",
      },
    };
  },
  methods: {
    numberToFixed(number) {
      return Math.floor(number * 100) / 100;
    },
    getAllSuppliers() {
      const loading = this.$loading({
        lock: true,
        text: "جاري تحميل البيانات",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axiosApi
        .get("suppliers")
        .then((res) => {
          this.tableData = res.data;
          localStorage.setItem("suppliersReportAll", JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },
    editSuppliers(row) {
      this.selectedSupplier = {};
      this.selectedSupplier = row;
      this.editFormVisible = true;
    },
    update() {
      const loading = this.$loading({
        lock: true,
        text: "جاري تحميل البيانات",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axiosApi
        .put(`suppliers/${this.selectedSupplier.id}`, this.selectedSupplier)
        .then((res) => {
          this.editFormVisible = false;
          this.selectedSupplier = {};
          this.tableData = res.data;
          this.$notify({
            title: "تمت العملية بنجاح",
            message: "تم حفظ بيانات العميل بنجاح",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "حدث خطأ",
            message: "عفوا تأكد من بيانات المطلوبة",
            duration: 1500,
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    deleteSuppliers(id) {
      const loading = this.$loading({
        lock: true,
        text: "جاري تحميل البيانات",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axiosApi
        .delete(`suppliers/${id}`)
        .then((res) => {
          this.$notify({
            title: "تمت العملية بنجاح",
            message: "تم حذف بيانات العميل بنجاح",
            type: "success",
            duration: 1500,
          });
          this.getAllSuppliers();
          console.log(res);
        })
        .catch(() => {
          this.$notify.error({
            title: "حدث خطأ",
            message: "عفوا تأكد من بيانات المطلوبة",
            duration: 1500,
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    filterCustomer() {
      let Data = [];
      let ReportAll = JSON.parse(localStorage.getItem("suppliersReportAll"));
      if (this.suppliersName !== "") {
        for (let i = 0; i < ReportAll.length; i++) {
          if (ReportAll[i].name.match(this.suppliersName)) {
            Data.push(ReportAll[i]);
          }
        }
      } else {
        Data = ReportAll;
      }
      this.tableData = Data;
    },
  },
  mounted() {
    this.getAllSuppliers();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #ebf9fc;
}
</style>
