<template>
  <div class="supplier body">
    <el-container>
      <el-main>
        <div class="page-header">
          <el-row type="flex" justify="space-between" align="middle">
            <el-col :lg="6" :md="7" :xs="20" class="title header-item">
              <h2>سداد الي الموردين</h2>
            </el-col>
            <el-col :lg="18" :md="17">
              <el-row type="flex" class="search" :gutter="10">
                <el-col :span="14">
                  <div class="bg-white">
                    <!-- <label for="searchForCustomer">اختر اسم المورد</label> -->
                    <el-input v-model="search" placeholder="بحث الموردين"> </el-input>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="bg-white">
                    <!-- <label for="balnceFrom">المديونيه من</label> -->
                    <el-input
                      v-model="balnceFrom"
                      type="number"
                      placeholder="المديونيه من"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="bg-white">
                    <!-- <label for="balnceTo">المديونيه الي</label> -->
                    <el-input
                      v-model="balnceTo"
                      type="number"
                      placeholder="المديونيه الي"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <el-row class="cInfo">
          <el-col :span="24">
            <div class="bg-white">
              <div class="headerSide bg-red">
                <p class="text">حركه الموردين / حركه الدائنين</p>
                <p class="num">
                  <span>{{ numberToFixed(totalAccounts) }}</span> ج.م
                </p>
              </div>
              <div class="content">
                <Supplier :suppliers="suppliers" @getSuppliers="getSuppliers" :btnColor="'bgRed'" />
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Supplier from "@/components/suppliers/Pay.vue";
import axiosApi from "@/plugins/axios.js";

export default {
  components: { Supplier },
  data() {
    return {
      search: "",
      totalAccounts: 0,
      suppliers: [],
      allSuppliers: [],
      balnceFrom: "",
      balnceTo: "",
    };
  },
  created() {
    this.getSuppliers();
  },
  watch: {
    search: function (newValue) {
      this.suppliers = this.allSuppliers;

      let query = newValue.trim();
      if (query != "") {
        this.suppliers = this.suppliers.filter((supplier) => {
          return (
            supplier.name.toLowerCase().includes(query.toLowerCase()) ||
            (supplier.phone &&
              supplier.phone.toLowerCase().includes(query.toLowerCase()))
          );
        });
      } else {
        this.suppliers = this.allSuppliers;
      }
      this.calcTotalAcounts();
    },
    balnceFrom: function (newValue) {
      this.suppliers = this.allSuppliers;
      let balance = newValue.trim();
      if (balance != "") {
        this.suppliers = this.suppliers.filter((supplier) => {
          return (
            Number(supplier.balance) >= Number(balance) &&
            Number(supplier.balance) >= Number(this.balnceTo)
          );
        });
      } else {
        this.suppliers = this.allSuppliers;
      }
      this.calcTotalAcounts();
    },
    balnceTo: function (newValue) {
      this.suppliers = this.allSuppliers;
      let balance = newValue.trim();
      if (balance != "") {
        this.suppliers = this.suppliers.filter((supplier) => {
          return (
            Number(supplier.balance) <= Number(balance) &&
            Number(supplier.balance) >= Number(this.balnceFrom)
          );
        });
      } else {
        this.suppliers = this.allSuppliers;
      }
      this.calcTotalAcounts();
    },
  },
  methods: {
    numberToFixed(number) {
      return Number(Math.floor(number * 100) / 100).toLocaleString();
    },
    getSuppliers() {
      // suppliers/to-pay
      const loading = this.$loading({
        lock: true,
        text: "جاري تحميل البيانات",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let url = `suppliers?toPay=1`;
      axiosApi
        .get(url)
        .then((response) => {
          this.suppliers = response.data;
          this.allSuppliers = response.data;
          this.calcTotalAcounts();
          setTimeout(() => {
            document.querySelector(".paied").focus();
          }, 200);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loading.close();
        });
    },
    calcTotalAcounts() {
      let sum = 0;
      this.suppliers.map((supplier) => {
        supplier["amount"] = "";
        sum += Number(supplier["balance"]);
      });
      this.totalAccounts = sum;
    },
  },
};
</script>
<style lang="sass">
@import "../../styles/Pay.scss"
</style>
