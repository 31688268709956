import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

import purchases from "./modules/purchases";
import suppliers from "./modules/suppliers";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ordersByType: [],
    allTables: {},
    emptyTables: {},
    emptyCount: "",
    reservedCount: "",
    reservedTables: {},
    fullTables: {},
    fullCount: "",
    areas: [],

    selectedCategory: {},
    orders: [
      {
        notes: "",
        products: [],
        newUser: "",
        selectedAddress: { area: {} },
        selectedCustomer: {
          phone: "",
          recieved_time: "12:00 AM",
          recieve_date: new Date(),
        },
        total: 0,
        orderCostDiscount: 0,
        discount: 0,
        afterDiscounts: 0,

        payment_status: 1,
        selectFromOldAddresses: "",
        orderType: { table: {} },
        date: new Date().toISOString(),
        table_id: "",
        order_type_id: "",
        addresses: [],
        selectFromExist: "",
        departmentEmployees: {
          employees: [],
        },
      },
    ],
    selectedOrder: 0,
    currentType: {},

    showAddition: false,
    setUser: {},
    partners: [],
    employeesDepts: [],

    isLoggedIn: null,
    // url:"http://127.0.0.1:8000/images/"
    url: "http://167.172.157.191:2090/images/",
    // url:"https://haven-pos.herokuapp.com/api/images/",
    // url:"https://haven-pos.herokuapp.com/images/"

    ordersTab: "orders",
    orderSettings: null,
  },
  getters: {
    allTables: (state) => state.allTables,
    selectedOrder: state => state.selectedOrder,
    orders: state => state.orders,
    orderProductCount: state => {
      return state.orders[state.selectedOrder]?.products?.reduce((total, product) => {
        return +total + +product?.qty
      }, 0)
    },
    currentOrder: state => state.orders[state.selectedOrder]
  },
  mutations: {
    SET_ORDERS: (state, payload) => {
      state.ordersByType = payload;
    },
    UPDATE_ORDER_PRINTED: (state, payload) => {
      console.log("state.ordersByType", state.ordersByType);
      const orderIndex = state.ordersByType.findIndex((ele) => {
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeee", ele);
        return ele.id == payload.id;
      });
      console.log("this is order from state ", orderIndex);
      console.log("this is payload from state ", payload);
      if (orderIndex > -1) {
        state.ordersByType[orderIndex].printed = 1;
      }
    },

    SET_ORDER_SETTINGS: (state, payload) => {
      state.orderSettings = payload;

      state.orders.map((order) => {
        if (
          state.orderSettings &&
          state.orderSettings.apply_discount &&
          state.orderSettings.order_cost <= order.total
        ) {
          order.orderCostDiscount = state.orderSettings.discount;
          order.afterDiscounts =
            order.total - (order.discount + order.orderCostDiscount);
        } else {
          order.orderCostDiscount = 0;
          order.afterDiscounts = order.total - order.discount;
        }
        return order;
      });
    },

    setEmployeeDepts: (state, payload) => {
      state.employeesDepts = payload;
    },
    setPartenars: (state, payload) => {
      state.partners = payload;
    },

    setOrdersTabActive: (state, payload) => {
      if (payload) {
        state.ordersTab = "orders";
      } else {
        state.ordersTab = "clients";
      }
    },

    ResetSelectedAddressAndCustomer: (state) => {
      const orderTab = state.orders[state.selectedOrder];
      orderTab.selectedAddress = { area: {} };
      orderTab.selectedCustomer = {
        phone: " ",
        recieved_time: "12:00 AM",
        recieve_date: new Date(),
      };
    },

    // calcDiscounts: (state) => {
    //   orderTab.discounts
    //   const orderTab = state.orders[state.selectedOrder];
    //   let discounts = [];
    //   orderTab.products.forEach(ele => {
    //     if(ele.offerDiscount > 0){
    //       discounts.push(Number(ele.offerDiscount));
    //     }
    //   })

    //   discounts = [... new Set(discounts)];
    //   console.log(discounts);
    //   discounts.forEach(ele => orderTab.discounts += Number(ele));
    // },

    calcPrice: (state) => {
      const orderTab = state.orders[state.selectedOrder];

      let sum = 0;
      orderTab.products.forEach((ele) => {
        if(orderTab.orderType.id==3){
          sum += Number(ele.salaPrice ) * Number(ele.qty);

        }else{
          sum += Number(ele.mainPrice) * Number(ele.qty);

        }
      });

      console.log("sum ", sum);

      if (
        orderTab.selectedAddress &&
        orderTab.selectedAddress.area &&
        Object.keys(orderTab.selectedAddress.area).length > 0
      ) {
        console.log("case one ");

        orderTab.driver_cost = Number(
          orderTab.selectedAddress.area.driver_cost
        );
        orderTab.haven_cost = Number(orderTab.selectedAddress.area.haven_cost);
        orderTab.delivery_discount = Number(
          orderTab.selectedAddress.area.discount
        );

        sum +=
          orderTab.driver_cost +
          orderTab.haven_cost -
          orderTab.delivery_discount;
      } else {
        orderTab.haven_cost = 0;
        orderTab.driver_cost = 0;
        orderTab.delivery_discount = 0;
      }

      if (
        orderTab.selectedAddress.area &&
        Object.keys(orderTab.selectedAddress.area).length == 0
      ) {
        orderTab.haven_cost = 0;
        orderTab.driver_cost = 0;
        orderTab.delivery_discount = 0;
      }

      orderTab.total = Number(sum);

      if (
        state.orderSettings &&
        state.orderSettings.apply_discount &&
        state.orderSettings.order_cost <= orderTab.total
      ) {
        orderTab.orderCostDiscount = state.orderSettings.discount;
      } else {
        orderTab.orderCostDiscount = 0;
      }

      orderTab.afterDiscounts =
        orderTab.total - (orderTab.discount + orderTab.orderCostDiscount);
    },

    SetAreas: (state, payload) => {
      state.areas = payload;
    },

    AddTableToOrder: (state, payload) => {
      if (state.orders[state.selectedOrder].orderType.id === 3) {
        // console.log("sala")
        state.orders[state.selectedOrder].orderType.table = { ...payload };
        state.orders[state.selectedOrder].table_id = payload.id;
        localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
      }
    },

    updateTables: (state, payload) => {
      state.allTables = payload;

      state.emptyTables = state.allTables.filter((ele) => ele.status == 1);
      state.emptyCount = state.emptyTables.length;

      state.reservedTables = state.allTables.filter((ele) => ele.status == 3);
      state.reservedCount = state.reservedTables.length;

      state.fullTables = state.allTables.filter((ele) => ele.status == 2);

      state.fullCount = state.fullTables.length;
    },

    removeOrder: (state) => {
      state.orders.splice(state.selectedOrder, 1);
    },

    deleteProductInOrder: (state, payload) => {
      let targetProduct = state.orders[state.selectedOrder].products[payload];
      console.log(targetProduct);
      if (targetProduct.offerId) {
        let i = state.orders[state.selectedOrder].products.length;
        let sum = state.orders[state.selectedOrder].total;
        let discount = state.orders[state.selectedOrder].discount;
        // console.log("before ", discount)

        let product = null;
        while (i--) {
          if (
            state.orders[state.selectedOrder].products[i].offerId ==
            targetProduct.offerId
          ) {
            product = state.orders[state.selectedOrder].products[i];
            // console.log("in ", discount)

            sum -=
              Number(state.orders[state.selectedOrder].products[i].qty) *
              Number(state.orders[state.selectedOrder].products[i].price);
            state.orders[state.selectedOrder].products.splice(i, 1);
          }
        }
        if (product) {
          discount -= Number(product.offerCount) * Number(product.discount);
        }

        state.orders[state.selectedOrder].total = sum;
        // console.log("aftter ", discount)
        state.orders[state.selectedOrder].discount = discount;
      } else {
        state.orders[state.selectedOrder].products.splice(payload, 1);
      }

      if (
        state.orderSettings &&
        state.orderSettings.apply_discount &&
        state.orderSettings.order_cost <=
          state.orders[state.selectedOrder].total
      ) {
        state.orders[state.selectedOrder].orderCostDiscount =
          state.orderSettings.discount;
      } else {
        state.orders[state.selectedOrder].orderCostDiscount = 0;
      }

      localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
    },

    AddNewOrder: (state) => {
      state.orders.push({
        departmentEmployees: {
          employees: [],
        },

        date: new Date().toISOString(),

        notes: "",
        discount: 0,
        orderCostDiscount: 0,
        afterDiscounts: 0,
        products: [],
        newUser: "",
        payment_status: 1,
        selectedAddress: { area: {} },
        selectedCustomer: {
          phone: "",
          recieved_time: "12:00 AM",
          recieve_date: new Date(),
        },

        orderType: { ...state.currentType, table: {} },
        table_id: "",
        order_type_id: state.currentType.id,
        total: 0,
        addresses: [],
        selectFromExist: "",
        selectFromOldAddresses: "",
      });

      state.selectedOrder = state.orders.length - 1;
      localStorage.setItem("defaultOrderIndex", state.selectedOrder);
      localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
    },

    SetOrderType: (state, payload) => {
      if (payload) {
        state.currentType = { ...payload };
      }

      if (
        state.orders[state.selectedOrder] &&
        state.orders[state.selectedOrder].orderType
      ) {
        state.orders[state.selectedOrder].orderType = { ...payload, table: {} };
        state.orders[state.selectedOrder].order_type_id = payload.id;
      } else {
        state.orders[0].orderType = { ...payload, table: {} };
        state.orders[0].order_type_id = payload.id;
      }

      // localStorage.setItem('HevenOrders', JSON.stringify(state.orders));

      localStorage.setItem("currType", JSON.stringify({ ...payload }));

      if (
        state.orders[state.selectedOrder] &&
        state.orders[state.selectedOrder].orderType.id == 2
      ) {
        // delete state.orders[state.selectedOrder].clientInfo;
        state.orders[state.selectedOrder].selectedCustomer = {};
        state.orders[state.selectedOrder].addresses = [];
        console.log(state.orders[state.selectedOrder].selectedCustomer);
        state.orders[state.selectedOrder].selectedAddress = {};
        state.orders[state.selectedOrder].selectFromExist = "";
      }
    },

    AddProductsToOrder: (state, payload) => {
      console.log(payload);

      payload.forEach((ele) => {
        console.log(ele);
        state.orders[state.selectedOrder].total =
          Number(state.orders[state.selectedOrder].total) +
          Number(ele.priceObject.price) * Number(ele.qty);
      });

      state.orders[state.selectedOrder].products = [
        ...state.orders[state.selectedOrder].products,
        ...payload,
      ];

      state.orders[state.selectedOrder].discount += Number(payload[0].discount);

      if (
        state.orderSettings &&
        state.orderSettings.apply_discount &&
        state.orderSettings.order_cost <=
          state.orders[state.selectedOrder].total
      ) {
        state.orders[state.selectedOrder].orderCostDiscount =
          state.orderSettings.discount;
      } else {
        state.orders[state.selectedOrder].orderCostDiscount = 0;
      }

      state.orders[state.selectedOrder].afterDiscounts =
        state.orders[state.selectedOrder].total -
        (state.orders[state.selectedOrder].discount +
          state.orders[state.selectedOrder].orderCostDiscount);

      localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
    },

    IncreaseOfferQty: (state, payload) => {
      console.log(payload.products);
      payload.products.map((ele) => {
        state.orders[state.selectedOrder].products.map((ele2) => {
          if (
            ele.pivot.product_size_id == ele2.priceObject.product_size_id &&
            ele.pivot.product_id == ele2.id &&
            ele.pivot.offer_id == ele2.offerId
          ) {
            ele2.offerCount++;
            ele2.qty += Number(ele.pivot.qty);
            state.orders[state.selectedOrder].total +=
              Number(ele2.price) * Number(ele.pivot.qty);
          }
        });
      });

      state.orders[state.selectedOrder].discount += Number(payload.discount);
      state.orders[state.selectedOrder].afterDiscounts =
        state.orders[state.selectedOrder].total -
        (state.orders[state.selectedOrder].discount +
          state.orders[state.selectedOrder].orderCostDiscount);

      localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
    },

    AddProductToOrder: (state, payload) => {
      state.orders[state.selectedOrder].total =
        Number(state.orders[state.selectedOrder].total) +
        Number(payload.priceObject.price);

      if (
        state.orderSettings &&
        state.orderSettings.apply_discount &&
        state.orderSettings.order_cost <=
          state.orders[state.selectedOrder].total
      ) {
        state.orders[state.selectedOrder].orderCostDiscount =
          state.orderSettings.discount;
      }

      state.orders[state.selectedOrder].afterDiscounts =
        state.orders[state.selectedOrder].total -
        (state.orders[state.selectedOrder].discount +
          state.orders[state.selectedOrder].orderCostDiscount);

      state.orders[state.selectedOrder].products.push(payload);

      localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
    },

    AddAdditionsToProductInOrder: (state, payload) => {
      // let targetProduct = state.orders[state.selectedOrder].products.findIndex(ele => {
      //   let ifSameProductSamePrice = (ele.id == payload.id) && (ele.priceObject.price == payload.priceObject.price) && (ele.product_category_id == payload.product_category_id)
      //   return ifSameProductSamePrice;
      // })

      // console.log(targetProduct)

      // if(targetProduct < 0){
      //   console.log("payload ", payload)

      state.orders[state.selectedOrder].products.splice(
        payload.index + 1,
        0,
        payload
      );
      // }
      // }else{
      //   state.orders[state.selectedOrder].products.find(ele => {
      //     if(ele.id == payload.id && ele.priceObject.price == payload.priceObject.price){
      //       console.log("this is element ", ele)
      //       ele.qty +=1
      //     }
      //   })
      // }
    },

    ResetOrderTable: (state) => {
      if (state.orders[state.selectedOrder].orderType.id === 3) {
        state.orders[state.selectedOrder].orderType.table = {};
      }
    },

    UpdateOrderTab: (state, payload) => {
      state.selectedOrder = Number(payload);
      localStorage.setItem("defaultOrderIndex", Number(payload));

      state.currentType = state.orders[state.selectedOrder || 0].orderType;
      localStorage.setItem(
        "currType",
        JSON.stringify({ ...state.currentType })
      );
      // localStorage.setItem('HevenOrders', JSON.stringify(state.orders));
    },
    CloseTab: (state, payload) => {
      state.orders.splice(payload, 1);
      state.selectedOrder = state.orders.length - 1;
      localStorage.setItem("defaultOrderIndex", state.selectedOrder);
      localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
    },
    updateOrders: (state, payload) => {
      state.orders = payload;
    },
    updateOrder: (state, payload) => {
      let orderTab = state.orders[state.selectedOrder];

      if (orderTab.orderType.id != 3) {
        orderTab.table_id = 1;
      }

      orderTab = payload;

      let address = orderTab.selectedAddress;

      // concatenate flat and building and streat as a address descriptions
      if (address.streat) {
        address.description = `${address.streat}`;
      }
      if (address.building) {
        address.description += ` - عمارة ${address.building} `;
      }
      if (address.flat) {
        address.description += ` - شقة ${address.flat}`;
      }

      // customer alraady exist and his order in new address then i will add id = 0
      if (!orderTab.newUser && !orderTab.selectFromOldAddresses) {
        address.id = 0;
      }

      // set area_id and id = 0 when new customer
      if (
        orderTab.newUser &&
        address.area &&
        Object.keys(address.area).length > 0
      ) {
        address.id = 0;
      }

      localStorage.setItem("HevenOrders", JSON.stringify(state.orders));
    },
    UpdateSelectedCategory: (state, payload) => {
      state.selectedCategory = { ...payload };
      localStorage.setItem(
        "HevenSelectedCategory",
        JSON.stringify(state.selectedCategory)
      );
    },

    UpdateUserStatus: (state) => {
      state.isLoggedIn = true;
    },
    addition: (state) => {
      state.showAddition = !state.showAddition;
    },
    setUser(state, payload) {
      state.user = payload;
    },

    Logout(state) {
      state.setUser = {};
      window.localStorage.removeItem("heavenDashboardToken");
      window.localStorage.removeItem("heavenDashboardUser");
      router.push(`/login`);
      state.isLoggedIn = false;
    },
  },
  actions: {
    toggleAddition: ({ commit }) => {
      commit("addition");
    },
  },
  modules: {
    purchases,
    suppliers,
  },
});
