<template>
  <div
    class="el-table el-table--fit el-table--border el-table--scrollable-y el-table--enable-row-hover el-table--enable-row-transition invoice-table"
  >
    <div class="el-table__header-wrapper">
      <table
        cellspacing="0"
        cellpadding="0"
        border="0"
        class="el-table__header"
        style="width: 100%"
      >
        <colgroup>
          <template v-for="(column, index) in columns">
            <col
              :key="index"
              :name="`el-table_3_column_1${index + 1}`"
              :width="column.width"
              v-if="!column.hasCondition"
            />
            <col
              :key="index"
              :name="`el-table_3_column_1${index + 1}`"
              :width="column.width"
              v-if="column.hasCondition && column.condition"
            />
          </template>
        </colgroup>
        <thead class="has-gutter">
          <tr class="">
            <template v-for="(column, index) in columns">
              <th
                :key="index"
                colspan="1"
                rowspan="1"
                :class="`el-table_3_column_1${index + 1} is-leaf`"
                :width="column.width"
                v-if="!column.hasCondition"
              >
                <div class="cell center">{{ column.name }}</div>
              </th>
              <th
                :key="index"
                colspan="1"
                rowspan="1"
                :class="`el-table_3_column_1${index + 1} is-leaf`"
                :width="column.width"
                v-if="column.hasCondition && column.condition"
              >
                <div class="cell center">{{ column.name }}</div>
              </th>
            </template>
          </tr>
        </thead>
      </table>
    </div>
    <div
      class="el-table__body-wrapper"
      style="height: auto; max-height: 37vh"
      ref="products_table"
    >
      <table
        cellspacing="0"
        cellpadding="0"
        border="0"
        class="el-table__body"
        style="width: 100%"
      >
        <colgroup>
          <template v-for="(column, index) in columns">
            <col
              :key="index"
              :name="`el-table_3_column_1${index + 1}`"
              :width="column.width"
              v-if="!column.hasCondition"
            />
            <col
              :key="index"
              :name="`el-table_3_column_1${index + 1}`"
              :width="column.width"
              v-if="column.hasCondition && column.condition"
            />
          </template>
        </colgroup>
        <tbody class="bodytable">
          <tr
            class="el-table__row"
            v-for="(item, index) in invoice.products"
            :key="index + '2'"
            :class="item.stock < 0 ? 'bg-danger' : ''"
          >
            <td
              rowspan="1"
              colspan="1"
              class="el-table_3_column_11"
              data-label="#"
            >
              <div class="cell num">{{ index + 1 }}</div>
            </td>
            <td
              rowspan="1"
              colspan="1"
              class="el-table_3_column_12"
              data-label="المنتج"
            >
              <div class="cell">
                {{ item.name }}
              </div>
            </td>
            <td
              rowspan="1"
              colspan="1"
              class="el-table_3_column_15"
              data-label="الكمية"
            >
              <div class="cell">
                <el-popover
                  :ref="`${item.code}-${item.id}-qty`"
                  placement="left"
                  trigger="manual"
                  :content="item.toolTipMessage"
                  v-model="item.isValid"
                  popper-class="bg-danger"
                >
                </el-popover>
                <input
                  @blur="updateProductQty(item, index)"
                  class="form-control"
                  type="number"
                  @change="calcTotal"
                  @focus="$event.target.select()"
                  v-model="item.qty"
                  placeholder="الكمية"
                  min="0"
                  v-popover="`${item.code}-${item.id}-qty`"
                />
              </div>
            </td>
            <td
              rowspan="1"
              colspan="1"
              class="el-table_3_column_16"
              data-label="السعر"
            >
              <div class="cell">
                <el-popover
                  :ref="`${item.code}-${item.id}`"
                  placement="left"
                  trigger="manual"
                  :content="item.toolTipMessage"
                  v-model="item.invalidBuyCost"
                  popper-class="bg-danger"
                >
                </el-popover>
                <input
                  class="form-control"
                  type="number"
                  @change="calcTotal"
                  @focus="$event.target.select()"
                  v-model="item.price"
                  placeholder=" السعر"
                  min="0"
                  v-popover="`${item.code}-${item.id}`"
                />
              </div>
            </td>
            <td
              rowspan="1"
              colspan="1"
              class="el-table_3_column_17"
              data-label="اخر سعر"
            >
              <div class="cell center">
                <span>
                  {{ item.lastPurchaseCost }}
                </span>
                <span class="d-mob-none"> ج.م </span>
              </div>
            </td>
            <td
              rowspan="1"
              colspan="1"
              class="el-table_3_column_18"
              data-label="الاجمالي"
            >
              <div class="cell center">
                <span>
                  {{ $numberToFixed(item.price * item.qty) }}
                </span>
                <span class="d-mob-none"> ج.م </span>
              </div>
            </td>
            <td rowspan="1" colspan="1" class="el-table_3_column_23">
              <div class="cell">
                <el-popconfirm
                  confirm-button-text="موافق"
                  cancel-button-text="إلغاء"
                  icon="el-icon-info"
                  icon-color="red"
                  :title="'هل تريد حذف ' + ' ' + item.name + ' ؟'"
                  @confirm="deleteItem(item, index)"
                >
                  <el-button type="danger" size="mini" icon="el-icon-delete" slot="reference">
                    <!-- <i class="fas fa-trash-alt"></i> -->
                  </el-button>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <a id="goToBottom" href="#scrollToMe"></a>
      <div id="scrollToMe"></div>
    </div>
    
  </div>
</template>

<script>
import { columns } from "./types";
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    invoice: {
      type: Object,
      default: () => {
        return {
          inventory_id: JSON.parse(localStorage.getItem("user_inventories"))[0]
            .id,
          paied: 0,
          VAT: 0,
          supplier: null,
          total_cost: 0,
          total_buy_cost: 0,
          total_sale_cost: 0,
          total_qty: 0,
          total_discount: 0,
          percentage_discount: 0,
          products: [],
          price_strategy_id: "",
          notes: "",
          can_deliver: 0,
          load_id: null,
        };
      },
    },
  },
  components: {  },
  computed: mapGetters(["invoiceData", "purchases", "returnedPurchases"]),
  data() {
    return {
      columns,
    };
  },
  methods: {
    ...mapActions(["setSearchProduct", "selectedProductPurchases", "selectUnitsPurchases"]),
    showAddModel() {
      this.$emit("showAddModel");
    },
    selectedProduct(e) {
      this.$refs.searchProductsComponent.setValue(e.product);
      this.setSearchProduct(this.$refs.searchProductsComponent);
      this.invoiceData.product = e.product;
      this.selectedProductPurchases(e);
    },
    productQueryChange(e) {
      this.$emit("productQueryChange", e);
    },
    validateQty() {
      document.querySelector(".buy_cost").focus();
    },
    validateBuyCost() {
      if (this.invoiceData.buyPrice <= 0) {
        this.invoiceData.showBuyCostToolTip = true;
      } else {
        this.invoiceData.showBuyCostToolTip = false;
      }
    },
    calcTotal() {
      let buy_cost = 0;
      let sale_total = 0;
      this.allowSale = true;
      var qty = 0;
      for (var i = 0; i < this.invoice.products.length; i++) {
        if (this.invoice.products[i].buy_cost > this.invoice.products[i].price) {
          this.allowSale = false;
        }
        if (this.invoice.products[i].qty == "") {
          this.invoice.products[i].qty = 1;
        }
        let buy = Number(this.invoice.products[i].qty * this.invoice.products[i].buy_cost);
        buy_cost = buy_cost + buy;
        let sale = Number(this.invoice.products[i].qty * this.invoice.products[i].price);
        sale_total = sale_total + sale;
        qty = qty + Number(this.invoice.products[i].qty);
      }
      this.checkQty();
      this.invoice.total_buy_cost = Number(sale_total);
      this.invoice.total_sale_cost = Number(sale_total);
      this.invoice.total_qty = Number(qty);
      this.discountChange();
    },
    checkQty() {
      for (var i = 0; i < this.invoice.products.length; i++) {
        if (Number(this.invoice.products[i].qty) == 0) {
          this.disabled = true;
          return 1;
        } else {
          this.disabled = false;
        }
        if (Number(this.invoice.products[i].qty) > Number(this.invoice.products[i].stock)) {
          this.disabled = true;
          return 1;
        } else {
          this.disabled = false;
        }
        if (this.invoice.products[i].qty == "") {
          this.invoice.products[i].qty = 1;
        }
      }
    },
    setLocalStorage() {
      localStorage.setItem("purchases", JSON.stringify(this.purchases));
      localStorage.setItem("returnedPurchases", JSON.stringify(this.returnedPurchases));
    },
    discountChange() {
        this.invoice.percentage_discount = (
          parseFloat(this.invoice.total_discount / this.invoice.total_buy_cost) * 100
        ).toFixed(2);
        if (isNaN((+this.invoice.total_discount / +this.invoice.total_buy_cost) * 100)) {
          this.invoice.percentage_discount = 0;
        }
        this.setLocalStorage(this.invoiceType);
    },
    deleteItem(product, index) {
      this.invoice.products.splice(index, 1);
      this.calcTotal();
      this.$forceUpdate();
    }
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  margin-top: 20px;
  height: 56vh;
}
.d-mob,
.add-item-title {
  display: none;
}
.last-product-sale-table {
  width: 100%;
}
@media screen and (max-width: 600px) {
  * {
    font-size: 14px;
  }
  .el-table {
    height: auto;
  }
  .el-table__body-wrapper {
    margin-top: 10px;
  }
  .el-table__header-wrapper {
    display: none;
  }
  .el-table__footer,
  .el-table__body {
    display: block;
    tr {
      display: block;
      td {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: #fff !important;
        .cell {
          width: 100%;
        }
        &[data-label]::before {
          content: attr(data-label);
          width: 100%;
        }
        &[data-label="الكمية"],
        &[data-label="السعر"] {
          display: inline-block;
          width: 50%;
        }
        &[data-label="اخر سعر"],
        &[data-label="الاجمالي"],
        &[data-label="المحجوز"],
        &[data-label="المتاح"] {
          display: inline-block;
          width: calc(100% / 4);
        }
      }
    }
  }
  .el-table__body {
    tr {
      counter-increment: section;
      td {
        &[data-label="#"] {
          display: none;
        }
        &[data-label="المنتج"] {
          flex-wrap: nowrap;
        }
        &[data-label="المنتج"]::before {
          content: counter(section);
          width: fit-content;
        }
      }
    }
  }
  .d-mob-none {
    display: none !important;
  }
  .d-mob {
    display: block;
  }
  .add-item-title {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: bold;
    }
    button {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
  .item-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .last-product-sale-table {
    display: block;
    border: none;
    thead {
      display: none;
    }
    tbody {
      display: block;
      tr {
        display: block;
        td {
          display: flex;
          border: none;
          &[data-label]::before {
            content: attr(data-label);
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
