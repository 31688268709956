<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>حركه الخامات</h2>
        </el-col>
        <el-col :lg="18" :md="17">
          <el-row :gutter="15" type="flex">
            <el-col :lg="8" :md="8">
              <div class="search-container">
                <el-select
                  v-model="inventory_id"
                  placeholder="بحث المخازن"
                  @change="filterReport"
                  :default-first-option="true"
                >
                  <el-option
                    v-for="branch in branchesList"
                    :key="branch.id"
                    :value="branch.id"
                    :label="branch.name"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="8" :md="8">
              <ProductSearch
                :allowCreateNewProduct="false"
                :showStockZero="true"
                ref="searchProductsComponent"
                :supplier="{ id: 1 }"
                @selectedProduct="selectedProduct"
              />
            </el-col>
            <el-col :lg="8" :md="8">
              <div class="search-container">
                <el-date-picker
                  v-model="dateRange"
                  @change="filterReport"
                  type="daterange"
                  range-separator="الي"
                  format="yyyy/MM/dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="بداية الفترة"
                  end-placeholder="نهاية الفترة"
                  style="width: 100%"
                />
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-table
        class="mt-2"
        align="right"
        v-if="tableData?.length > 0"
        :data="tableData"
        border
        style="width: 100%"
        height="90vh"
        v-el-table-infinite-scroll="loadMoreData"
        :infinite-scroll-disabled="disabled"
      >
        <el-table-column type="index" label="#" />
        <el-table-column
          label="رقم الحركه"
          width="100"
          prop="operation_id"
        ></el-table-column>
        <el-table-column label="نوع الحركه">
          <template slot-scope="scope">
            {{ scope.row.material_log_type.name }}
          </template>
        </el-table-column>
        <el-table-column label="البيان">
          <template slot-scope="scope">
            {{ scope.row.notes }}
          </template>
        </el-table-column>
        <el-table-column label="الكميه" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.qty > 0 ? 'success' : 'danger'">{{
              scope.row.qty
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="بواسطة">
          <template slot-scope="scope">
            {{ scope.row.user.name }}
          </template>
        </el-table-column>
        <el-table-column label="التوقيت">
          <template slot-scope="scope">
            {{ scope.row.created_at | moment(" D/MM/YYYY | h:mm A") }}
          </template>
        </el-table-column>
        <el-table-column label="المخزون">
          <template slot-scope="scope">
            {{ scope.row.stock }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios";
import ProductSearch from "@/components/Products/Search.vue";

export default {
  name: "MaterialReport",
  components: { ProductSearch },
  data() {
    return {
      inventory_id: 1,
      material_id: null,
      tableData: [],
      branchesList: [],
      categoriesList: [],
      categoryId: null,
      dateRange: null,
    };
  },
  mounted() {
    this.getBranchesList();
  },
  methods: {
    selectedProduct(e) {
      this.material_id = e?.product?.id;
      this.getMaterialReport();
    },
    filterReport() {
      if (this.material_id) {
        this.getMaterialReport();
      }
    },
    getBranchesList() {
      axiosApi.get("inventories").then((res) => {
        this.branchesList = res.data;
      });
    },
    getMaterialReport() {
      const loading = this.$loading({
        lock: true,
        text: false,
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,.7)",
        customClass: "fullscreen-loading",
      });

      let params = {
        inventory_id: this.inventory_id,
      };

      if (this.material_id) {
        params = {
          ...params,
          material_id: this.material_id,
        };
      }
      if (this.dateRange) {
        params = {
          ...params,
          start: this.dateRange[0],
          end: this.dateRange[1],
        };
      }

      axiosApi
        .get("material-logs", { params })
        .then((res) => {
          this.tableData = res.data?.logs?.data;
          const old = res.data?.old;
          let sum = +old;
          this.tableData?.map((item) => {
            sum += +item.qty 
            console.log("sum",sum )
            item["stock"] = Number(sum).toFixed(3);
          });
          this.tableData?.unshift({
            // created_at: "",
            material_log_type: {
              id: 1,
              name: "رصيد بداية المدة",
              created_at: "2024-07-08T06:35:40.000000Z",
            },
            material_log_type_id: 1,
            notes: "رصيد بداية المدة",
            operation_id: "",
            qty: old,
            stock: old,
            synced: 1,
            // updated_at: "2024-07-16T09:43:53.000000Z",
            user: {},
            user_id: 1,
          });
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>
