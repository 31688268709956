<template>
  <div class="receiving-orders">
    <el-dialog :visible.sync="determinePrintersFirst" width="30%">
      <div class="d-block text-center">
        <div class="text-center">
          <img
            style="width: 90px; margin-bottom: 10px"
            src="@/assets/printer.svg"
            alt=""
          />
        </div>
        <h3 class="text-center">من فضلك حدد الطابعات علي الجهاز اولاً</h3>
      </div>
    </el-dialog>

    <div class="container-fluid">
      <swiper class="swiper" :options="swiperOptionBestSeller">
        <swiper-slide v-for="type in types" :key="type.id">
          <Type
            v-if="type.id != 3"
            :class="{ 'selected-type': type.id == selectedOrderType.id }"
            @click.native="selectType(type)"
            :type="type"
            color="#EBF9FC"
            style="margin-right: 10px; cursor: pointer; margin-top: 20px"
          />
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <div class="show-orders" v-if="!showDeliveryModel">
        <!-- <h6 class="text-right" style="color: #949494;font-size: 12px;">ابحث برقم الهاتف و المناطق و رقم الطلب</h6> -->

        <div class="filter-orders d-flex mb-4">
          <div class="ml-2">
            <el-input
              @keydown.enter.native="handleFilter()"
              placeholder="ابحث بالموبايل"
              v-model="mobile"
            >
            </el-input>
          </div>

          <div class="ml-2">
            <el-input
              @keydown.enter.native="handleFilter()"
              placeholder="ابحث برقم الطلب"
              v-model="code"
            ></el-input>
          </div>
          <div class="ml-2">
            <el-select
              clearable
              @change="handleFilter"
              v-model="area"
              placeholder="ابحث بالمناطق"
            >
              <!-- <el-option :key="5" label="5" :value="5"> </el-option> -->

              <el-option
                v-for="area in areas"
                :key="area.id"
                :label="area.name"
                :value="area.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ml-2">
                <el-select
          @change="handleFilter"
  placeholder="حدد الكاشير"
                      v-model="user"
                      filterable
                      clearable
                      value-key="id"
                    >
                      <el-option
                        v-for="user in users"
                        :key="user.id"
                        :label="user.name"
                        :value="user.id"
                      >
                        {{ user.name }}
                      </el-option>
                    </el-select>
          </div>
          <div class="ml-2">
            <el-select
              clearable
              @change="handleFilter"
              v-model="from"
              placeholder="من"
            >
              <!-- <el-option :key="5" label="5" :value="5"> </el-option> -->

              <el-option
                v-for="item in [
                  { name: 'كاشير', val: 'cashier' },
                  { name: 'تطبيق الموبايل', val: 'mobile' },
                ]"
                :key="item.val"
                :label="item.name"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <!-- <div v-for="order in orders" :key="order.id" class="col-md-3">
            <div class="order-card">
              <div class="order-count">
                <el-tooltip content="عدد المنتجات لهذا الطلب" placement="top">
                  <el-button> {{ order.products.length }} </el-button>
                </el-tooltip>
              </div>

              <div class="customer-data">
                <span class="text-center"> تفاصيل الطلب</span>
                <table style="width: 100%">
                  <tr>
                    <td>رقم الطلب</td>
                    <td>{{ order.id }}</td>
                  </tr>
                  <tr>
                    <td>اسم العميل</td>
                    <td>
                      <span> <b>أ/</b> </span> {{ order.customer.name }}
                    </td>
                  </tr>
                  <tr>
                    <td>الموبايل</td>
                    <td>{{ order.customer.phone }}</td>
                  </tr>
                  <tr>
                    <td>رقم الهاتف</td>
                    <td>{{ order.customer.mobile }}</td>
                  </tr>
                  <tr>
                    <td>الحالة</td>
                    <td
                      style="border: none"
                      class="text-primary text-center"
                      v-if="order.status == 1"
                    >
                      في المطبخ
                    </td>
                    <td
                      style="border: none"
                      class="text-warning text-center"
                      v-if="order.status == 2"
                    >
                      في الطريق
                    </td>
                    <td
                      style="border: none"
                      class="text-success text-center"
                      v-if="order.status == 3"
                    >
                      تم التوصيل
                    </td>
                    <td
                      style="border: none"
                      class="text-danger text-center"
                      v-if="order.status == 4"
                    >
                      تم الرفض
                    </td>
                  </tr>
                  <tr>
                    <td>العنوان</td>
                    <td style="font-size: 12px">{{ order.address }}</td>
                  </tr>
               
                </table>

                <div class="mb-2 d-flex">
                  <vs-button
                    @click.native="showOrderProducts(order)"
                    transparent
                    class="mx-auto"
                  >
                    عرض المنتجات
                  </vs-button>
                  <vs-button
                    v-if="
                      selectedOrderType.id == 2 &&
                      order.employee &&
                      order.status == 2
                    "
                    transparent
                    @click.native="showDeliveryInfo(order)"
                    class="mx-auto"
                  >
                    السائق
                  </vs-button>
                </div>
              
              </div>

              <div class="buttons d-flex" v-if="order.status != 2">
                <div>
              
                  <el-button
                    :style="{
                      width: selectedOrderType.id != 2 ? '100%' : '90%',
                    }"
                    plain
                    @click="openModelToUpdateStatus(order)"
                    class="heven-custom"
                    type="primary"
                    >تغيير الحالة</el-button
                  >
                </div>

                <div v-if="selectedOrderType.id == 2">
                  <el-button
                    style="
                      background-color: #fe5634 !important;
                      border-color: #fe5634 !important;
                    "
                    color="#FE5634"
                    @click="openAssignToDeliveryModel(order)"
                    type="primary"
                  >
                    إسناد إلي سائق
                  </el-button>
               
                </div>
              </div>
            </div>
          </div> -->

          <el-table
            class="mt-2 infinite-list"
            align="right"
            v-if="orders.length > 0"
            :data="orders"
            border
            style="width: 100%; overflow: auto;"
            height="76vh"
            v-el-table-infinite-scroll="loadMoreData"
            :infinite-scroll-disabled="disabled"
            >
            <el-table-column label="#" type="index" width="50">
            </el-table-column>
            <el-table-column sortable width="100" label="رقم " prop="order">
            </el-table-column>

            <el-table-column width="100" label="القيمة">
              <template slot-scope="scope">
                <!-- {{ Number(scope.row.total) + Number(scope.row.restaurant_cost) +
                    Number(scope.row.driver_cost) -
                    Number(scope.row.discount) -
                    Number(scope.row.delivery_discount)
                }} -->

                {{ calcPrice(scope.row) }}
              </template>
            </el-table-column>
             <el-table-column sortable width="100" label="الخصم" prop="discount">
            </el-table-column>
            <el-table-column width="90" label="الحالة">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 1">في المطبخ</el-tag>
                <el-tag type="warning" v-if="scope.row.status == 2"
                  >في الطريق</el-tag
                >
                <el-tag type="success" v-if="scope.row.status == 3"
                  >تم التوصيل
                </el-tag>
                <el-tag type="danger" v-if="scope.row.status == 4">
                  ﻣﺮﻓﻮﺽ بعد</el-tag
                >
                <el-tag type="danger" v-if="scope.row.status == 5"
                  >ﻣﺮﻓﻮﺽ قبل</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column  width="100" label="العميل" >

               <template slot-scope="scope">
                <span v-if="scope.row?.client_name">{{
                  scope.row.client_name
                }}</span>
                <span v-else>{{
                  scope.row.customer.name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column width="120" label="الفون" prop="customer.mobile">
            </el-table-column>
            <el-table-column label="بواسطة">
              <template slot-scope="scope">
                {{
                  scope.row.from == "mobile"
                    ? "تطبيق الموبايل"
                    : scope.row.user && scope.row.user.name
                }}
              </template>
            </el-table-column>

            <el-table-column label="اﻟﺴﺎﺋﻖ">
              <template slot-scope="scope">
                <span v-if="scope.row.employee.id > 1">{{
                  scope.row.employee.name
                }}</span>
              </template>
            </el-table-column>

            <el-table-column width="160" sortable label="التوقيت">
              <template slot-scope="scope">
                <span v-if="scope.row.order.created_at">
                  {{ $moment(scope.row.order.created_at).format("lll") }}
                </span>
                <span v-if="scope.row.created_at" dir="ltr">
                  {{ scope.row.created_at | moment(" D/MM/YYYY | h:mm A") }}
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column width="0" sortable label="الاسناد">

              <template slot-scope="scope">
                <span v-if="scope.row.order.assigned_at"> {{ $moment(scope.row.order.assigned_at).format('lll') }} </span>
                    <span v-if="scope.row.assigned_at"> {{ $moment(scope.row.assigned_at).format('LLLL') }} </span>
              </template>
            </el-table-column> -->

            <el-table-column label="اجراءات" width="120">
              <template slot-scope="scope">
                <el-dropdown>
                  <el-button type="primary">
                    الإجرائات<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="openModelToUpdateStatus(scope.row)"
                      v-if="
                        scope.row.status < 3 &&
                        scope.row.order_type_id != 2 &&
                        scope.row.area_id == 1
                      "
                      >تم الإستلام
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="scope.row.status < 3 && scope.row.area_id > 1"
                      @click.native="openAssignToDeliveryModel(scope.row)"
                      >إسناد إلي سائق</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.status < 3 && scope.row.area_id > 1"
                      @click.native="resetOrder(scope.row)"
                    >
                      إلغاء السائق</el-dropdown-item
                    >

                    <!-- <el-dropdown-item @click.native="printOrder(scope.row)" v-if="scope.row.printed === 0"> الطباعة
                    </el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="row">
                  <div class="col-8">
                    <el-table
                      class="mt-2"
                      align="right"
                      :data="props.row.products"
                      border
                      style="width: 100%"
                    >
                      <el-table-column label="#" type="index" width="50">
                      </el-table-column>
                      <el-table-column
                        sortable
                        width="100"
                        label="الكمية "
                        prop="pivot.qty"
                      >
                      </el-table-column>
                      <el-table-column
                        label="الصنف "
                        prop="name"
                      ></el-table-column>
                      <el-table-column
                        width="100"
                        label="المقاس "
                        prop="pivot.size"
                      ></el-table-column>
                      <el-table-column
                        width="100"
                        label="السعر "
                        prop="pivot.price"
                      ></el-table-column>
                      <el-table-column width="100" label="الاجمالي">
                        <template slot-scope="scope">
                          <span>{{
                            scope.row.pivot.qty * scope.row.pivot.price
                          }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="col-4">
                    <div class="row pr-2 mt-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">الاجمالى</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.total }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">خصم </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.discount }}
                        </p>
                      </div>
                    </div>

                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">سعر السائق</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.driver_cost }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">سعر هيفن</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.restaurant_cost }}
                        </p>
                      </div>
                    </div>

                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">خصم توصيل</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.delivery_discount }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="props.row.point_discount"
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">خصم النقاط</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.point_discount }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="props.row.promocode_discount"
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">خصم البروموكود</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.promocode_discount }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">اسم العميل</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px" v-if="props.row.client_name">
                          {{ props.row.client_name }}
                          
                        </p>
                        <p style="margin: 10px 0px" v-else>
                          {{ props.row.customer.name }}
                          
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">رقم العميل</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.customer.mobile }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">العنوان</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">{{ props.row.address }}</p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="props.row.notes"
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">* ﻣﻼﺣﻈﺎﺕ</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.notes }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="props.row.rejected_reason"
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">* ﻣﻼﺣﻈﺎﺕ</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.rejected_reason }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="
                        props.row.payment_type && props.row.payment_type == 1
                      "
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">نوع الدفع</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">نقدي</p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="
                        props.row.payment_type && props.row.payment_type == 2
                      "
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">نوع الدفع</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">فيزا</p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="props.row.preparated_at"
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">تاريخ الاستلام</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{
                            new Date(props.row.preparated_at).toLocaleString(
                              "ar-EG"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="props.row.assigned_at"
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">تاريخ الاسناد</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{
                            new Date(props.row.assigned_at).toLocaleString(
                              "ar-EG"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="row pr-2"
                      style="text-align: start"
                      v-if="props.row.rejected_reason"
                    >
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">سبب الرفض</p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.rejected_reason }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <el-row class="mt-2 mb-2" v-if="pagination.current_page < pagination.last_page && pagination.last_page > 1">
          <el-col>
            <el-button style="width: 100%;" class="d-block" type="danger" @click="loadMoreData">تحميل المزيد</el-button>
          </el-col>
        </el-row> -->
      </div>

      <div class="show-deliveries" v-if="showDeliveryModel">
        <el-button
          class="d-block heven-secondary p-0"
          icon="el-icon-right"
          plain
          @click="showDeliveryModel = !showDeliveryModel"
          type="primary"
          >الرجوع إلي الطلبات</el-button
        >

        <div class="deliveries mt-3">
         <!-- <h6 class="text-right" style="color: #949494; font-size: 12px">
            ابحث بأسم العميل و رقم الهاتف و التليفون و المناطق
          </h6>-->
          <div class="d-flex">
            <div class="ml-2">
              <el-input
                @keydown.enter.native="getDrivers()"
                placeholder="ابحث بأسم السائق "
                v-model="driverName"
              >
              </el-input>
            </div>
            <div class="ml-2">
              <el-input
                @keydown.enter.native="getDrivers()"
                placeholder="ابحث برقم السائق  "
                v-model="driverMobile"
              >
              </el-input>
            </div>
          </div>

          <div class="row">
            <div v-for="boy in drivers" :key="boy.id" class="col-md-2">
              <div
                class="delivery-boy"
                @click="setDeliveryBoy(boy)"
                :class="{
                  'selected-delivery-boy': boy.id == currDeliveryBoy.id,
                }"
              >
                <img src="@/images/delivery-boy.png" alt="" />
                <div>
                  <h6 style="font-size: 18px; margin-bottom: 10px">
                    {{ boy.name }}
                  </h6>
                  <h6>{{ boy.mobile }}</h6>
                  <!-- <div class="row">
                                        <div class="col-md-12">
                                            <h6> <b>الراتب / </b> {{boy.salary}} <sub>L.E</sub></h6>
                                        </div>
                                        <div class="col-md-12">
                                            <h6> <b>الساعه / </b> {{boy.hour_cost}} </h6>
                                        </div>
                                    </div> -->

                  <!-- <h6> {{boy.address}} </h6> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-dialog v-model="toggleOrderProducts">
      <template #header>
        <h4 class="not-margin">منتجات الطلب</h4>
      </template>

      <div class="con-form">
        <div class="">
          <table class="custom-table">
            <thead>
              <tr>
                <td>المنتج</td>
                <td>الكمية</td>
                <td>السعر</td>
                <td>المقاس</td>
              </tr>
            </thead>

            <tr v-for="(item, index) in currOrderProducts" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.pivot.qty }}</td>
              <td>{{ item.pivot.price }}</td>
              <td>{{ item.pivot.size }}</td>
            </tr>
          </table>
        </div>
        <!-- <el-select v-model="updateStatus" placeholder="إختر الحالة">
                <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select> -->
      </div>

      <!-- <template #footer>

          <el-button style="background-color: #FE5634 !important;border-color: #FE5634 !important;color:#FFF !important" plain @click="updateStatusInBackend()" type="primary">
             تعديل الحالة

        </el-button>

        </template> -->
    </vs-dialog>

    <vs-dialog v-model="showModelToUpdateStatus">
      <template #header>
        <h4 class="not-margin">تعديل حالة الطلب</h4>
      </template>

      <div class="con-form">
        <!-- <el-select v-model="updateStatus" placeholder="إختر الحالة">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <h6>هل أنت متأكد انك تريد تغيير الحالة إلي تم التسليم ؟</h6>
      </div>

      <template #footer>
        <!-- <vs-button border @click="updateStatusInBackend()"> </vs-button> -->

        <el-button
          style="
            background-color: #fe5634 !important;
            border-color: #fe5634 !important;
            color: #fff !important;
          "
          plain
          @click="updateStatusInBackend()"
          type="primary"
        >
          تعديل الحالة
        </el-button>
      </template>
    </vs-dialog>

    <vs-dialog v-model="showDeliveryInfoModel">
      <template #header>
        <h4 class="not-margin">سائق هذا الطلب</h4>
      </template>

      <div class="con-form">
        <table class="custom-table">
          <thead>
            <tr>
              <td>الأسم</td>
              <td>الموبايل</td>
              <td>السعر</td>
              <td>تكلفة الساعة</td>
            </tr>
          </thead>

          <tr v-if="currOrder && currOrder.employee">
            <td>{{ currOrder.employee.name }}</td>
            <td>{{ currOrder.employee.mobile }}</td>
            <td>{{ currOrder.employee.salary }}</td>
            <td>{{ currOrder.employee.hour_cost }}</td>
          </tr>
        </table>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";
import Type from "@/components/Operations/Type.vue";
// import { ipcRenderer } from 'electron'

export default {
  components: {
    Type,
  },
  watch: {},
  computed: {
    noMore () {
      return this.pagination.current_page >= this.pagination.last_page;
    },
    disabled () {
      return this.loading || this.noMore
    },
  },
  data() {
    return {
        users: [],
    user:null,
      invoice_details:null,
      driverMobile: "",
      driverName: "",
      mobile: "",
      code: "",
      area: "",
      areas: [],
      printers: [],
      tableData: [],

      currOrder: [],
      toggleOrderProducts: false,
      currOrderProducts: [],
      currDeliveryBoy: {},
      drivers: [],
      updateStatus: "",
      showDeliveryModel: false,
      orders: [],
      statusOptions: [
        { label: "قيد الإنتظار", value: 1 },
        { label: "في الطريق", value: 2 },
        { label: "تم التوصيل", value: 3 },
        // { label: "تم الرفض", value: 4 },
      ],
      showModelToUpdateStatus: false,
      active: 0,
      selectedOrderType: {},
      types: [],
      from: "",
      determinePrintersFirst: false,
      showDeliveryInfoModel: false,
      pagination: {
        current_page: 1,
        last_page: 1,
      },
      swiperOptionBestSeller: {
        slidesPerView: 8,
        spaceBetween: 5,
        slidesPerGroup: 1,
        loopFillGroupWithBlank: false,
        breakpoints: {
          1200: {
            slidesPerView: 8,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          350: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      startFilter: false,
      loading: false,
    };
  },

  created() {
    this.getOrderTypes(true);
    this.getDrivers();
    this.getAllAreas();
    this.getAllPrinters();
    this.getUsers();

  },

  methods: {
      getUsers() {
      const loading = this.$loading({
          lock: true,
          text: false,
          spinner: 'el-icon-loading',
          background: 'rgba(255,255,255,.7)',
          customClass: 'fullscreen-loading'
        });
      axiosApi.get(`/users`).then((res) => {
        this.users = res.data;
      }).finally(() => loading.close());
    },
    handleFilter() {
      this.startFilter = true;
      this.getOrdersByType();
    },
    castDate(date) {
      return new Date(date).toLocaleString("ar-EG");
    },
    calcPrice(order) {
      const collectedItems =
        +order.total + +order.restaurant_cost + +order.driver_cost;
      const discountItems =
        +order.point_discount +
        +order.promocode_discount +
        +order.delivery_discount 
        +
        +order.discount
        ;
      return collectedItems - discountItems;
    },
    async printOrder(order) {
      let res = await axiosApi.post(`/print-order/${order.id}`);
      order.printed = res.data && res.data.printed;
      this.printInvoiceDetails({
        products: [...order.products],
        invoice: order,
        kitchenPrinters: true,
        sendPrinted: true,
      });
      this.$store.commit("SET_ORDERS", this.orders);
    },
    showDeliveryInfo(order) {
      this.currOrder = { ...order };
      this.showDeliveryInfoModel = true;
    },

    getAllAreas() {
      axiosApi.get(`/areas`).then((res) => {
        this.areas = res.data;
      });
    },
    showOrderProducts(order) {
      this.toggleOrderProducts = true;
      this.currOrderProducts = [...order.products];
    },
    printInvoiceDetails(products) {
      let productsCategories = [];
      for (let i = 0; i < products.length; i++) {
        productsCategories.push(products[i].product_category_id);
      }

      productsCategories = [...new Set(productsCategories)];

      let productsPrinters = [];
      productsCategories.map((ele2, index) => {
        productsPrinters.push([]);
        products.map((ele) => {
          if (ele.product_category_id == ele2) {
            productsPrinters[index].push(ele);
          }
        });
      });

      const { ipcRenderer } = require("electron");
      ipcRenderer.send("printOrder", products);
    },

    getAllPrinters() {
      if (JSON.parse(localStorage.getItem("printers"))) {
        let printers = JSON.parse(localStorage.getItem("printers"));

        if (printers.findIndex((ele) => !ele.devicePrinter) > -1) {
          this.printers = [];
          return;
        }
      }

      this.printers = JSON.parse(localStorage.getItem("printers"));
    },

    setDeliveryBoy(delivery) {
      if (!this.printers || this.printers.length == 0) {
        this.determinePrintersFirst = true;

        // setTimeout(() => {
        //   this.$bvModal.hide("determinePrintersFirst");
        // }, 2000);

        return;
      }

      this.currDeliveryBoy = { ...delivery };
      axiosApi
        .put(`/orders/${this.currOrder.id}`, {
          employee_id: this.currDeliveryBoy.id,
        })
        .then((res) => {
         this.invoice_details=res.data;
         this.printInvoice();
          this.$notify({
            title: "تم بنجاح",
            message: "تم إسناد السائق إلي الطلب بنجاح",
            type: "success",
          });
          this.showDeliveryModel = false;
          this.startFilter = true;
          this.getOrdersByType();
        });
    },
    printInvoice(){
      // ! send invoice to print
      //  this.invoice_details[0].created_at=this.$moment(this.invoice_details[0].created_at).tz('Asia/Riyadh').format('YYYY-MM-DD hh:mm A');
     this.invoice_details[0].products.map((product)=>{
        console.log("p",product)
        product['price']=parseFloat(product['pivot']["price"])
        product['qty']=parseFloat(product["pivot"]["qty"])
        product["total"]=parseFloat(product['pivot']["price"]) * parseFloat(product["pivot"]["qty"])
      })
      let data={
         "printerName": this.printers[0].devicePrinter,
        invoice:this.invoice_details[0],
          CompanyName:"",
          showLogo:true,
        notes:"notes",
        showLogo:true,
        CompanyName:"الـكـتـكـوت",
      
      }
      axiosApi
        .post(
          `http://localhost:16881/api/PrintMainInvoice`,data
        )

        .then((response) => {
         console.log('printed')
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDrivers() {
      let url = `/employees/drivers?page=1`;
      if (this.driverMobile.trim() != "") {
        url += "&mobile=" + this.driverMobile;
      }
      if (this.driverName.trim() != "") {
        url += "&name=" + this.driverName;
      }

      axiosApi.get(url).then((res) => {
        this.drivers = res.data.data;
      });
    },
    openAssignToDeliveryModel(order) {
      this.showDeliveryModel = true;
      this.currOrder = { ...order };
    },
    updateStatusInBackend() {
      this.showModelToUpdateStatus = false;
      const loading = this.$vs.loading();
      axiosApi
        .put(`/orders/${this.currOrder.id}/status`, {
          status: 3,
        })
        .then(() => {
          this.$notify({
            title: "تم بنجاح",
            message: "تم تحديث حالة الطلب بنجاح",
            type: "success",
          });
          this.getOrdersByType();
        })
        .finally(() => loading.close());
    },
    resetOrder(order) {
      this.showModelToUpdateStatus = false;
      const loading = this.$vs.loading();
      // orders-reset/{id}
      axiosApi
        .post(`/orders-reset/${order.id}`)
        .then(() => {
          this.$notify({
            title: "تم بنجاح",
            message: "تم تحديث حالة الطلب بنجاح",
            type: "success",
          });
          this.getOrdersByType();
        })
        .finally(() => loading.close());
    },
    openModelToUpdateStatus(order) {
      this.currOrder = { ...order };
      this.updateStatus = this.currOrder.status;
      this.showModelToUpdateStatus = true;
    },
    selectType(type) {
      this.startFilter = true;
      this.selectedOrderType = { ...type };
      this.getOrdersByType();
    },
    getOrdersByType() {
      if (this.startFilter) {
        this.pagination = {
          current_page: 1,
          last_page: 1,
        }
        this.tableData = [];
        this.orders = [];
      }
      const loading = this.$vs.loading();
      let url = `/orders-by-type/${this.selectedOrderType.id}?page=${this.pagination.current_page}`;
      if (this.mobile.trim() != "") {
        url += "&mobile=" + this.mobile;
      }
      if (this.code.trim() != "") {
        url += "&code=" + this.code;
      }
      if (this.area != "") {
        url += "&area=" + this.area;
      }
       if (this.user >0) {
        url += "&user=" + this.user ;
      }
      if (this.from != "") {
        url += "&from=" + this.from;
      }
      axiosApi
        .get(url)
        .then((res) => {
          this.pagination.current_page = res.data.current_page;
          this.pagination.last_page = res.data.last_page;
          if (this.orders.length > 0) {
            this.orders = [...this.orders, ...res.data.data];
            this.$store.commit("SET_ORDERS", this.orders);
            this.tableData = [...this.tableData, ...res.data.data];
          } else {
            this.orders = res.data.data;
            this.$store.commit("SET_ORDERS", res.data.data);
            this.tableData = res.data.data;
          }
        })
        .finally(() => {
          this.startFilter = false;
          loading.close()
          this.loading = false;
        });
    },
    loadMoreData() {
      this.loading = true;
      if (this.pagination.last_page > this.pagination.current_page) {
        this.pagination.current_page++;
        this.getOrdersByType();
      }
    },
    getOrderTypes(inCreated = false) {
      const loading = this.$vs.loading();
      let x = axiosApi
        .get("order-types")
        .then((res) => {
          this.types = res.data;
        })
        .finally(() => loading.close());
      if (inCreated) {
        Promise.all([x]).then(() => {
          this.selectedOrderType = this.types[0];
          this.getOrdersByType();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.con-form {
  .el-select {
    width: 100%;
    margin-bottom: 14px;
  }
}

.el-select-dropdown.el-popper {
  z-index: 999999 !important;
}

.selected-type {
  background: #fe5634 !important;
  color: #fff !important;
}

.selected-delivery-boy {
  background: #f7f7f7 !important;
}

.receiving-orders {
  margin-top: 20px;
}

.receiving-orders {
  .swiper-slide {
    width: auto !important;
  }

  .deliveries {
    .delivery-boy {
      cursor: pointer;
      padding: 25px 0;
      // box-shadow: 0 4px 25px 0 #0000000f;
      border-radius: 17px;
      margin-top: 15px;
      text-align: center !important;

      img {
        width: 67px;
      }

      h6 {
        font-size: 12px;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.show-orders {
  padding: 0 40px !important;

  .order-card {
    position: relative;
    box-shadow: 0 4px 25px 0 #0000000f;
    padding: 6px 7px;
    margin-bottom: 15px;
    text-align: start;
    height: 322px;

    .order-count {
      button {
        padding: 8px 10px 5px;
        border: 1px solid #eee;
        border-radius: 50%;
      }

      position: absolute;
      top: 11px;
      right: 10px;
    }

    .customer-data {
      table {
        font-family: din;
        //    margin-bottom:10px;
      }

      table,
      tr,
      td {
        //    border: 1px solid #333;
        padding: 3px;
      }

      td {
        &:last-of-type {
          text-align: center;
          padding: 0 9px;
          font-size: 14px;
        }

        &:first-of-type {
          width: 33%;
        }
      }

      > span {
        display: block;
        background: #ebf9fc;
        padding: 8px;
        margin-bottom: 4px;
        /* border-bottom: 1px solid #ccc; */
        color: #545352;
      }

      h6 {
        margin-bottom: 4px;
      }

      .additional-data {
        h6 {
          font-size: 10px;
          color: #675d5d;
          margin-bottom: 6px;
        }
      }

      .total-price {
        b {
          font-size: 20px;
        }
      }
    }

    .buttons {
      div {
        flex: 1;
        text-align: center;

        button {
          width: 90%;
          margin: 0;
        }
      }
    }
  }
}

.el-button--primary.is-plain {
  color: #fe5634 !important;
  background: #ffffff !important;
  border-color: #fe5634 !important;
  transition: all 0.5s ease;

  &:hover {
    background: #fe5634 !important;
    color: #fff !important;
  }
}

.custom-table {
  width: 100%;
  direction: rtl;
  font-family: "din";

  thead {
    font-family: "din-bold";
  }

  td {
    font-size: 13px;
  }

  tr {
    &:nth-of-type(even) {
      background: #f7f7f7;
    }
  }
}
</style>
