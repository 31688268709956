import axiosApi from '@/plugins/axios';

export default {
  state: {
    suppliersLoading: false,
    suppliersList: [],
    showFormDialog: false,
    suppliersForm: {
      name: '',
      balance: '',
      address: '',
      phone: '',
    },
    selectedSupplier: null,
  },
  getters: {
    suppliersLoading: state => state.suppliersLoading,
    suppliersList: state => state.suppliersList,
    suppliersForm: state => state.suppliersForm,
    showFormDialog: state => state.showFormDialog,
    selectedSupplier: state => state.selectedSupplier,
  },
  mutations: {
    GET_SUPPLIERS_LOADING(state, payload) {
      state.suppliersLoading = payload;
    },
    GET_SUPPLIERS_LIST(state, payload) {
      state.suppliersList = payload;
    },
    GET_SUPPLIERS_FORM(state, payload) {
      state.suppliersForm = payload;
    },
    GET_SHOW_FORM_DIALOG(state, payload) {
      state.showFormDialog = payload;
    },
    GET_SELECTED_SUPPLIER(state, payload) {
      state.selectedSupplier = payload;
    },
  },
  actions: {
    getSuppliers({ commit }) {
      axiosApi.get("suppliers").then((res) => {
        commit("GET_SUPPLIERS_LIST", res.data);
      })
    },
    getSupplierForm({ commit }, data) {
      commit("GET_SUPPLIERS_FORM", data);
    },
    getShowFormDialog({ commit }, data) {
      commit("GET_SHOW_FORM_DIALOG", data);
    },
    getCreateSupplier({ commit, dispatch, getters }, data) {
      commit("GET_SUPPLIERS_LOADING", true);
      axiosApi.post("suppliers", data).then(() => {
        commit("GET_SUPPLIERS_LOADING", false);
        dispatch("getSuppliers");
        commit("GET_SHOW_FORM_DIALOG", false);
        const selectedSupplier = getters.suppliersList.filter(supplier => supplier.name === data.name)[0]
        commit("GET_SELECTED_SUPPLIER", selectedSupplier);
      }).catch(() => {
        commit("GET_SUPPLIERS_LOADING", false);
      })
    },
    getSelectedSupplier({ commit }, data) {
      commit("GET_SELECTED_SUPPLIER", data);
    }
  },
}