<template>
  <div class="customer">
    <el-collapse
      v-model="selectedAccount"
      accordion
      @change="handleChangeCollapse"
    >
      <el-collapse-item
        v-for="(supplier, index) in suppliers"
        :key="index"
        :name="supplier.id"
      >
        <template slot="title">
          <el-row type="flex" align="middle">
            <el-col :span="12" class="alignItem">
              <p class="text">{{ supplier.name }}</p>
            </el-col>
            <el-col :span="12" class="d-flex justify-content-end">
              <el-popconfirm
                class="mr-auto"
                confirm-button-text="موافق"
                cancel-button-text="إلغاء"
                icon="el-icon-info"
                icon-color="red"
                :title="
                  'هل تريد عرض حساب المورد /  ' + ' ' + supplier.name + ' ؟'
                "
                @confirm="goTo(`/purchases/supplier-account/${supplier.id}`)"
              >
                <div slot="reference">
                  <p class="text">سجل الحركات</p>
                </div>
              </el-popconfirm>
              <p class="text"></p>
              <p class="num">
                {{ numberToFixed(+supplier.balance).toLocaleString() }} ج.م
              </p>
              <!-- @click.stop -->
              <input
                @keyup.enter.stop
                @click.stop
                type="number"
                :class="'paied' + supplier.id"
                class="form-control paied"
                placeholder="قيمه السداد"
                @keyup.enter="pay(supplier)"
                v-model="supplier.amount"
              />
              <el-button
                @click="pay(supplier)"
                class="btn"
                :class="btnColor"
                @click.stop
                :loading="isLoading"
              >
                تم
              </el-button></el-col
            >
          </el-row>
        </template>
        <el-row type="flex">
          <el-col :span="2">
            <el-popconfirm
              class="h mr-auto"
              confirm-button-text="موافق"
              cancel-button-text="إلغاء"
              icon="el-icon-info"
              icon-color="red"
              :title="
                'هل تريد عرض حساب المورد /  ' + ' ' + supplier.name + ' ؟'
              "
              @confirm="goTo(`/purchases/supplier-account/${supplier.id}`)"
            >
              <div slot="reference">
                <button class="main-border" style="margin-top: 5px">
                  عرض <br />
                  الحساب
                </button>
              </div>
            </el-popconfirm>
          </el-col>
          <el-col :span="22">
            <el-table
              class="mt-2"
              align="right"
              v-if="report.length > 0"
              :data="report"
              border
              style="width: 100%"
            >
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column
                sortable
                width="100"
                label="السند"
                prop="operation_id"
              >
              </el-table-column>
              <el-table-column sortable label="التوقيت" prop="created_at">
                <template slot-scope="scope">
                  {{
                    scope.row.created_at | moment(" Do / MM / YYYY | h:mm A")
                  }}
                </template>
              </el-table-column>
              <el-table-column width="350" label="بيان" prop="description">
              </el-table-column>
              <el-table-column label="القيمه">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.amount < 0 ? 'success' : 'danger'">{{
                    scope.row.amount
                  }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="بواسطة" prop="user.name">
              </el-table-column>
              <el-table-column label="الرصيد">
                <template slot-scope="scope">
                  {{ Number(scope.row.account).toFixed(3) }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import axiosApi from "@/plugins/axios.js";

export default {
  props: {
    suppliers: {
      type: Array,
    },
    btnColor: {
      type: String,
    },
  },
  data() {
    return {
      selectedAccount: "",
      report: [],
      isLoading: false,
    };
  },
  watch: {
    selectedAccount: function (newValue) {
      // alert(newValue)
      this.getSupplierReportNew(newValue);
      if (this.selectedAccount != "") {
        document.querySelector(".paied" + this.selectedAccount).focus();
      } else {
        document.querySelector(".paied").focus();
      }
    },
  },

  methods: {
    handleChangeCollapse() {},
    numberToFixed(number) {
      let result = Math.floor(number * 100) / 100;
      return result < 0 ? result * -1 : result;
    },
    pay(supplier) {
      if (supplier.amount > 0) {
        const loading = this.$loading({
          lock: true,
          text: "جاري تحميل البيانات",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        if (!this.isLoading) {
          this.isLoading = true;
          let paymentForm = {
            amount: supplier.amount,
            supplier_id: supplier.id,
          };
          axiosApi
            .post("supplier-payments", paymentForm)
            .then(() => {
              // location.reload();
              this.$notify({
                title: "تمت العملية بنجاح",
                message: "تم حفظ عملية السداد بنجاح",
                type: "success",
                duration: 1500,
              });
              this.$emit("getSuppliers");
            })
            .catch((err) => {
              if (err.response.status == 403) {
                this.$notify.error({
                  title: "حدث خطأ",
                  message: `عفوا لا يوجد رصيد || رصيد الخزنه ${err.response.data.balance}`,
                  duration: 1500,
                });
              } else {
                this.$notify({
                  title: "حدث خطأ",
                  message: "عفوا لم تتم العملية ",
                  type: "error",
                  duration: 1500,
                });
              }
            })
            .finally(() => {
              this.isLoading = false;
              loading.close();
            });
        }
      } else {
        this.$notify({
          title: "حدث خطأ",
          message: "حدد مبلغ صحيح أولاً",
          type: "error",
          duration: 1500,
        });
      }
    },
    //
    // },
    goTo(routerName) {
      this.$router.push({
        path: routerName,
      });
    },

    getSupplierSummary(account) {
      // customers/account-summary/{id}
      this.report = [];
      const loading = this.$loading({
        lock: true,
        text: "جاري تحميل البيانات",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let url = `suppliers/account-summary/${account}?limit=5`;
      axiosApi
        .get(url)
        .then((response) => {
          this.report = response.data.supplier_report;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loading.close();
        });
    },
    getSupplierReportNew(account) {
      const loading = this.$loading({
        lock: true,
        text: "جاري تحميل البيانات",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let url = `suppliers/${account}/account?limit=10`;
      axiosApi
        .get(url)
        .then((response) => {
          this.report = response.data.logs.data;
          const vm = this;
          let sum = response.data.old;
          this.report.map(function (value) {
            sum += value["amount"];
            // if (value["type"] == 1) {
            // } else {
            //   sum -= value["amount"];
            // }
            value["account"] = vm.numberToFixed(sum);
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>
