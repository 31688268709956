<template>
  <div class="receiving-orders">
    <el-dialog :visible.sync="determinePrintersFirst" width="30%">
      <div class="d-block text-center">
        <div class="text-center">
          <img style="width: 90px; margin-bottom: 10px" src="@/assets/printer.svg" alt="" />
        </div>
        <h3 class="text-center">من فضلك حدد الطابعات علي الجهاز اولاً</h3>
      </div>
    </el-dialog>

    <div class="container-fluid">


      <div class="show-orders" v-if="!showDeliveryModel">
        <!-- <h6 class="text-right" style="color: #949494;font-size: 12px;">ابحث برقم الهاتف و المناطق و رقم الطلب</h6> -->

        <div class="row">
          <div class="col-md-12">
            <div>
              <h2>الطلبات المعلقة</h2>

              <el-input @keydown.native.enter="getOrdersByType()" placeholder="يحث برقم الموبايل" v-model="mobile">
              </el-input>
              <el-table class="mt-2" align="right" v-if="tableData.length > 0" :data="tableData" border
                style="width: 100%">
                <el-table-column label="#" type="index" width="50">
                </el-table-column>
                <el-table-column sortable width="100" label="رقم " prop="order">
                </el-table-column>

                <el-table-column width="100" label="إجمالي السعر">
                  <template slot-scope="scope">
                    {{
                      calcPrice(scope.row)
                    }}
                  </template>
                </el-table-column>
                <el-table-column width="150" label="الحالة">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.status == 1">في المطبخ</el-tag>
                    <el-tag v-if="scope.row.status == 0">انتظار الموافقة</el-tag>
                    <el-tag type="warning" v-if="scope.row.status == 2">في الطريق</el-tag>
                    <el-tag type="success" v-if="scope.row.status == 3">تم التوصيل
                    </el-tag>
                    <el-tag type="danger" v-if="scope.row.status == 4">
                      ﻣﺮﻓﻮﺽ بعد</el-tag>
                    <el-tag type="danger" v-if="scope.row.status == 5">ﻣﺮﻓﻮﺽ قبل</el-tag>
                  </template>
                </el-table-column>

                <el-table-column label="العميل" prop="customer.name">
                </el-table-column>
                <el-table-column label="الفون" prop="customer.mobile">
                </el-table-column>
                <el-table-column label="بواسطة">
                  <template slot-scope="scope">
                    {{ scope.row.from == "mobile" ? "تطبيق الموبايل" : scope.row.user&&scope.row.user.name }}
                  </template>
                </el-table-column>
                <el-table-column label="بواسطة" prop="user.name">
                  <template slot-scope="scope">
                    {{ scope.row.order_type_id == 2 ? "دليفري" : "حجز" }}
                  </template>

                </el-table-column>

                <el-table-column width="250" sortable label="التوقيت">
                  <template slot-scope="scope" >
                    <span v-if="scope.row.order.created_at"> {{scope.row.order.created_at| moment(" D/MM/YYYY | h:mm A")}} </span>
                    <span v-if="scope.row.created_at"> {{ scope.row.created_at| moment(" D/MM/YYYY | h:mm A") }} </span>
                  </template>
                </el-table-column>

                <el-table-column label="اجراءات" width="150">
                  <template slot-scope="scope">
                    <!-- <el-popconfirm  @confirm="acceptOrder(scope.row)"
                      title="هل أنت متأكد من تغيير حالة الطلب ؟" confirm-button-text="قبول" cancel-button-text="إلغاء">
                      <el-button slot="reference">قبول</el-button>
                    </el-popconfirm> -->

                    <!-- <el-dropdown v-if="scope.row.order_type_id != 2">
                      <el-button type="primary">
                        الإجرائات<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="acceptOrder(scope.row)">قبول</el-dropdown-item>
                          <el-dropdown-item @click.native="openRejectModel(scope.row)" >رفض</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown> -->
                    
                    <el-dropdown >
                      <el-button type="primary">
                        الإجرائات<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="openChangeBranch(scope.row)">قبول</el-dropdown-item>
                          <el-dropdown-item @click.native="openRejectModel(scope.row)" >رفض</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>

                  </template>
                </el-table-column>
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <div class="row">
                      <div class="col-8">
                        <el-table class="mt-2" align="right" :data="props.row.products" border style="width: 100%">
                          <el-table-column label="#" type="index" width="50">
                          </el-table-column>
                          <el-table-column sortable width="100" label="الكمية " prop="pivot.qty">
                          </el-table-column>
                          <el-table-column label="الصنف " prop="name"></el-table-column>
                          <el-table-column width="100" label="المقاس " prop="pivot.size"></el-table-column>
                          <el-table-column width="100" label="السعر " prop="pivot.price"></el-table-column>
                          <el-table-column width="100" label="الاجمالي">
                            <template slot-scope="scope">
                              <span>{{
                                  scope.row.pivot.qty * scope.row.pivot.price
                              }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <div class="col-4">
                        <div class="row pr-2 mt-2" style="text-align: start">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">اسم العميل</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.customer.name }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">رقم العميل</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.customer.mobile }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">العنوان</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">{{ props.row.address }}</p>
                          </div>
                        </div>



                        <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">سعر السائق </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.driver_cost }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">سعر هيفن </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.restaurant_cost }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">خصم الدليفري </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.delivery_discount }}
                        </p>
                      </div>
                    </div>

                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">إجمالي الدليفري </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ (props.row.driver_cost + props.row.restaurant_cost ) - props.row.delivery_discount }}
                        </p>
                      </div>
                    </div>

                    



                        <div class="row pr-2" style="text-align: start" v-if="props.row.notes">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">* ﻣﻼﺣﻈﺎﺕ</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.notes }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="props.row.rejected_reason">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">* ﻣﻼﺣﻈﺎﺕ</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.rejected_reason }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="
                          props.row.payment_type && props.row.payment_type == 1
                        ">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">نوع الدفع</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">نقدي</p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="
                          props.row.payment_type && props.row.payment_type == 2
                        ">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">نوع الدفع</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">فيزا</p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="props.row.preparated_at">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">تاريخ الاستلام</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{
                                  new Date(props.row.preparated_at).toLocaleString(
                                    "ar-EG"
                                  )
                              }}
                            </p>
                          </div>
                        </div>


                        <div class="row pr-2" style="text-align: start" v-if="props.row.point_discount">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">خصم النقاط</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p> {{ props.row.point_discount }} </p>
                          </div>
                        </div>

                        <div class="row pr-2" style="text-align: start" v-if="props.row.promocode_discount">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">خصم البرومو كود</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p> {{ props.row.promocode_discount }} </p>
                          </div>
                        </div>

                        <div class="row pr-2" style="text-align: start" v-if="props.row.rejected_reason">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">سبب الرفض</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.rejected_reason }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>


            </div>
          </div>



        </div>
      </div>

      <div class="show-deliveries" v-if="showDeliveryModel">
        <el-button class="d-block heven-secondary p-0" icon="el-icon-right" plain
          @click="showDeliveryModel = !showDeliveryModel" type="primary">الرجوع إلي الطلبات</el-button>

        <div class="deliveries mt-3">
          <h6 class="text-right" style="color: #949494; font-size: 12px">
            ابحث بأسم العميل و رقم الهاتف و التليفون و المناطق
          </h6>
          <div class="d-flex">
            <div class="ml-2">
              <el-input @keydown.enter.native="getDrivers()" placeholder="ابحث بأسم السائق " v-model="driverName">
              </el-input>
            </div>
            <div class="ml-2">
              <el-input @keydown.enter.native="getDrivers()" placeholder="ابحث برقم السائق  " v-model="driverMobile">
              </el-input>
            </div>
          </div>

          <div class="row">
            <div v-for="boy in drivers" :key="boy.id" class="col-md-2">
              <div class="delivery-boy" @click="setDeliveryBoy(boy)" :class="{
                'selected-delivery-boy': boy.id == currDeliveryBoy.id,
              }">
                <img src="@/images/delivery-boy.png" alt="" />
                <div>
                  <h6 style="font-size: 18px; margin-bottom: 10px">
                    {{ boy.name }}
                  </h6>
                  <h6>{{ boy.mobile }}</h6>
                  <!-- <div class="row">
                                        <div class="col-md-12">
                                            <h6> <b>الراتب / </b> {{boy.salary}} <sub>L.E</sub></h6>
                                        </div>
                                        <div class="col-md-12">
                                            <h6> <b>الساعه / </b> {{boy.hour_cost}} </h6>
                                        </div>
                                    </div> -->

                  <!-- <h6> {{boy.address}} </h6> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-dialog v-model="toggleOrderProducts">
      <template #header>
        <h4 class="not-margin">منتجات الطلب</h4>
      </template>

      <div class="con-form">
        <div class="">
          <table class="custom-table">
            <thead>
              <tr>
                <td>المنتج</td>
                <td>الكمية</td>
                <td>السعر</td>
                <td>المقاس</td>
              </tr>
            </thead>

            <tr v-for="(item, index) in currOrderProducts" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.pivot.qty }}</td>
              <td>{{ item.pivot.price }}</td>
              <td>{{ item.pivot.size }}</td>
            </tr>
          </table>
        </div>
        <!-- <el-select v-model="updateStatus" placeholder="إختر الحالة">
                <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select> -->
      </div>

      <!-- <template #footer>

          <el-button style="background-color: #FE5634 !important;border-color: #FE5634 !important;color:#FFF !important" plain @click="updateStatusInBackend()" type="primary">
             تعديل الحالة

        </el-button>

        </template> -->
    </vs-dialog>

    <vs-dialog v-model="showModelToUpdateStatus">
      <template #header>
        <h4 class="not-margin">تعديل حالة الطلب</h4>
      </template>

      <div class="con-form">
        <!-- <el-select v-model="updateStatus" placeholder="إختر الحالة">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <h6>هل أنت متأكد انك تريد تغير الحالة إلي تم التسليم ؟</h6>
      </div>

      <template #footer>
        <!-- <vs-button border @click="updateStatusInBackend()"> </vs-button> -->

        <el-button style="
            background-color: #fe5634 !important;
            border-color: #fe5634 !important;
            color: #fff !important;
          " plain @click="updateStatusInBackend()" type="primary">
          تعديل الحالة
        </el-button>
      </template>
    </vs-dialog>

    <vs-dialog v-model="showDeliveryInfoModel">
      <template #header>
        <h4 class="not-margin">سائق هذا الطلب</h4>
      </template>

      <div class="con-form">
        <table class="custom-table">
          <thead>
            <tr>
              <td>الأسم</td>
              <td>الموبايل</td>
              <td>السعر</td>
              <td>تكلفة الساعة</td>
            </tr>
          </thead>

          <tr v-if="currOrder && currOrder.employee">
            <td>{{ currOrder.employee.name }}</td>
            <td>{{ currOrder.employee.mobile }}</td>
            <td>{{ currOrder.employee.salary }}</td>
            <td>{{ currOrder.employee.hour_cost }}</td>
          </tr>
        </table>
      </div>
    </vs-dialog>




    <vs-dialog v-model="openChangeBranchModel">
      <template #header>
        <h4 class="not-margin"> تغير الفرع لهذا الطلب </h4>
      </template>

      <div class="con-form">
        <el-form :model="currOrderToChangeBranch" :ref="currOrderToChangeBranch">
          <el-form-item :rules="[{ required: true, message: 'الفرع مطلوب' }]" prop="branch_id">
            <el-select placeholder="اختر الفرع" v-model="currOrderToChangeBranch.branch_id">
              <el-option v-for="item in branches" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <el-button @click="changeBranchAndAccept">تأكيد الطلب علي هذا الفرع</el-button>
      </div>
    </vs-dialog>



    <vs-dialog v-model="rejectModel">
      <template #header>
        <h4 class="not-margin"> رفض الطلب </h4>
      </template>

      <div class="con-form">
        <el-form :model="currOrderToChangeStatus" ref="currOrderToChangeStatus">
          <el-form-item  prop="rejected_reason">
            <el-select
               v-model="currOrderToChangeStatus.rejected_reason"
               @change="setRejectReason(currOrderToChangeStatus.rejected_reason)"
               placeholder="اختر السبب"
               clearable
            >
              <el-option v-for="item in rejectReasons" :key="item.id" :value="item" :label="item.reason"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item >
            <el-input v-model="rejected_reason_input"></el-input>
          </el-form-item>
        </el-form>

        <el-button @click="confirmRejectOrder">تأكيد رفض الطلب</el-button>
      </div>
    </vs-dialog>


  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";
// import Type from "@/components/Operations/Type.vue";

export default {
  // components: {
  //   Type,
  // },
  watch: {},

  data() {
    return {
      rejected_reason_input: '',
      currOrderToChangeStatus: {
        rejected_reason_input: ''
      },
      rejectModel: false,
      changeOrderStatus: false,
      driverMobile: "",
      driverName: "",
      mobile: "",
      code: "",
      area: "",
      areas: [],
      printers: [],
      tableData: [],

      currOrder: [],
      toggleOrderProducts: false,
      currOrderProducts: [],
      currDeliveryBoy: {},
      drivers: [],
      updateStatus: "",
      showDeliveryModel: false,
      statusOptions: [
        { label: "قيد الإنتظار", value: 1 },
        { label: "في الطريق", value: 2 },
        { label: "تم التوصيل", value: 3 },
        // { label: "تم الرفض", value: 4 },
      ],
      showModelToUpdateStatus: false,
      active: 0,
      selectedOrderType: {},
      types: [],
      determinePrintersFirst: false,
      orders: [],
      showDeliveryInfoModel: false,
      openChangeBranchModel: false,
      currOrderToChangeBranch: {},
      branches: [],
      rejectReasons: [],

      swiperOptionBestSeller: {
        slidesPerView: 8,
        spaceBetween: 5,
        slidesPerGroup: 1,
        loopFillGroupWithBlank: false,
        breakpoints: {
          1200: {
            slidesPerView: 8,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          350: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },

  created() {
    this.getOrderTypes(true);
    this.getDrivers();
    this.getAllAreas();
    this.getAllPrinters();
    this.getBranches();
  },
  computed: {
    user(){
      return JSON.parse(localStorage.getItem('heavenDashboardUser'))
    }
  },

  mounted(){
    window.Echo.private(`branch_${this.user.branch_id}`).listen("BroadcastOrderEvent", (e) => {
      console.log(this.changeOrderStatus)
      this.getOrdersByType();
      // if(!this.changeOrderStatus && this.$route.path == '/pendding-orders'){
      //   this.getOrderTypes(true);
      // }
    });
  },

  methods: {
    setRejectReason(reason){
      this.rejected_reason_input = reason.reason;
    },
    calcPrice(order){
      const collectedItems = +order.total + +order.restaurant_cost + +order.driver_cost;
      const discountItems = +order.point_discount + +order.promocode_discount + +order.delivery_discount + +order.discount;
      return collectedItems - discountItems;
    },
    confirmRejectOrder(){
      if(!this.rejected_reason_input){
        this.$notify.error({
            title: "خطأ",
            message: "سبب الرفض مطلوب",
        });
        return;
      }
      this.$refs.currOrderToChangeStatus.validate((valid) => {
        if(valid) {
          const loading = this.$vs.loading();
      axiosApi
        .put(`orders/${this.currOrderToChangeStatus.id}/status`, {
          "status":5,
          "reject_reason":this.rejected_reason_input
        })
        .then(() => {
          this.$notify({
            title: "ﺗﻢ ﺑﻨﺠﺎﺡ",
            message: "ﺗﻢ رفض اﻟﻄﻠﺐ ﺑﻨﺠﺎﺡ",
            type: "success",
          });
          this.rejectModel = false;
          this.currOrderToChangeStatus = {};
          this.rejected_reason_input = ''
          this.getOrdersByType()
        })
        .finally(() => loading.close());
        }
      })
      
    },
    getRejectReasons() {
      const loading = this.$vs.loading();
      axiosApi
        .get("reject-reasons")
        .then((res) => {
          this.rejectReasons = res.data;
        })
        .finally(() => loading.close());
    },
    openRejectModel(order){
      this.getRejectReasons();
      this.rejectModel = true;
      this.currOrderToChangeStatus = {...order}
    },
    async changeBranchAndAccept() {
      this.changeOrderStatus = true;
      const loading = this.$vs.loading();
      try {
        await axiosApi.put(`/orders/${this.currOrderToChangeBranch.id}/branch`, { branch_id: this.currOrderToChangeBranch.branch_id });
        await axiosApi.put(`/orders/${this.currOrderToChangeBranch.id}/status`, { status: 1 });
        this.$notify({
          title: "ﺗﻢ ﺑﻨﺠﺎﺡ",
          message: "ﺗﻢ قبول اﻟﻄﻠﺐ ﺑﻨﺠﺎﺡ",
          type: "success",
        });
        
        
        await this.getOrdersByType();
        this.openChangeBranchModel = false;
        this.changeOrderStatus = false;
      }
      catch {
        this.$notify.error({
          title: "خطأ",
          message: "هناك خطأ ما",
        });
      }
      finally {
        loading.close();
      }
    },
    getBranches() {
      const loading = this.$vs.loading();
      axiosApi
        .get("branches")
        .then((res) => {
          this.branches = res.data;
        })
        .finally(() => loading.close());
    },
    openChangeBranch(order) {
      this.openChangeBranchModel = true;
      this.currOrderToChangeBranch = { ...order };
    },
    acceptOrder(currOrder) {
      this.changeOrderStatus = true;
      const loading = this.$vs.loading();

      axiosApi
        .put(`/orders/${currOrder.id}/status`, {
          status: 1,
        })
        .then(() => {
          this.$notify({
            title: "ﺗﻢ ﺑﻨﺠﺎﺡ",
            message: "ﺗﻢ قبول اﻟﻄﻠﺐ ﺑﻨﺠﺎﺡ",
            type: "success",
          });
          this.getOrdersByType();
        })
        .finally(() => loading.close());
    },
    showDeliveryInfo(order) {
      this.currOrder = { ...order };
      this.showDeliveryInfoModel = true;
    },

    getAllAreas() {
      axiosApi.get(`/areas`).then((res) => {
        this.areas = res.data;
      });
    },
    showOrderProducts(order) {
      this.toggleOrderProducts = true;
      this.currOrderProducts = [...order.products];
    },
    printInvoiceDetails(products) {
      let productsCategories = [];
      for (let i = 0; i < products.length; i++) {
        productsCategories.push(products[i].product_category_id);
      }

      productsCategories = [...new Set(productsCategories)];
      console.log(products);
      console.log(productsCategories);

      let productsPrinters = [];
      productsCategories.map((ele2, index) => {
        productsPrinters.push([]);
        products.map((ele) => {
          if (ele.product_category_id == ele2) {
            productsPrinters[index].push(ele);
          }
        });
      });

      console.log(productsPrinters);

      const { ipcRenderer } = require("electron");

      console.log();
      ipcRenderer.send("printOrder", products);
      console.log("print order", products);
    },

    getAllPrinters() {
      if (JSON.parse(localStorage.getItem("printers"))) {
        let printers = JSON.parse(localStorage.getItem("printers"));

        if (printers.findIndex((ele) => !ele.devicePrinter) > -1) {
          console.log("printer hasn't device printer");
          this.printers = [];
          return;
        }
      }

      this.printers = JSON.parse(localStorage.getItem("printers"));
      console.log("printer has device printer");
    },

    setDeliveryBoy(delivery) {
      if (!this.printers || this.printers.length == 0) {
        this.determinePrintersFirst = true;

        // setTimeout(() => {
        //   this.$bvModal.hide("determinePrintersFirst");
        // }, 2000);

        return;
      }

      this.currDeliveryBoy = { ...delivery };
      axiosApi
        .put(`/orders/${this.currOrder.id}`, {
          employee_id: this.currDeliveryBoy.id,
        })
        .then((res) => {
          this.printInvoiceDetails({
            products: [...res.data[0].products],
            invoice: res.data[0],
            kitchenPrinters: false,
          });
          this.$notify({
            title: "تم بنجاح",
            message: "تم إسناد السائق إلي الطلب بنجاح",
            type: "success",
          });
          this.showDeliveryModel = false;
          this.getOrdersByType();
        });
    },
    getDrivers() {
      let url = `/employees/drivers?page=1`;
      if (this.driverMobile.trim() != "") {
        url += "&mobile=" + this.driverMobile;
      }
      if (this.driverName.trim() != "") {
        url += "&name=" + this.driverName;
      }

      axiosApi.get(url).then((res) => {
        this.drivers = res.data.data;
      });
    },
    openAssignToDeliveryModel(order) {
      console.log("order", order);
      this.showDeliveryModel = true;
      this.currOrder = { ...order };
    },
    updateStatusInBackend() {
      this.showModelToUpdateStatus = false;
      const loading = this.$vs.loading();
      axiosApi
        .put(`/orders/${this.currOrder.id}/status`, {
          status: 3,
        })
        .then(() => {
          this.$notify({
            title: "تم بنجاح",
            message: "تم تحديث حالة الطلب بنجاح",
            type: "success",
          });
          this.getOrdersByType();
        })
        .finally(() => loading.close());
    },
    resetOrder(order) {
      this.showModelToUpdateStatus = false;
      const loading = this.$vs.loading();
      // orders-reset/{id}
      axiosApi
        .post(`/orders-reset/${order.id}`)
        .then(() => {
          this.$notify({
            title: "تم بنجاح",
            message: "تم تحديث حالة الطلب بنجاح",
            type: "success",
          });
          this.getOrdersByType();
        })
        .finally(() => loading.close());
    },
    openModelToUpdateStatus(order) {
      this.currOrder = { ...order };
      this.updateStatus = this.currOrder.status;
      this.showModelToUpdateStatus = true;
    },
    selectType(type) {
      this.selectedOrderType = { ...type };
      this.getOrdersByType();
    },
    getOrdersByType() {
      const loading = this.$vs.loading();
      let url = `/orders?status=0`;
      if (this.mobile.trim() != "") {
        url += "&mobile=" + this.mobile;
      }
      if (this.code.trim() != "") {
        url += "&code=" + this.code;
      }
      if (this.area != "") {
        url += "&area=" + this.area;
      }

      axiosApi
        .get(url)
        .then((res) => {
          this.orders = res.data.data;
          this.tableData = res.data.data;
        })
        .finally(() => loading.close());
    },
    getOrderTypes(inCreated = false) {
      const loading = this.$vs.loading();
      let x = axiosApi
        .get("order-types")
        .then((res) => {
          this.types = res.data;
        })
        .finally(() => loading.close());
      if (inCreated) {
        Promise.all([x]).then(() => {
          this.selectedOrderType = this.types[0];
          this.getOrdersByType();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.con-form {
  .el-select {
    width: 100%;
    margin-bottom: 14px;
  }
}

.el-select-dropdown.el-popper {
  z-index: 999999 !important;
}

.selected-type {
  background: #fe5634 !important;
  color: #fff !important;
}

.selected-delivery-boy {
  background: #f7f7f7 !important;
}

.receiving-orders {
  margin-top: 20px;
}

.receiving-orders {
  .swiper-slide {
    width: auto !important;
  }

  .deliveries {
    .delivery-boy {
      cursor: pointer;
      padding: 25px 0;
      // box-shadow: 0 4px 25px 0 #0000000f;
      border-radius: 17px;
      margin-top: 15px;
      text-align: center !important;

      img {
        width: 67px;
      }

      h6 {
        font-size: 12px;
        margin-bottom: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.show-orders {
  padding: 0 40px !important;

  .order-card {
    position: relative;
    box-shadow: 0 4px 25px 0 #0000000f;
    padding: 6px 7px;
    margin-bottom: 15px;
    text-align: start;
    height: 322px;

    .order-count {
      button {
        padding: 8px 10px 5px;
        border: 1px solid #eee;
        border-radius: 50%;
      }

      position: absolute;
      top: 11px;
      right: 10px;
    }

    .customer-data {
      table {
        font-family: din;
        //    margin-bottom:10px;
      }

      table,
      tr,
      td {
        //    border: 1px solid #333;
        padding: 3px;
      }

      td {
        &:last-of-type {
          text-align: center;
          padding: 0 9px;
          font-size: 14px;
        }

        &:first-of-type {
          width: 33%;
        }
      }

      >span {
        display: block;
        background: #ebf9fc;
        padding: 8px;
        margin-bottom: 4px;
        /* border-bottom: 1px solid #ccc; */
        color: #545352;
      }

      h6 {
        margin-bottom: 4px;
      }

      .additional-data {
        h6 {
          font-size: 10px;
          color: #675d5d;
          margin-bottom: 6px;
        }
      }

      .total-price {
        b {
          font-size: 20px;
        }
      }
    }

    .buttons {
      div {
        flex: 1;
        text-align: center;

        button {
          width: 90%;
          margin: 0;
        }
      }
    }
  }
}

.el-button--primary.is-plain {
  color: #fe5634 !important;
  background: #ffffff !important;
  border-color: #fe5634 !important;
  transition: all 0.5s ease;

  &:hover {
    background: #fe5634 !important;
    color: #fff !important;
  }
}

.custom-table {
  width: 100%;
  direction: rtl;
  font-family: "din";

  thead {
    font-family: "din-bold";
  }

  td {
    font-size: 13px;
  }

  tr {
    &:nth-of-type(even) {
      background: #f7f7f7;
    }
  }
}

.el-dropdown-menu.el-popper {
  z-index: 999999999 !important;
}
</style>