<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>جرد الخامات</h2>
        </el-col>
        <el-col :lg="18" :md="17">
          <el-row :gutter="15" type="flex">
            <el-col :lg="6" :md="6">
              <el-select
                v-model="branchId"
                placeholder="بحث المخازن"
                @change="filterReport"
                :default-first-option="true"
              >
                <el-option
                  v-for="branch in branchesList"
                  :key="branch.id"
                  :value="branch.id"
                  :label="branch.name"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :lg="6" :md="6">
              <el-select
                v-model="stock"

                clearable
                placeholder="بحث بالمخزون"
                @change="filterReport"
              >
                <el-option label="يوجد مخزون" value="1"></el-option>
                <el-option label="لا يوجد مخزون" value="0"></el-option>
              </el-select>
            </el-col>
            <el-col :lg="6" :md="6">
              <el-select
                v-model="categoryId"
                filterable
                clearable
                placeholder="بحث بالتصنيف"
                value-key="id"
                @change="filterReport"
              >
                <el-option
                  v-for="x in categoriesList"
                  :key="x.id"
                  :label="x.name"
                  :value="x.id"
                >
                  {{ x.name }}
                </el-option>
              </el-select>
            </el-col>

          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex">
      <el-table
        class="mt-2"
        align="right"
        :data="tableData"
        border
        style="width: 100%"
        height="90vh"
        v-el-table-infinite-scroll="loadMoreData"
        :infinite-scroll-disabled="disabled"
      >
        <el-table-column label="#" type="index" width="50" />
        <el-table-column label="المنتج" prop="name"></el-table-column>
        <el-table-column label="المخزون" prop="qty"></el-table-column>
        <el-table-column label="التصنيف">
          <template slot-scope="scope">
            {{ scope.row.material_category_id | findCategoryName(categoriesList) }}
          </template>
        </el-table-column>
        <el-table-column label="المخزن">
          <template slot-scope="scope">
            {{ scope.row.inventory_id | findBranchName(branchesList) }}
          </template>
        </el-table-column>
           <el-table-column label="اجراء" width="110px">
        <template slot-scope="scope">
          <el-row :gutter="10">
            <el-col >
              <el-button
                size="mini"
                type="warning"
                title="تعديل"
                icon="el-icon-edit"
                @click="editMaterial(scope.row)"
              >
              </el-button>
            </el-col>
          </el-row>
        </template>
           </el-table-column>
      </el-table>
    </el-row>
      <el-dialog
  
      :fullscreen="false"
      title="تعديل بيانات المورد"
      :visible.sync="editFormVisible"
      v-if="selectedMaterial"
    >
      <el-form
        :rules="rules"
        ref="NewSuppliers"
        :model="selectedMaterial"
        label-width="120px"
        label-position="top"
      >
        <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
          <el-col :xl="10" :lg="10" :md="24" :sm="24">
               <label
                    class="mt-3 mb-3"
                  >
                    الاسم
                  </label>
            <el-form-item  prop="name">
             
              <el-input
                class="input3"
                @focus="$event.target.select()"
                v-model="selectedMaterial.name"
              ></el-input>
            </el-form-item>
          </el-col>
         
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="update">حفظ التعديلات</el-button>
        <el-button class="mr-3" @click="editFormVisible = false"
          >إلغاء</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  name: "PurchasesReport",
  components: {},
  data: () => {
    return {
      editFormVisible:false,
      selectedMaterial:{},
      branchId: 1,
      stock: "",
      categoryId: "",
      categoriesList: null,
      suppliers: null,
      dateRange: null,
      tableData: [],
      startFilter: false,
      pagination: {
        current_page: 1,
        last_page: 1,
      },
      branchesList: [],
    };
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  mounted() {
    this.getMaterialsGard();
    this.getCategories();
    this.getBranchesList();
  },
  methods: {
     editMaterial(row) {
      // alert('dd')
      this.selectedMaterial = {};
      this.selectedMaterial = row;
      this.editFormVisible = true;
    },
      update() {
      const loading = this.$loading({
        lock: true,
        text: "جاري تحميل البيانات",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      axiosApi
        .put(`materials/${this.selectedMaterial.id}`, this.selectedMaterial)
        .then((res) => {
          this.editFormVisible = false;
          this.selectedMaterial = {};
           this.getMaterialsGard();
          this.$notify({
            title: "تمت العملية بنجاح",
            message: "تم حفظ بيانات العميل بنجاح",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "حدث خطأ",
            message: "عفوا تأكد من بيانات المطلوبة",
            duration: 1500,
          });
        })
        .finally(() => {
          loading.close();
        });
    },
    getBranchesList() {
      axiosApi.get("inventories").then((res) => {
        this.branchesList = res.data;
      });
    },
    getCategories() {
      axiosApi.get("material_categories").then(res => {
        this.categoriesList = res.data;
      })
    },
    filterReport() {
      this.startFilter = true;
      this.getMaterialsGard();
    },
    getMaterialsGard() {
      const loading = this.$loading({
        lock: true,
        text: false,
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,.7)",
        customClass: "fullscreen-loading",
      });

      let params = {};

      if (this.branchId) {
        params = {
          ...params,
          inventory_id: this.branchId,
        };
      }
      if (this.stock) {
        params = {
          ...params,
          inStock: this.stock,
        };
      }
      if (this.categoryId) {
        params = {
          ...params,
          material_category_id: this.categoryId,
        };
      }

      if (this.startFilter) {
        this.tableData = [];
      }

      axiosApi
        .get("inventory-materials", { params })
        .then((res) => {
          this.pagination.current_page = res.data.current_page;
          this.pagination.last_page = res.data.last_page;
          if (this.tableData.length > 0) {
            this.tableData = [...this.tableData, ...res.data.data];
          } else {
            this.tableData = res.data.data;
          }
        })
        .finally(() => {
          loading.close();
          this.startFilter = false;
        });
    },
    loadMoreData() {
      if (this.pagination.last_page > this.pagination.current_page) {
        this.pagination.current_page++;
        this.getOrders();
      }
    },
  },
  filters: {
    findCategoryName: (value, categoriesList) => {
      return categoriesList?.filter(category => {
        return category.id === value
      })[0]?.name
    },
    findBranchName: (value, branchesList) => {
      return branchesList?.filter(branch => {
        return branch.id === value
      })[0]?.name
    },
  }
};
</script>
