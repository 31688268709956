<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>تصنيف الخامات</h2>
        </el-col>
        <!-- <el-col :lg="18" :md="17">
          <el-row type="flex" justify="end">
            <el-button type="primary" plain icon="el-icon-plus" @click="() => openAddCategoryModel = true"
              >اضافة تصنيف</el-button
            >
          </el-row>
        </el-col> -->
      </el-row>
    </div>
    <el-row type="flex" :gutter="15">
      <el-col :md="18" style="border-left: 1px solid #ccc;">
        <el-row type="flex" class="mt-2">
          <el-col :lg="6" v-for="item in categories" :key="item.id">
            <section class="baby-blue-section">
              <div>
                <h6>{{ item.name }}</h6>
              </div>
              <!-- <div class="edit-delete">
                <button
                  class="btn emp-dept-update"
                  @click="openUpdateCategorie(item)"
                >
                  <img src="@/images/Icon-feather-edit-3.svg" alt="" />
                </button>
                <button
                  class="btn emp-dept-delete"
                  @click="openDeleteCategory(item)"
                >
                  <img src="@/images/Icon-material-delete.svg" alt="" />
                </button>
              </div> -->
            </section>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="6" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-evenly; ">
        <h2 >اضافة تصنيف</h2>
        <el-input
        placeholder="اسم التصنيف"
        class="mt-2"
        @focus="$event.target.select()"
        v-model="categoryForm.name"
        style="width: 65%;"
      ></el-input>
      <el-button type="primary" @click="addNewCategory" :loading="loading"
          >إضافة</el-button>
      </el-col>
    </el-row>
    <vs-dialog
      title="إضافة"
      v-model="openAddCategoryModel"
      width="40%"
      class="add-operation-dialog"
    >
      <!-- center -->
      <template #header>
        <span>إضافة تصنيف جديد</span>
      </template>

      <el-input
        placeholder="اسم التصنيف"
        class="mt-2"
        @focus="$event.target.select()"
        v-model="categoryForm.name"
      ></el-input>

      <!-- This is Popup Footer -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="openAddCategoryModel = false">إلغاء</el-button>
        <el-button type="primary" @click="addNewCategory" :loading="loading"
          >إضافة</el-button
        >
      </span>
    </vs-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  name: "MaterialsCategories",
  data() {
    return {
      categories: [],
      openAddCategoryModel: false,
      categoryForm: {
        name: "",
      },
      loading: false,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      const loading = this.$loading({
        lock: true,
        text: false,
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,.7)",
        customClass: "fullscreen-loading",
      });

      axiosApi
        .get("material_categories")
        .then((res) => {
          this.categories = res.data;
        })
        .finally(() => {
          loading.close();
        });
    },
    openUpdateCategorie(item) {
      console.log("🚀 ~ openUpdateCategorie ~ item:", item);
    },
    openDeleteCategory(item) {
      console.log("🚀 ~ openDeleteCategory ~ item:", item);
    },
    addNewCategory() {
      if (this.categoryForm.name !== "") {
        this.loading = true;
        const loading = this.$loading({
          lock: true,
          text: false,
          spinner: "el-icon-loading",
          background: "rgba(255,255,255,.7)",
          customClass: "fullscreen-loading",
        });
  
        axiosApi.post("material_categories", this.categoryForm).then(() => {
          this.$notify({
              title: "تمت العملية بنجاح",
              message: "تم حفظ بيانات التصنيف بنجاح",
              type: "success",
              duration: 1500,
            });
            this.openAddCategoryModel = false;
            this.categoryForm.name = "";
          this.getCategories();
        }).finally(() => {
          loading.close();
          this.loading = false;
        })
        
      }
    },
  },
};
</script>
