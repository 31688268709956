import Vue from 'vue'
import App from './App.vue'
import router from './router'
import sharedMethods from "./mixin/sharedMethods";

import VueApexCharts from 'vue-apexcharts'
import "./plugins/moment"
import './plugins/swiper'
import ElTableInfiniteScroll from "el-table-infinite-scroll";

Vue.directive("el-table-infinite-scroll", ElTableInfiniteScroll);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts);
Vue.mixin(sharedMethods);



import {
  BootstrapVue,
  IconsPlugin
} from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// import "@/fontawesome-free-5.9.0-web/css/all.css";

Vue.use(require("vue-shortkey"));


  import veusax from 'vuesax'
  import 'vuesax/dist/vuesax.css'

  Vue.use(veusax)

import {
  initialize
} from '@/plugins/general.js';

initialize(store, router);


import 'element-ui/lib/theme-chalk/index.css';

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ar'

Vue.use(ElementUI, { locale })
import store from './store'


Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' 
//   && !localStorage.getItem('heavenDashboardToken') 
//   && !localStorage.getItem("heavenDashboardUser")) next({ name: 'Login' })
//   else next()
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
