<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center mt-2 row col-md-12">
      <div class="mb-3 col-md-3">
        <el-select
          dir="ltr"
          @change="getOrders"
          v-model="branch"
          clearable
          placeholder="حدد الفروع"
        >
          <!-- @change="getProducts" -->
          <el-option
            v-for="item in branches"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
       <div class="mb-3 col-md-3">
       <el-select
          @change="getOrders"
  placeholder="حدد الكاشير"
                      v-model="user"
                      filterable
                      clearable
                      value-key="id"
                    >
                      <el-option
                        v-for="user in users"
                        :key="user.id"
                        :label="user.name"
                        :value="user.id"
                      >
                        {{ user.name }}
                      </el-option>
                    </el-select>
       </div>

      <div class=" mb-3 col-md-4">
        <el-date-picker
          @change="getOrders"
          :format="format"
          :value-format="valueFormat"
          v-model="dateRange"
          type="datetimerange"
          range-separator="إلي"
          start-placeholder="بداية الفترة"
          end-placeholder="نهاية الفترة"
        >
        </el-date-picker>
      </div>
        <div class=" mb-3 col-md-2">
         <el-button
                @click="print"
                style="padding: 5px; height: 26px; margin: 5px"
                :type="'success'"
                
              >
           طباعه التقرير
              </el-button>
       </div>
      <!-- <div class="mr-2">
        <el-popconfirm
        @confirm="clearOrders"
  confirm-button-text='نعم متاكد'
  cancel-button-text='الغاء'
  icon="el-icon-error"
  icon-color="red"
  title="متاكد من تقفيل اليوميه ؟"
>
  <el-button slot="reference">تقفيل اليوميه</el-button>
</el-popconfirm>
      
      </div> -->

    </div>

    <div class="row mt-2">
      <el-col :span="4">
         <el-card shadow="hover">
          <span>خصومات التوصيل </span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ totalDeliveryDiscounts }}</span>
        </el-card> </el-col>
        <el-col :span="4">  <el-card shadow="hover">
          <span>خصومات  </span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ totalOfferDiscount }}</span>
        </el-card> </el-col>
      <!-- <el-col :span="4">  <el-card shadow="hover">
          <span>خصومات العروض </span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ totalOfferDiscount }}</span>
        </el-card> </el-col>
            <el-col :span="5" >
        <el-card shadow="hover">
          <span> نسبة الدليفري للمطعم</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ totalRestaurantDeliveryCost }}</span>
        </el-card>
      </el-col> -->
      <el-col :span="5" >
        <el-card shadow="hover">
          <span> نسبة الدليفري للسائق</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ totalDriverCost }}</span>
        </el-card>
      </el-col>


      <!-- <el-col :span="5" >
        <el-card shadow="hover">
          <span> {{pointsDiscounts.name}} </span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ pointsDiscounts.totalCost }}</span>
        </el-card>
      </el-col>
      <el-col :span="5" >
        <el-card shadow="hover">
          <span> {{promoCodeDiscounts.name}} </span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ promoCodeDiscounts.totalCost }}</span>
        </el-card>
      </el-col> -->
  
      <el-col :span="6">
        <el-card shadow="hover">
          <span> اجمالي توريد الخزينة </span>
          <el-divider direction="vertical"></el-divider>
          <span>{{
            totalSales -
            totalNotpaiedSales -
            totalExpenses -
            totalDriverCost - totalOfferDiscount 
          }}
          </span>
        </el-card>
      </el-col>
    </div>
    <div class="row mt-2">
      <el-card
        style="width: 48%"
        class="box-card"
        v-if="salesByCashier.length > 0"
      >
        <div slot="header" class="clearfix">
          <span>المبيعات حسب كل كاشير</span>
        </div>

        <el-table
          class="mt-2"
          align="right"
          v-if="salesByCashier.length > 0"
          :data="salesByCashier"
          border
          sum-text="الاجمالي "
          show-summary
          style="width: 100%"
        >
          <!--  -->
          <el-table-column label="#" type="index" width="80"> </el-table-column>
          <el-table-column label="الكاشير " prop="name"> </el-table-column>
          <el-table-column label="المبيعات " prop="totalCost">
          </el-table-column>
        </el-table>
      </el-card>
      <el-card
        style="width: 50%"
        class="box-card mr-2"
        v-if="salesByCashier.length > 0"
      >
        <div slot="header" class="clearfix">
          <span>اللوغي</span>
        </div>

        <el-table
          class="mt-2"
          align="right"
          v-if="notPaiedSales.length > 0"
          :data="notPaiedSales"
          border
          sum-text="الاجمالي "
          show-summary
          style="width: 100%"
        >
          <el-table-column label="#" type="index" width="80"> </el-table-column>
          <el-table-column label="البيان " prop="name"> </el-table-column>
          <el-table-column label="القيمة " prop="totalCost"> </el-table-column>
          <el-table-column label="تفاصيل ">
            <template slot-scope="scope">
              <el-button v-if="scope.row.link" @click="goTo(scope.row.link)" type="primary"
                >عرض التفاصيل</el-button
              >
              <!-- <router-link
                v-if="scope.row.link"
                title=" تفاصيل"
                class="btn btn-sm btn-success btn-color"
                :to="{
                  path: scope.row.link,
                }"
              >
                <i class="fa fa-eye ml-1"></i>

                عرض التفاصيل
              </router-link> -->
            </template>
          </el-table-column>
        </el-table>
      </el-card>

      <el-card
        style="width: 45%"
        class="box-card mt-2 mb-2"
        v-if="expenses.length > 0"
      >
        <div slot="header" class="clearfix">
          <span>المصروفات و المشتريات</span>
        </div>

        <el-table
          class="mt-2"
          align="right"
          v-if="expenses.length > 0"
          :data="expenses"
          border
          sum-text="الاجمالي "
          show-summary
          style="width: 100%"
        >
          <el-table-column label="#" type="index" width="80"> </el-table-column>
          <el-table-column label="البيان " prop="name"> </el-table-column>
          <el-table-column label="القيمة " prop="cost"> </el-table-column>
        </el-table>
      </el-card>

      <el-card
        style="width: 45%"
        class="box-card mr-auto mt-2 mb-2"
        v-if="partenersReport.length > 0"
      >
        <div slot="header" class="clearfix">
          <span>مسحوبات اﻷونر</span>
        </div>

        <el-table
          class="mt-2"
          align="right"
          v-if="partenersReport.length > 0"
          :data="partenersReport"
          border
          sum-text="الاجمالي "
          show-summary
          style="width: 100%"
        >
          <el-table-column label="#" type="index" width="80"> </el-table-column>
          <el-table-column label="الاسم " prop="name"> </el-table-column>
          <el-table-column label="القيمة " prop="cost"> </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  name: "BranchAccount",

  data() {
    return {
    users: [],
    user:null,

      format: "yyyy-MM-dd HH:mm A",
      valueFormat: "yyyy-MM-dd HH:mm:ss",
      dateRange: localStorage.getItem("reportsInterval")
        ? JSON.parse(localStorage.getItem("reportsInterval"))
        : [
            this.$moment(new Date(), "DD-MM-YYYY")
              .locale("en")
              .format("YYYY-MM-DD") +
              " " +
              "11:30:00",
            this.$moment(new Date(), "DD-MM-YYYY")
              .add(1, "days")
              .locale("en")
              .format("YYYY-MM-DD") +
              " " +
              "11:30:00",
          ],

      branch: null,
      branches: [],

      totalAccount: 0,
      totalDeliveryDiscounts: 0,
      totalOfferDiscount: 0,
      salesByCashier: [],
      totalSales: 0,
      totalRestaurantDeliveryCost: 0,
      totalDriverCost: 0,
      notPaiedSales: [],
      totalNotpaiedSales: 0,
      expenses: [],
      totalExpenses: 0,
      partenersReport: [],
      totalPartenersPayment: 0,

      pointsDiscounts: 0,
      promoCodeDiscounts: 0,
    };
  },
  created() {
    this.getOrders();
    this.getBranches();
    this.getUsers();

  },
  methods: {
     getUsers() {
      const loading = this.$loading({
          lock: true,
          text: false,
          spinner: 'el-icon-loading',
          background: 'rgba(255,255,255,.7)',
          customClass: 'fullscreen-loading'
        });
      axiosApi.get(`/users`).then((res) => {
        this.users = res.data;
      }).finally(() => loading.close());
    },
     print(){

         let printers = JSON.parse(localStorage.getItem("printers"));
        //  let dates=localStorage.getItem('reportsInterval')?JSON.parse(localStorage.getItem('reportsInterval')): [((this.$moment(new Date(), "DD-MM-YYYY")).locale("en").format("YYYY-MM-DD") + ' '+'11:30:00'), ((this.$moment(new Date(), "DD-MM-YYYY").add(1,'days')).locale("en").format("YYYY-MM-DD")+ ' '+'11:30:00')],

let totalSales= this.totalSales -
            this.totalNotpaiedSales -this.totalOfferDiscount-
            
            this.totalDriverCost -
            this.totalPartenersPayment;
      let data={
        //"Microsoft Print to PDF"
       "printerName": printers[0].devicePrinter,
        totalExpenses : this.totalExpenses,
        totalSales:totalSales,
        netAmount:totalSales-this.totalExpenses,
        dateRange:this.dateRange[0] + " - " + this.dateRange[1],
 
    InvoiceTimeValue:this.$moment(new Date(), "DD-MM-YYYY").locale("ar").format("dddd | Do / MM / YYYY | h:mm A")
      }
      axiosApi
        .post(
          `http://localhost:16881/api/sales-report`,data
          // `http://localhost:16881/api/PrintInvoicesALL`,model
        )

        .then((response) => {
         console.log('printed')
        })
        .catch((e) => {
          console.log(e);
        });
    },
    clearOrders(){
       const loading = this.$vs.loading();
      axiosApi
        .post("/orders-clear")
        .then(() => {
            this.getOrders();
        })
        .finally(() => loading.close());
    },
    getDiscountPoints(){
      const loading = this.$vs.loading();
      axiosApi
        .get("/orders-point-discounts", {params: {
          start: this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null,
          end: this.dateRange && this.dateRange.length > 0 ? this.dateRange[1] : null,
        }})
        .then((res) => {
          this.pointsDiscounts = res.data?.[0];
        })
        .finally(() => loading.close());
    },
    getDiscountPromocodes(){
      const loading = this.$vs.loading();
      axiosApi
        .get("/orders-promo-discounts", {params: {
          start: this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : null,
          end: this.dateRange && this.dateRange.length > 0 ? this.dateRange[1] : null,
        }})
        .then((res) => {
          this.promoCodeDiscounts = res.data?.[0];
        })
        .finally(() => loading.close());
    },
    getBranches() {
      const loading = this.$vs.loading();
      axiosApi
        .get("branches")
        .then((res) => {
          this.branches = res.data;
        })
        .finally(() => loading.close());
    },
    goTo(path) {
      this.$router.push({ path: path });
    },
    getOrders() {
      this.getSalesByCashier();
      this.getExpenses();
      // this.getSelectedPartenerReport();
    //   this.getDiscountPoints();
    // this.getDiscountPromocodes();
    },

    getSelectedPartenerReport() {
      let url = `parteners-payments?groupBy=true`;

      if (this.branch >0) {
        url += "&branch=" + this.branch;
      }
       if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "&start=" + this.dateRange[0];
        url += "&end=" + this.dateRange[1];
      }
      const vm = this;
      this.totalPartenersPayment = 0;
      axiosApi
        .get(url)
        .then((res) => {
          this.partenersReport = res.data;
          this.partenersReport.map(function (value) {
            vm.totalPartenersPayment += value["cost"];
          });
        })
        .catch((error) => console.log(error))
        .finally(() => {});
    },
    getSalesByCashier() {
      const loading = this.$vs.loading();

      let url = `orders-by-cashier?`;

      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
      if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "start=" + this.dateRange[0] + "&";
        url += "end=" + this.dateRange[1] + "&";
      }
      const vm = this;
      this.totalSales = 0;
      this.totalDriverCost = 0;
      this.totalRestaurantDeliveryCost = 0;
      axiosApi
        .get(url)
        .then((res) => {
          this.salesByCashier = res.data;
          this.salesByCashier.map(function (value) {
            vm.totalSales += value["totalCost"];
            vm.totalDriverCost += value["totalDriverCost"];
            vm.totalRestaurantDeliveryCost += value["totalRestaurantDeliveryCost"];
          });
          this.getNotPaiedSales();
        })
        .finally(() => loading.close());
    },
    getNotPaiedSales() {
      const loading = this.$vs.loading();

      this.totalNotpaiedSales = 0;
      let url = `not-paied-orders-by-type?`;
      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
       if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "start=" + this.dateRange[0] + "&";
        url += "end=" + this.dateRange[1] + "&";
      }
      const vm = this;
      axiosApi
        .get(url)
        .then((res) => {
          this.notPaiedSales = res.data;
          this.notPaiedSales.map(function(value) {
            vm.totalNotpaiedSales += value["totalCost"];
          });

          this.getRejectedOrders();
          this.getOrdersPaiedByVisa();
          this.getOffersDiscounts();
          this.getDeliveryDiscounts();
        })
        .finally(() => loading.close());
    },
    getRejectedOrders() {
      const loading = this.$vs.loading();

      let url = `rejected-orders?`;
      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
      if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "start=" + this.dateRange[0] + "&";
        url += "end=" + this.dateRange[1] + "&";
      }
      const vm = this;
      axiosApi
        .get(url)
        .then((res) => {
          let data = res.data[0];

          data["link"] = "/orders/report?status=4";
          this.notPaiedSales.push(data);
          
            this.totalNotpaiedSales += res.data[0].totalCost;
          this.totalDriverCost -= res.data[0].totalDriverCost;
          this.totalRestaurantDeliveryCost -= res.data[0].totalRestaurantDeliveryCost;
        })
        .finally(() => loading.close());
    },
    getOrdersPaiedByVisa() {
      const loading = this.$vs.loading();

      let url = `orders-paied-by-visa?`;
      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
       if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "start=" + this.dateRange[0] + "&";
        url += "end=" + this.dateRange[1] + "&";
      }
      const vm = this;
      axiosApi
        .get(url)
        .then((res) => {
          let data = res.data[0];

          data["link"] = "/orders/report?payment_type=2";
          this.notPaiedSales.push(data);
         
            this.totalNotpaiedSales += res.data[0].totalCost;

        })
        .finally(() => loading.close());
    },
    getOffersDiscounts() {
      const loading = this.$vs.loading();

      let url = `offers-discounts?`;
      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
      if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "start=" + this.dateRange[0] + "&";
        url += "end=" + this.dateRange[1] + "&";
      }
      const vm = this;
      axiosApi
        .get(url)
        .then((res) => {
         
         
            this.totalOfferDiscount = Number(res.data[0].totalCost);

        })
        .finally(() => loading.close());
    },
    getDeliveryDiscounts() {
      const loading = this.$vs.loading();
this.totalDeliveryDiscounts=0;
      let url = `orders-delivery-discounts?`;
      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
       if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "start=" + this.dateRange[0] + "&";
        url += "end=" + this.dateRange[1] + "&";
      }
      const vm = this;
      axiosApi
        .get(url)
        .then((res) => {
            this.totalDeliveryDiscounts += Number(res.data[0].totalCost);

        })
        .finally(() => loading.close());
    },
    getExpenses() {
      const loading = this.$vs.loading();

      let url = `expenses/category?`;
      if (this.branch >0) {
        url += "branch=" + this.branch + "&";
      }
       if (this.user >0) {
        url += "user=" + this.user + "&";
      }
      if (this.dateRange != null) {
        localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

        url += "start=" + this.dateRange[0] + "&";
        url += "end=" + this.dateRange[1] + "&";
      }

      const vm = this;
      this.totalExpenses = 0;
      axiosApi
        .get(url)
        .then((res) => {
          this.expenses = res.data;
          this.expenses.map(function (value) {
            vm.totalExpenses += value["cost"];
          });
        })
        .finally(() => loading.close());
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-color {
  list-style: none;
}
</style>
