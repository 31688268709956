<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>تحويل الخامات</h2>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex">
      <el-table
        class="mt-2"
        align="right"
        v-if="tableData.length > 0"
        :data="tableData"
        border
        style="width: 100%"
        height="90vh"
      >
        <el-table-column label="#" type="index" width="50" />
        <el-table-column label="التحويل من">
          <template slot-scope="scope">
            {{ `${scope.row.from.name} - ${scope.row.from.address}` }}
          </template>
        </el-table-column>
        <el-table-column label="التحويل الي">
          <template slot-scope="scope">
            {{ `${scope.row.to.name} - ${scope.row.to.address}` }}
          </template>
        </el-table-column>
        <el-table-column label="التوقيت">
          <template slot-scope="scope">
            {{ scope.row.created_at | moment(" D/MM/YYYY | h:mm A") }}
          </template>
        </el-table-column>
        <el-table-column label="بواسطة">
          <template slot-scope="scope">
            {{ scope.row.user.name }}
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-row type="flex" justify="center">
              <el-col :span="20">
                <el-table
                  class="mt-2"
                  align="right"
                  :data="scope.row.materials"
                  border
                  style="width: 100%"
                >
                <el-table-column type="index" label="#" />
                <el-table-column label="الخامه" prop="name"></el-table-column>
                <el-table-column label="الكميه">
                  <template slot-scope="scope">
                    {{ scope.row.pivot.qty }}
                  </template>
                </el-table-column>
              </el-table>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  name: "MaterialMovement",
  data() {
    return {
      tableData: [],
    }
  },
  mounted() {
    this.getMovementData();
  },
  methods: {
    getMovementData() {
      const loading = this.$loading({
        lock: true,
        text: false,
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,.7)",
        customClass: "fullscreen-loading",
      });
      axiosApi.get("inventory-movements").then(res => {
        this.tableData = res.data.data;
        
      }).finally(() => {
        loading.close();
      })
    },
  },
};
</script>
