<template>
    <div class="promo-codes">
        <div class="container-fluid">
            <div>
                <div class="mb-4  mt-4 d-flex justify-content-between align-items-center">
                    <h5 class="promo-codes__title"> أكواد الخصم </h5>
                    <div>
                        <el-button @click="addCode = {} , addCodeModel = true"> إضافة كود </el-button>
                    </div>
                </div>
                <section class="promo-codes__grid">
                    <div class="promo-codes__card" v-for="code in promoCodes" :key="code.id">
                        <table class="promo-codes__table">
                            <tr>
                                <td class="promo-codes__table-title">الكود</td>
                                <td>{{ code.promocode }}</td>
                            </tr>
                            <tr>
                                <td class="promo-codes__table-title">قيمة الخصم</td>
                                <td>{{ code.discount_value }}</td>
                            </tr>
                            <tr>
                                <td class="promo-codes__table-title"> متاح إلي</td>
                                <td>{{ code.valid_to }}</td>
                            </tr>
                            <tr>
                                <td class="promo-codes__table-title">  السماح للاستخدام </td>
                                <td>{{ code.order_limit }}</td>
                            </tr>
                        </table>
                        <div class="d-flex flex-row-reverse">
                            
                            <div>
                                <el-popconfirm
                                @confirm="deleteCode(code)"
                                confirm-button-text="موافق"
                                cancel-button-text="إلغاء"
                                icon="el-icon-info"
                                icon-color="red"
                                title="هل تريد مسح الكود"
                                >
                                <div slot="reference">
                                    <el-button
                                    style="padding: 8px 14px"
                                    type="danger"
                                    icon="el-icon-delete"
                                    title="مسح"
                                    ></el-button>
                                </div>
                                </el-popconfirm>
                            </div>
                            <div>
                                <el-button
                                type="primary"
                                icon="el-icon-edit"
                                title="تعديل"
                                style="padding: 9px 14px"
                                class="p-2 ml-1"
                                @click="openUpdateCode(code)"
                                ></el-button>
                            </div>

                        </div>
                    </div>
                </section>

                <div class="mt-4" dir="ltr" v-if="totalPages > 1">
                    <el-pagination
                    layout="prev, pager, next"
                    :total="totalPages * 10"
                    background
                    @current-change="getPromoCodes"
                    :current-page.sync="page"
                />
                </div>
            </div>
        </div>


        <vs-dialog v-model="updateCodeModel">
            <template #header>
                تعديل الكود
            </template>

            <div>
                <el-form :model="updateCode" ref="updateCodeRef">
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}]" prop="promocode">
                        <template #label> الكود </template>
                        <el-input v-model="updateCode.promocode"></el-input>
                    </el-form-item>
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}, {type: 'number', message: 'يجب ان يكون رقم'}]" prop="discount_value">
                        <template #label> قيمة الخصم </template>
                        <el-input v-model.number="updateCode.discount_value"></el-input>
                    </el-form-item>
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}]" prop="valid_to">
                        <template #label> متاح إلي </template>
                        <el-date-picker
                            style="width: 100%"
                            v-model="updateCode.valid_to"
                            type="date"
                            placeholder="متاح إلي">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}, {type: 'number', message: 'يجب ان يكون رقم'}]" prop="order_limit">
                        <template #label> مرات الاستخدام </template>
                        <el-input v-model.number="updateCode.order_limit"></el-input>
                    </el-form-item>

                    <el-button @click="updatePromoCode()" class="mt-4" style="width: 100%" type="primary" >
                        تعديل
                    </el-button>
                </el-form>
            </div>
        </vs-dialog>





        <vs-dialog v-model="addCodeModel">
            <template #header>
                إضافة الكود
            </template>

            <div>
                <el-form :model="addCode" ref="addCodeRef">
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}]" prop="promocode">
                        <template #label> الكود </template>
                        <el-input v-model="addCode.promocode"></el-input>
                    </el-form-item>
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}, {type: 'number', message: 'يجب ان يكون رقم'}]" prop="discount_value">
                        <template #label> قيمة الخصم </template>
                        <el-input v-model.number="addCode.discount_value"></el-input>
                    </el-form-item>
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}]" prop="valid_to">
                        <template #label> متاح إلي </template>
                        <el-date-picker
                            style="width: 100%"
                            v-model="addCode.valid_to"
                            type="date"
                            placeholder="متاح إلي">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :rules="[{required: true, message: 'هذا الحقل مطلوب'}, {type: 'number', message: 'يجب ان يكون رقم'}]" prop="order_limit">
                        <template #label> مرات الاستخدام </template>
                        <el-input v-model.number="addCode.order_limit"></el-input>
                    </el-form-item>

                    <el-button @click="addPromoCode()" class="mt-4" style="width: 100%" type="primary" >
                        إضافة
                    </el-button>
                </el-form>
            </div>
        </vs-dialog>



    </div>
</template>

<script>
import axiosApi from '@/plugins/axios';
export default {
    data(){
        return {
            promoCodes: [],
            page: 1,
            totalPages: 1,
            updateCodeModel: false,
            updateCode: {},
            addCode: {},
            addCodeModel: false
        }
    },
    created(){
        this.getPromoCodes();
    },
    methods: {
        addPromoCode(){
            this.$refs.addCodeRef.validate(async (valid) => {
                if(valid) {
                    const loading = this.$vs.loading();
                    try {
                        await axiosApi.post(`/promocodes`, {...this.addCode, valid_to: this.$moment(this.addCode.valid_to).format("YYYY-MM-DD")});
                        this.$notify({
                            title: 'Success',
                            message: 'Code added successfully',
                            type: 'success'
                        });
                        this.addCodeModel = false;
                        this.getPromoCodes();
                    } catch(err) {
                        this.$notify.error({
                            title: 'Error',
                            message: 'There are something wrong'
                        });
                    } 
                    finally {
                        loading.close();
                    }
                }
            })
        },
        updatePromoCode(){
            this.$refs.updateCodeRef.validate(async (valid) => {
                if(valid) {
                    const loading = this.$vs.loading();
                    try {
                        await axiosApi.post(`/promocodes/${this.updateCode.id}`, {...this.updateCode, valid_to: this.$moment(this.updateCode.valid_to).format("YYYY-MM-DD")});
                        this.$notify({
                            title: 'Success',
                            message: 'Code Update successfully',
                            type: 'success'
                        });
                        this.updateCodeModel = false;
                        this.getPromoCodes();
                    } catch(err) {
                        this.$notify.error({
                            title: 'Error',
                            message: 'There are something wrong'
                        });
                    } 
                    finally {
                        loading.close();
                    }
                }
            })
        },
        openUpdateCode(code){
            this.updateCodeModel = true;
            this.updateCode = {...code};
        },
        async deleteCode(code) {
            const loading = this.$vs.loading();
            try {
                await axiosApi.post(`/promocodes/${code.id}/delete`);
                this.$notify({
                    title: 'Success',
                    message: 'Code deleted successfully',
                    type: 'success'
                });
                this.getPromoCodes();
            }catch(err) {
                this.$notify.error({
                    title: 'Error',
                    message: 'There are something wrong'
                });
            } finally {
                loading.close()
            }
        },
        async getPromoCodes(){
            const loading = this.$vs.loading();
            try {
                const params = {page: this.page}
                let res = await axiosApi.get(`/promocodes`, {params});
                this.promoCodes = res.data?.data?.collection;
                this.page = res.data?.data?.pageInfo?.currentPage;
                this.totalPages = res.data?.data?.pageInfo?.totalPages;
            } finally {
                loading.close();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.promo-codes {
    &__title {
            margin:  0;
    font-size: 20px;
    color: #FE5634;
    }
    &__table-title{
        color: #FE5634;
    }
    &__table{
        width: 100%;
        tr,td{
            border: none;
        }
    }
    &__grid {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    &__card {
        padding: 10px;
        background: #ffffff;
        color: #333;
        border-radius: 10px;
        box-shadow: 0 0 19px 2px #d7d7d761;
    }
}
</style>