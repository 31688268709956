<template>
  <div>
    <div class="d-flex mt-4" style="gap: 15px">
      <div>
        <el-button :type="active == 1 ? 'primary' : ''" @click="active = 1">
          المنتج
        </el-button>
      </div>
      <div class="mr-2">
        <el-button :type="active == 2 ? 'primary' : ''" @click="active = 2">
          إضافة صورة
        </el-button>
      </div>
      <div class="mr-2">
        <el-button :type="active == 3 ? 'primary' : ''" @click="active = 3">
          الزيادات و البدون
        </el-button>
      </div>
      <div class="mr-2">
        <el-button :type="active == 4 ? 'primary' : ''" @click="active = 4">
          كارت الصنف
        </el-button>
      </div>
    </div>

    <div class="products-add mb-4">
      <div class="add" v-if="active == 1">
        <div>
          <div class="d-flex">
            <div class="form-group">
              <label for="">نوع المنتج</label>
              <el-select
                v-model="addProduct.type"
                placeholder="نوع المنتج"
                value-key="value"
              >
                <el-option
                  v-for="item in productTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="form-group">
              <label>إسم المنتج</label>
              <el-input class="product-name" v-model="addProduct.name" />
            </div>
          </div>

          <div class="mr-2">
            <label
              style="
                text-align: right;
                color: #fe5634;
                display: block;
                font-size: 15px;
              "
              class="text-right"
              for=""
              >وصف المنتج</label
            >
            <el-input
              :rows="4"
              v-model="addProduct.description"
              type="textarea"
            ></el-input>
          </div>
        </div>

        <div>
          <div
            v-for="(price, index) in prices"
            :key="index"
            class="prices mb-2"
          >
            <div class="form-group">
              <label for="">الحجم</label>
              <el-input v-model="price.name" :disabled="true"> </el-input>
            </div>
            <div class="form-group">
              <label for="">السعر</label>
              <el-input  @focus="$event.target.select()" v-model="price.price.price"> </el-input>
            </div>
             <div class="form-group">
            <label for="">سعر الصاله</label>
            <el-input  @focus="$event.target.select()" v-model="price.price.sala_price"> </el-input>
          </div>
            <!-- <div class="form-group">
               <label for=""> السعر البديل</label>
              <el-input v-model="price.price.pending_price"> </el-input>
            </div>  -->

            <!-- <el-button
            class="remove-price"
            @click="product.prices.splice(index, 1)"
            v-if="index != 0"
            type="danger"
            icon="el-icon-delete"
          ></el-button> -->
          </div>
        </div>

        <div class="d-flex" style="gap: 15px">
          <vs-button @click="sendProductToBackend" flat class="save mr-2 ml-2"
            >تعديل</vs-button
          >
          <vs-button @click="closeUpdate" flat class="save mr-2 ml-2" danger
            >إلغاء</vs-button
          >
        </div>
      </div>

      <div v-if="active == 2">
        <h4>إضافة صورة للمنتج</h4>

        <el-upload
          class="avatar-uploader"
          list-type="picture-card"
          action="#"
          :show-file-list="true"
          :auto-upload="false"
          :on-change="handlePictureCardPreview"
          :on-remove="handleRemove"
          ref="addPhoto"
          :file-list="fileList"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>

        <div class="mt-4 d-flex">
          <vs-button primary="primary" flat @click="saveImage"
            >حفظ صور المنتج</vs-button
          >
          <vs-button
            @click="closeUpdate"
            flat
            class="save mr-1 ml-1 mt-1"
            danger
            >إلغاء</vs-button
          >
        </div>
      </div>

      <div style="width: 100%" v-if="active == 3">
        <div class="row">
          <div class="col-md-12">
            <section>
              <div>
                <h6 class="text-right">الإضافات</h6>
                <el-select
                  multiple
                  @change="removeFromWithout"
                  v-model="addProduct.additions"
                  placeholder="الإضافات"
                >
                  <el-option
                    v-for="item in additions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div>
                <h6 class="text-right">بدون</h6>
                <el-select
                  multiple
                  @change="removeFromAdditions"
                  v-model="addProduct.withouts"
                  placeholder="بدون"
                  value-key="id"
                >
                  <el-option
                    v-for="(item, index) in without"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </section>

            <div class="d-flex">
              <vs-button flat @click="saveAdditionsWithouts()">حفظ</vs-button>
              <vs-button
                @click="closeUpdate"
                flat
                class="save mt-1 mr-1 ml-1"
                danger
                >إلغاء</vs-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="active == 4" class="w-100 mt-3">
        <el-row type="flex" :gutter="15">
          <el-col :span="24">
            <el-row type="flex" :gutter="15">
              <el-col :span="8">
                <div class="form-group">
                  <label for="">حجم المنتج</label>
                  <el-select
                    v-model="productPriceId"
                    placeholder="حجم المنتج"
                    value-key="value"
                    :filterable="true"
                    :allow-create="true"
                    @change="handleSelectPrice"
                  >
                    <el-option
                      v-for="price in prices"
                      :key="price.price.id"
                      :label="price.name"
                      :value="price.price.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="form-group">
                  <label for="">اسم الخامة</label>
                  <el-select
                    v-model="materialName"
                    placeholder="اسم الخامة"
                    value-key="value"
                    :filterable="true"
                    @change="handleChangeMaterial"
                    :remote="true"
                    :remote-method="getMaterialList"
                    ref="materialName"
                  >
                    <el-option
                      v-for="item in materialList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="form-group">
                  <label>الكمية</label>
                  <el-input
                    class="product-name"
                    type="number"
                    v-model="materialQty"
                    @keypress.native.enter="addMaterialToList"
                  />
                </div>
              </el-col>
              <el-col :span="4">
                <div class="d-flex">
                  <vs-button flat @click="saveProductMaterials()"
                    >حفظ</vs-button
                  >
                  <vs-button
                    @click="closeUpdate"
                    flat
                    class="save mt-1 mr-1 ml-1"
                    danger
                    >إلغاء</vs-button
                  >
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="18">
            <el-table
              class="mt-2"
              align="right"
              :data="selectedPrice?.materials"
              border
              style="width: 100%"
            >
              <el-table-column label="#" type="index" width="50" />
              <el-table-column prop="name" label="اسم الخامة">
              </el-table-column>
              <el-table-column prop="qty" label="الكمية" width="150">
                <template slot-scope="scope">
                  <input
                    v-model="scope.row.qty"
                    type="number"
                    class="form-control"
                  />
                  <!-- <el-input type="number" @change="$forceUpdate()" v-model="scope.row.qty" /> -->
                </template>
              </el-table-column>
              <el-table-column label="اجراءات" width="100">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="() => deleteMaterial(scope.$index)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      title="تسجيل خامة جديد"
      :visible.sync="addProductFormVisible"
      v-if="productForm"
    >
      <el-form :model="productForm" :rules="rules" ref="form">
        <el-row type="flex" style="flex-wrap: wrap" :gutter="10">
          <el-col :xl="12" :lg="12" :md="6" :sm="12">
            <el-form-item label="الاسم " prop="name">
              <el-input
                class="formInput1"
                autofocus
                v-model="productForm.name"
                autocomplete="off"
                @keyup.enter.native="focusNext"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="التصنيف" prop="material_category_id">
              <el-select v-model="productForm.material_category_id">
                <el-option
                  v-for="category in materialCategories"
                  :key="category.id"
                  :value="category.id"
                  :label="category.name"
                  >{{ category.name }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="النوع" prop="type">
              <el-select v-model="productForm.type">
                <el-option value="1" label="تامه">تامه</el-option>
                <el-option value="2" label="مصنعه">مصنعه</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="handleAddNewProduct"
          >تسجيل خامة
        </el-button>
        <el-button class="mr-3" @click="addProductFormVisible = false"
          >إلغاء</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  props: ["selectedCategory", "product", "sizes"],
  created() {
    this.getMaterialCategories();
    this.addProduct = this.product;

    this.mySizes = [...this.sizes];

    if (this.mySizes.length >= 0) {
      this.mySizes.forEach((size) => {
        this.prices.push({
          size_id: size.id,
          name: size.name,
          price:
            this.product.prices[
              this.product.prices.findIndex(
                (ele) => ele.product_size_id == size.id
              )
            ] || {},
        });
      });
    }

    if (this.product.images.length > 0) {
      this.product.images.map((ele) => {
        this.fileList.push({ url: ele.name });
      });
    }

    this.getAdditionsByCategorie(this.selectedCategory);

    console.log("my sizes ", this.prices);

    this.product.additions = this.product.additions.map((ele2) => {
      ele2.id = ele2.child_id;
      ele2.name = ele2.child.name;
      return ele2;
    });

    this.product.withouts = this.product.withouts.map((ele2) => {
      ele2.id = ele2.child_id;
      ele2.name = ele2.child.name;
      return ele2;
    });

    this.addProduct.additions = this.addProduct.additions.map((ele) => {
      if (ele.id > 0) {
        ele = ele.id;
      }
      return ele;
    });

    this.addProduct.withouts = this.addProduct.withouts.map((ele) => {
      if (ele.id > 0) {
        ele = ele.id;
      }
      return ele;
    });
  },
  data() {
    return {
      fileList: [],
      active: 1,
      additions: [],
      without: [],
      mySizes: [],
      prices: [],
      productTypes: [
        { label: "أساسي", value: 1 },
        { label: "إضافي", value: 2 },
      ],
      addProduct: {
        additions: [],
        without: [],
      },
      toggleAddProduct: false,
      products: [],
      mainWithout: [],
      imageUrl: "",
      categoryIcon: "",
      dialogImageUrl: "",
      dialogVisible: false,
      materialName: "",
      materialQty: "",
      materialList: [],
      selectedMaterial: null,
      addProductFormVisible: false,
      productForm: {
        name: "",
        material_category_id: "",
        type: "",
      },
      rules: {
        name: [{ required: true, message: "الاسم مطلوب", trigger: "blur" }],
        material_category_id: [
          { required: true, message: "التصنيف مطلوب", trigger: "blur" },
        ],
        type: [{ required: true, message: "النوع مطلوب", trigger: "blur" }],
      },
      materialCategories: [],
      productPriceId: "",
      selectedPrice: {},
    };
  },

  methods: {
    handleSelectPrice(e) {
      this.selectedPrice = this.product.prices.filter((price) => {
        return +price.id === +e;
      })[0];
      this.selectedPrice.materials.map((material) => {
        material.qty = material.pivot.qty;
      });
    },
    getMaterialList(e) {
      if (e != "") {
        axiosApi
          .get("materials/search", { params: { name: e, inventory_id: 1 } })
          .then((res) => {
            this.materialList = res.data;
          });
      } else {
        this.materialList = [];
      }
    },
    getMaterialCategories() {
      axiosApi.get("material_categories").then((res) => {
        this.materialCategories = res.data;
      });
    },
    handleChangeMaterial(e) {
      if (typeof e == "string" && e !== "") {
        this.addProductFormVisible = true;
        this.productForm.name = e;
      } else {
        this.selectedMaterial = this.materialList.filter(
          (material) => material.id == this.materialName
        )[0];
      }
    },
    addMaterialToList() {
      const materialIndex = this.selectedPrice?.materials.findIndex(
        (material) => material.id == this.materialName
      );
      if (materialIndex === -1) {
        this.selectedPrice?.materials.push({
          name: this.selectedMaterial.name,
          id: this.selectedMaterial.id,
          qty: this.materialQty,
        });
      } else {
        const qty =
          +this.selectedPrice?.materials[materialIndex].qty + +this.materialQty;
        this.selectedPrice?.materials.splice(materialIndex, 1, {
          ...this.selectedPrice?.materials[materialIndex],
          qty,
        });
      }
      this.materialName = "";
      this.materialQty = "";
      this.$refs.materialName.focus();
    },
    deleteMaterial(index) {
      this.selectedPrice?.materials.splice(index, 1);
    },
    saveProductMaterials() {
      const materials = this.selectedPrice?.materials.map((material) => {
        return {
          material_id: material.id,
          qty: material.qty,
        };
      });
      const loading = this.$vs.loading();
      axiosApi
        .post("product-materials", {
          product_price_id: this.productPriceId,
          product_id: this.product.id,
          materials,
        })
        .then((res) => {
          this.$notify({
            title: "تم",
            message: `تم الحفظ بنجاح `,
            type: "success",
          });
          this.selectedPrice = res.data.prices.filter((price) => {
            return +price.id === +this.productPriceId;
          })[0];

          this.selectedPrice.materials.map((material) => {
            material.qty = material.pivot.qty;
          });

          // this.$emit("changeOperationStatus", "add");
          this.$emit("successProductUpdated", res.data);
        })
        .finally(() => loading.close())
        .catch((error) => {
          if (error.response.status == 422) {
            this.$notify.error({
              title: "خطأ!",
              message: "حدث خطأ ما",
              type: "danger",
            });
          }
        });
    },
    saveAdditionsWithouts() {
      this.addProduct.additions = this.addProduct.additions.map((ele) => {
        if (ele.id > 0) {
          ele = ele.id;
        }
        return ele;
      });

      this.addProduct.withouts = this.addProduct.withouts.map((ele) => {
        if (ele.id > 0) {
          ele = ele.id;
        }
        return ele;
      });

      const loading = this.$vs.loading();
      axiosApi
        .post(`/products/additions`, {
          product_id: this.product.id,
          additions: this.addProduct.additions,
          withouts: this.addProduct.withouts,
        })
        .then(() => {
          this.$notify({
            title: "تم",
            message: `تم الحفظ بنجاح `,
            type: "success",
          });

          this.$emit("changeOperationStatus", "add");
          this.$emit("successProductUpdated", "add");
        })
        .finally(() => loading.close())
        .catch((error) => {
          if (error.response.status == 422) {
            this.$notify.error({
              title: "خطأ!",
              message: "حدث خطأ ما",
              type: "danger",
            });
          }
        });
    },
    saveImage() {
      let formData = new FormData();

      formData.append("name", this.addProduct.name);
      formData.append("type", this.addProduct.type);
      formData.append("_method", "put");
      formData.append("product_category_id", this.selectedCategory.id);

      this.fileList.map((ele, index) => {
        if (ele.status == "success") {
          formData.append(`images[${index}]`, ele.url);
        } else if (ele.status == "ready") {
          formData.append(`images[${index}]`, ele.raw);
        }
      });
      let loading = this.$vs.loading();

      axiosApi
        .post(`/products/${this.product.id}`, formData)
        .then(() => {
          this.$notify({
            title: "تم",
            message: `تم حفظ الصورة بنجاح `,
            type: "success",
          });
          // this.$refs.addPhoto.clearFiles();
          this.$emit("changeOperationStatus", "add");
          this.$emit("successProductUpdated", "add");
        })
        .finally(() => loading.close());
    },

    handlePictureCardPreview(file, fileList) {
      this.fileList = fileList;
      console.log(fileList);

      // this.imageUrl = "";
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
      // this.categoryIcon = "";
    },

    removeFromWithout(e) {
      this.without = [...this.mainWithout];

      e.map((eleId) => {
        this.without.map((ele, index) => {
          if (ele.id == eleId) {
            this.without.splice(index, 1);
          }
        });
      });
    },

    removeFromAdditions(e) {
      this.additions = [...this.mainWithout];
      console.log(e);
      e.map((eleId) => {
        this.additions.map((ele, index) => {
          if (ele.id == eleId) {
            this.additions.splice(index, 1);
          }
        });
      });
    },
    closeUpdate() {
      this.$emit("changeOperationStatus", "edit");
    },
    sendProductToBackend() {
      if (this.addProduct && !this.addProduct.name) {
        this.$notify.error({
          title: "خطأ!",
          message: "اسم المنتج مطلوب",
          type: "danger",
        });
        return;
      }

      let myPrices = [];
      this.prices.forEach((ele) => {
        if (
          Object.keys(ele.price).length > 0 &&
          ele.price.price.toString().trim() != ""
        ) {
          // if(ele.price.pending_price.toString().trim() != ""){
          // myPrices.push({ size_id: ele.size_id,pending_price:ele.price.pending_price, price: ele.price.price });

          // }else{
          myPrices.push({ size_id: ele.size_id,sala_price:ele.price.sala_price, price: ele.price.price });

          // }
        }
      });

      if (myPrices.length == 0) {
        this.$notify.error({
          title: "خطأ!",
          message: "لا يوجد اسعار للمنتج اضف سعر علي الاقل",
          type: "danger",
        });
        return;
      }

      if (myPrices.findIndex((ele) => isNaN(Number(ele.price))) > -1) {
        this.$notify.error({
          title: "خطأ!",
          message: "قيمة السعر غير صالحة",
          type: "danger",
        });
        return;
      }

      const loading = this.$vs.loading();
      axiosApi
        .put(`/products/${this.product.id}`, {
          name: this.addProduct.name,
          description: this.addProduct.description,
          type: this.addProduct.type,
          product_category_id: this.selectedCategory.id,
          prices: myPrices,
          // additions: this.addProduct.additions,
          // withouts: this.addProduct.withouts,
        })
        .then(() => {
          this.$notify({
            title: "تم",
            message: `تم تعديل المنتج بنجاح `,
            type: "success",
          });

          this.prices = [
            {
              size_id: "",
              price: "",
            },
          ];
          this.prices[0].size_id = this.sizes[0].id;
          this.prices[0].size = this.sizes[0];

          this.$emit("changeOperationStatus", "add");
          this.$emit("successProductUpdated", "add");

          this.addProduct = {};
        })
        .finally(() => loading.close())
        .catch((error) => {
          if (error.response.status == 422) {
            this.$notify.error({
              title: "خطأ!",
              message: "هذا الإسم موجود مسبقاٌ",
              type: "danger",
            });
          }
        });
    },
    getAllSizes() {
      const loading = this.$vs.loading();
      axiosApi
        .get("product-sizes")
        .then((res) => {
          this.sizes = [...res.data];
          this.mySizes = [...this.sizes];
          // this.prices[0].size_id = this.sizes[0].id;
          // this.prices[0].size = this.sizes[0];

          // console.log("sizes ", this.sizes)
          // console.log("prices ", this.product.prices)
          // console.log("prices ", this.product)

          this.mySizes.forEach((size) => {
            this.prices.push({
              size_id: size.id,
              name: size.name,
              price:
                this.product.prices[
                  this.product.prices.findIndex(
                    (ele) => ele.product_size_id == size.id
                  )
                ] || {},
            });
          });

          console.log("my sizes ", this.prices);

          // while(this.sizes.length > this.prices.length){
          //     this.pushNewPrice()
          // }
        })
        .finally(() => loading.close());
    },
    pushNewPrice() {
      let sizesNextIndex = this.product.prices.length;
      this.product.prices.push({
        product_size: this.sizes[sizesNextIndex],
        size: this.sizes[sizesNextIndex],
      });
    },

    getAdditionsByCategorie(category) {
      let loading = this.$vs.loading();
      axiosApi
        .get(`/product-categories/${category.id}/additions`)
        .then((res) => {
          this.additions = [...res.data];
          this.without = [...this.additions];
          this.mainWithout = [...res.data];

          let withouts = this.addProduct.withouts.map((ele) => {
            if (ele.id > 0) {
              ele = ele.id;
            }
            return ele;
          });

          let additions = this.addProduct.additions.map((ele) => {
            if (ele.id > 0) {
              ele = ele.id;
            }
            return ele;
          });

          this.removeFromAdditions(withouts);
          this.removeFromWithout(additions);
        })
        .finally(() => loading.close());
    },
    handleAddNewProduct() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: false,
            spinner: "el-icon-loading",
            background: "rgba(255,255,255,.7)",
            customClass: "fullscreen-loading",
          });
          axiosApi
            .post("materials", this.productForm)
            .then((res) => {
              this.$notify({
                title: "تمت العملية بنجاح",
                message: "تم حفظ بيانات العميل بنجاح",
                type: "success",
                duration: 1500,
              });
              this.addProductFormVisible = false;
              this.materialList.push(res.data);
              this.selectedMaterial = res.data;
              this.materialForm.material_id = res.data.id;
            })
            .finally(() => {
              loading.close();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.products-add {
  display: flex;
  flex-wrap: wrap;

  section {
    margin-bottom: 15px;
  }

  .el-select__tags-text {
    padding: 0 8px;
  }

  .el-select__tags {
    margin: 0 10px;
  }

  .text-right {
    text-align: right;
    color: #fe5634;
  }

  .add {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    padding-top: 14px;

    .prices {
      display: flex;
      flex-wrap: wrap;
    }

    .form-group {
      display: flex;
      flex-wrap: wrap;

      label {
        margin: 0 11px;
        color: #fe5634;
      }

      .el-input {
        width: 95px;
        height: 30px;
      }

      .product-name {
        width: 200px;
      }

      .el-input__inner {
        height: 30px !important;
      }
    }

    .add-new-price {
      padding: 2px;
      margin: 4px 6px;
      height: 24px;
      background: #f7f7f7;
      font-weight: bold;
      color: var(--primary);
      font-size: 17px;
    }

    .remove-price {
      padding: 2px;
      margin: 4px 6px;
      height: 24px;
    }
  }

  .save {
    height: 30px;
    border-radius: 8px;
    margin: 0 10px;
  }
}
</style>
