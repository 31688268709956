<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>تقرير مرتجع المشتريات</h2>
        </el-col>
        <el-col :lg="18" :md="17">
          <el-row :gutter="15" type="flex">
            <el-col :lg="6" :md="6">
              <el-input
                @change="filterReport"
                placeholder="بحث ب رقم الفاتورة"
                type="text"
                @focus="$event.target.select()"
                v-model="searchId"
              />
            </el-col>
            <el-col :lg="6" :md="6">
              <el-select
                class="usersname"
                v-model="searchSupplierId"
                filterable
                clearable
                placeholder="بحث باسم المورد"
                value-key="id"
                @change="filterReport"
              >
                <el-option
                  v-for="x in suppliers"
                  :key="x.id"
                  :label="x.name"
                  :value="x.id"
                >
                  {{ x.name }}
                </el-option>
              </el-select>
            </el-col>
            <el-col :lg="6" :md="6">
              <el-select
                class="usersname"
                v-model="searchUserId"
                filterable
                clearable
                placeholder="بحث باسم المستخدم"
                value-key="id"
                @change="filterReport"
              >
                <el-option
                  v-for="x in users"
                  :key="x.id"
                  :label="x.name"
                  :value="x.id"
                >
                  {{ x.name }}
                </el-option>
              </el-select>
            </el-col>
            <el-col :lg="6" :md="6">
              <el-date-picker
                v-model="dateRange"
                @change="filterReport"
                type="daterange"
                range-separator="الي"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
                start-placeholder="بداية الفترة"
                end-placeholder="نهاية الفترة"
                style="width: 100%"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex">
      <el-table
        class="mt-2"
        align="right"
        v-if="tableData.length > 0"
        :data="tableData"
        border
        style="width: 100%"
        height="90vh"
        v-el-table-infinite-scroll="loadMoreData"
        :infinite-scroll-disabled="disabled"
      >
        <el-table-column label="#" type="index" width="50" />
        <el-table-column label="رقم الفاتورة" prop="id" width="100" />
        <el-table-column label="المورد">
          <template slot-scope="scope">
            {{ scope.row.supplier.name }}
          </template>
        </el-table-column>
        <el-table-column label="القيمة" prop="total">
          <template slot-scope="scope">
            {{ Number(scope.row.total).toLocaleString() }} ج.م
          </template>
        </el-table-column>
        <el-table-column label="الخصم" prop="discount">
          <template slot-scope="scope">
            {{ Number(scope.row.discount).toLocaleString() }} ج.م
          </template>
        </el-table-column>
        <el-table-column label="الاجمالي">
          <template slot-scope="scope">
            {{
              Number(scope.row.total - scope.row.discount).toLocaleString()
            }}
            ج.م
          </template>
        </el-table-column>
        <el-table-column label="بواسطة">
          <template slot-scope="scope">
            {{ scope.row.user.name }}
          </template>
        </el-table-column>
        <el-table-column label="التوقيت">
          <template slot-scope="scope">
            {{ scope.row.created_at | moment(" D/MM/YYYY | h:mm A") }}
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row type="flex">
              <el-table
                class="mt-2"
                align="right"
                :data="props.row.materials"
                border
                style="width: 100%"
              >
                <el-table-column label="#" type="index" width="50" />
                <el-table-column label="الخامات" prop="name" />
                <el-table-column label="الكمية">
                  <template slot-scope="scope">
                    {{ scope.row.pivot.qty }}
                  </template>
                </el-table-column>
                <el-table-column label="السعر">
                  <template slot-scope="scope">
                    {{ scope.row.pivot.buy_cost }} ج.م
                  </template>
                </el-table-column>
                <el-table-column label="الاجمالي">
                  <template slot-scope="scope">
                    {{ scope.row.pivot.qty * scope.row.pivot.buy_cost }} ج.م
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";

export default {
  name: "ReturnReport",
  components: {},
  data: () => {
    return {
      searchId: "",
      searchSupplierId: "",
      searchUserId: "",
      users: null,
      suppliers: null,
      dateRange: null,
      tableData: [],
      startFilter: false,
      pagination: {
        current_page: 1,
        last_page: 1,
      },
    };
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  mounted() {
    this.getPurchasesReport();
    this.getUsers();
    this.getSuppliers();
  },
  methods: {
    filterReport() {
      this.startFilter = true;
      this.getPurchasesReport();
    },
    getUsers() {
      axiosApi.get(`users`).then((res) => {
        this.users = res.data;
      });
    },
    getSuppliers() {
      axiosApi.get("suppliers").then((res) => {
        this.suppliers = res.data;
      });
    },
    getPurchasesReport() {
      const loading = this.$loading({
        lock: true,
        text: false,
        spinner: "el-icon-loading",
        background: "rgba(255,255,255,.7)",
        customClass: "fullscreen-loading",
      });

      let params = {};

      if (this.searchId) {
        params = {
          ...params,
          id: this.searchId,
        };
      }
      if (this.searchSupplierId) {
        params = {
          ...params,
          supplier_id: this.searchSupplierId,
        };
      }
      if (this.searchUserId) {
        params = {
          ...params,
          user_id: this.searchUserId,
        };
      }
      if (this.dateRange) {
        params = {
          ...params,
          start: this.dateRange[0],
          end: this.dateRange[1],
        };
      }

      if (this.startFilter) {
        this.tableData = [];
      }

      axiosApi
        .get("return-purchases", { params })
        .then((res) => {
          this.pagination.current_page = res.data.current_page;
          this.pagination.last_page = res.data.last_page;
          if (this.tableData.length > 0) {
            this.tableData = [...this.tableData, ...res.data.data];
          } else {
            this.tableData = res.data.data;
          }
        })
        .finally(() => {
          loading.close();
          this.startFilter = false;
        });
    },
    loadMoreData() {
      if (this.pagination.last_page > this.pagination.current_page) {
        this.pagination.current_page++;
        this.getOrders();
      }
    },
  },
};
</script>
