<template>
  <el-tabs tab-position="right" v-model="currTabName" @tab-click="handleChange">
    <el-tab-pane label="فاتورة شراء" name="NewPurchases">
      <NewPurchases v-if="currTabName === 'NewPurchases'" />
    </el-tab-pane>
    <el-tab-pane label="تقرير المشتريات" name="PurchasesReport">
      <PurchasesReport v-if="currTabName === 'PurchasesReport'" />
    </el-tab-pane>
    <el-tab-pane label="فاتورة مرتجع" name="PurchasesReturn">
      <PurchasesReturn v-if="currTabName === 'PurchasesReturn'" />
    </el-tab-pane>
    <el-tab-pane label="تقرير مرتجع المشتريات" name="ReturnReport">
      <ReturnReport v-if="currTabName === 'ReturnReport'" />
    </el-tab-pane>
    <el-tab-pane label="كارت التشغيله" name="MaterialIngredients">
      <MaterialIngredients v-if="currTabName === 'MaterialIngredients'" />
    </el-tab-pane>
    <el-tab-pane label="تشغيله جديدة" name="Operations">
      <Operations v-if="currTabName === 'Operations'" />
    </el-tab-pane>
    <el-tab-pane label="تقرير التشغيل" name="OperationsReport">
      <OperationsReport v-if="currTabName === 'OperationsReport'" />
    </el-tab-pane>
    <el-tab-pane label="تحويل الخامات" name="MaterialMovement">
      <MaterialMovement v-if="currTabName === 'MaterialMovement'" />
    </el-tab-pane>
    <el-tab-pane label="تقرير التحويل" name="MovementReport">
      <MovementReport v-if="currTabName === 'MovementReport'" />
    </el-tab-pane>
    <el-tab-pane label="الموردين" name="Suppliers">
      <Suppliers v-if="currTabName === 'Suppliers'" />
    </el-tab-pane>
    <el-tab-pane label="سداد" name="SuppliersPay">
      <SuppliersPay v-if="currTabName === 'SuppliersPay'" />
    </el-tab-pane>
    <el-tab-pane label="جرد" name="Gard">
      <Gard v-if="currTabName === 'Gard'" />
    </el-tab-pane>
    <el-tab-pane label="تصنيف الخامات" name="Categories">
      <Categories v-if="currTabName === 'Categories'" />
    </el-tab-pane>
    <el-tab-pane label="حركه الخامات" name="MaterialReport">
      <MaterialReport v-if="currTabName === 'MaterialReport'" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import NewPurchases from "./New.vue";
import PurchasesReport from "./Report.vue";
import PurchasesReturn from "./Return.vue";
import ReturnReport from "./ReturnReport.vue";
import MaterialIngredients from "./MaterialIngredients.vue";
import Operations from "./Operations.vue";
import OperationsReport from "./OperationsReport.vue";
import MaterialMovement from "./MaterialMovement.vue";
import MovementReport from "./MaterialMovementReport.vue";
import Suppliers from "./SuppliersList.vue";
import SuppliersPay from "./SuppliersPay.vue";
import Gard from "./Gard.vue";
import Categories from "./Categories.vue";
import MaterialReport from "./MaterialReport.vue";

export default {
  components: {
    NewPurchases,
    PurchasesReport,
    PurchasesReturn,
    ReturnReport,
    MaterialIngredients,
    Operations,
    OperationsReport,
    MaterialMovement,
    MovementReport,
    Suppliers,
    SuppliersPay,
    Gard,
    Categories,
    MaterialReport,
  },
  data() {
    return {
      currTabName: this.$route.query.tab ?? "NewPurchases",
    };
  },
  methods: {
    handleChange(tabName) {
      this.$router.push({to: '/purchases', query: {tab: tabName.name}})
    },
  },
};
</script>
