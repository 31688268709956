<template>
  <div class="users-comments">
    <div class="container-fluid">
        <h5 class="users-comments__title">تقييمات العملاء</h5>

        
        <div class="d-flex ">
          <div class="ml-3">
            <el-date-picker
              @change="getOrdersRats"
              :format="format"
              :value-format="valueFormat"
              v-model="dateRange"
              type="datetimerange"
              range-separator="إلي"
              start-placeholder="بداية الفترة"
              end-placeholder="نهاية الفترة"
            >
            </el-date-picker>
          </div>
          <div>
            <el-input @keydown.enter.native="getOrdersRats" placeholder="رقم الطلب" v-model="order_id"></el-input>
          </div>
        </div>
    <el-table
        class="mt-2"
        align="right"
        v-if="comments.length > 0"
        :data="comments"
        border
        style="width: 100%"
    >
        <el-table-column label="#" type="index" width="50"> </el-table-column>
        <el-table-column sortable width="100" label=" رقم " prop="order.id"> </el-table-column>
        <el-table-column  width="100" label="القيمة" prop="order.total"> </el-table-column>
        <el-table-column  width="100"  label="الحالة"> 
           <template slot-scope="scope">
             <el-tag v-if="scope.row.order.status==1">في المطبخ</el-tag>
             <el-tag type="warning" v-if="scope.row.order.status==2">في الطريق</el-tag>
             <el-tag type="success" v-if="scope.row.order.status==3">تم التوصيل</el-tag>
             <el-tag type="danger" v-if="scope.row.order.status==4">مرفوض</el-tag>
           </template>
        </el-table-column>

        <el-table-column label= "اﻟﻌﻤﻴﻞ" prop="user.name"> </el-table-column>
        <el-table-column label="الفون" prop="user.phone"> </el-table-column>
        <el-table-column width="200" sortable label="التوقيت">
            <template slot-scope="scope">>            
              <!-- <span>{{scope.row.order.created_at | moment("dddd | Do / MM / YYYY | h:mm A")}}</span> -->
              <span> {{ $moment(scope.row.order.created_at).utc(false).format('lll') }} </span>
            </template>
        </el-table-column>  
        <el-table-column label="الكومنت" prop="comment"> </el-table-column>

        <el-table-column type="expand">
                  <template slot-scope="props">
                    <div class="row">
                      <div class="col-8">
                        <el-table class="mt-2" align="right" :data="props.row.order.products" border style="width: 100%">
                          <el-table-column label="#" type="index" width="50">
                          </el-table-column>
                          <el-table-column sortable width="100" label="الكمية " prop="pivot.qty">
                          </el-table-column>
                          <el-table-column label="الصنف " prop="name"></el-table-column>
                          <el-table-column width="100" label="المقاس " prop="pivot.size"></el-table-column>
                          <el-table-column width="100" label="السعر " prop="pivot.price"></el-table-column>
                          <el-table-column width="100" label="الاجمالي">
                            <template slot-scope="scope">
                              <span>{{
                                  scope.row.pivot.qty * scope.row.pivot.price
                              }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <div class="col-4">
                        <div class="row pr-2 mt-2" style="text-align: start">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">اسم العميل</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.user.name }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">رقم العميل</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.user.phone }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">العنوان</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">{{ props.row.order.address }}</p>
                          </div>
                        </div>



                        <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">سعر السائق </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.order.driver_cost }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">سعر هيفن </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.order.restaurant_cost }}
                        </p>
                      </div>
                    </div>
                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">خصم الدليفري </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ props.row.order.delivery_discount }}
                        </p>
                      </div>
                    </div>

                    <div class="row pr-2" style="text-align: start">
                      <div class="col-4" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0">إجمالي الدليفري </p>
                      </div>
                      <div class="col-8" style="border: 1px solid #ebeef5">
                        <p style="margin: 10px 0px">
                          {{ (props.row.order.driver_cost + props.row.order.restaurant_cost ) - props.row.order.delivery_discount }}
                        </p>
                      </div>
                    </div>

                    



                        <div class="row pr-2" style="text-align: start" v-if="props.row.order.notes">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">* ﻣﻼﺣﻈﺎﺕ</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.notes }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="props.row.order.rejected_reason">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">* ﻣﻼﺣﻈﺎﺕ</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.order.rejected_reason }}
                            </p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="
                          props.row.order.payment_type && props.row.order.payment_type == 1
                        ">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">نوع الدفع</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">نقدي</p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="
                          props.row.order.payment_type && props.row.order.payment_type == 2
                        ">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">نوع الدفع</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">فيزا</p>
                          </div>
                        </div>
                        <div class="row pr-2" style="text-align: start" v-if="props.row.order.preparated_at">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">تاريخ الاستلام</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{
                                  new Date(props.row.order.preparated_at).toLocaleString(
                                    "ar-EG"
                                  )
                              }}
                            </p>
                          </div>
                        </div>


                        <div class="row pr-2" style="text-align: start" v-if="props.row.order.point_discount">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">خصم النقاط</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p> {{ props.row.order.point_discount }} </p>
                          </div>
                        </div>

                        <div class="row pr-2" style="text-align: start" v-if="props.row.order.promocode_discount">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">خصم البرومو كود</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p> {{ props.row.order.promocode_discount }} </p>
                          </div>
                        </div>

                        <div class="row pr-2" style="text-align: start" v-if="props.row.order.rejected_reason">
                          <div class="col-4" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0">سبب الرفض</p>
                          </div>
                          <div class="col-8" style="border: 1px solid #ebeef5">
                            <p style="margin: 10px 0px">
                              {{ props.row.order.rejected_reason }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

    </el-table>

    <div class="mt-4" dir="ltr">
        <el-pagination
        v-if="totalPages > 1"
        layout="prev, pager, next"
        :total="totalPages * 10"
        background
        @current-change="getOrdersRats"
        :current-page.sync="page"
    />
    </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios.js";
export default {
  created() {
    this.getOrdersRats();
  },
  data() {
    return {
      comments: [],
      page: 1,
      totalPages: 1,

      format: "yyyy-MM-dd HH:mm A",
      valueFormat: "yyyy-MM-dd HH:mm:ss",
      order_id: '',
      dateRange: localStorage.getItem("reportsInterval")
        ? JSON.parse(localStorage.getItem("reportsInterval"))
        : [
            this.$moment(new Date(), "DD-MM-YYYY")
              .locale("en")
              .format("YYYY-MM-DD") +
              " " +
              "11:30:00",
            this.$moment(new Date(), "DD-MM-YYYY")
              .add(1, "days")
              .locale("en")
              .format("YYYY-MM-DD") +
              " " +
              "11:30:00",
          ],
    };
  },
  methods: {
    async getOrdersRats() {
      const loading = this.$vs.loading();
      try {
        let params = {
          page: this.page,
        }

        if(this.order_id){
          params.order_id = this.order_id
        }

        if(this.dateRange != null){

          localStorage.setItem("reportsInterval", JSON.stringify(this.dateRange));

          params.start = this.dateRange[0];
          params.end = this.dateRange[1];
        }
        
        const res = await axiosApi.get(`/order-comments`, {params});
        this.comments = res.data?.data?.collection;
        this.page = res.data?.data?.pageInfo?.currentPage;
        this.totalPages = res.data?.data?.pageInfo?.totalPages;
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users-comments{
    &__title{ 
        font-size: 21px;
    margin: 0;
    }
}
</style>
