let purchases = localStorage.getItem("purchases")
  ? JSON.parse(localStorage.getItem("purchases"))
  : [
      {
        inventory_id: 1,
        paied: 0,
        VAT: 0,
        supplier: null,
        total_cost: 0,
        total_buy_cost: 100,
        total_sale_cost: 0,
        total_qty: 1,
        total_discount: 0,
        percentage_discount: 0,
        products: [],
        notes: "",
      },
    ];
let returnedPurchases = localStorage.getItem("returnedPurchases")
  ? JSON.parse(localStorage.getItem("returnedPurchases"))
  : [
      {
        inventory_id: 1,
        paied: 0,
        VAT: 0,
        supplier: null,
        total_cost: 0,
        total_buy_cost: 100,
        total_sale_cost: 0,
        total_qty: 1,
        total_discount: 0,
        percentage_discount: 0,
        products: [],
        notes: "",
      },
    ];
let invoiceData = {
  qty: null,
  buyPrice: "-",
  discount: 0,
  product: null,
  priceStrategyId: null,
  selectedProductBuyCost: "-",
  supplier: null,
  lastPurchaseCost: "-",
  showQtyToolTip: false,
  showPriceToolTip: false,
  showBuyCostToolTip: false,
};

export default {
  state: {
    purchases: purchases,
    returnedPurchases: returnedPurchases,
    selectedPurchaseIndex: 0,
    invoiceData: invoiceData,
  },
  getters: {
    purchases: (state) => state.purchases,
    returnedPurchases: (state) => state.returnedPurchases,
    selectedPurchaseIndex: (state) => state.selectedPurchaseIndex,
    invoiceData: (state) => state.invoiceData,
  },
  mutations: {
    ADD_NEW_PURCHASES(state, payload) {
      state.purchases.push(payload);
    },
    ADD_NEW_Returned_PURCHASES(state, payload) {
      state.purchases.push(payload);
    },
    SET_SELECTED_PURCHASE_INDEX(state, payload) {
      state.selectedPurchaseIndex = payload;
    },
    INVOICE_DATA(state, payload) {
      state.invoiceData = payload;
    },
  },
  actions: {
    addNewPurchases({ commit }, type) {
      type === "purchases"
        ? commit("ADD_NEW_PURCHASES", {
            inventory_id: 1,
            paied: 0,
            VAT: 0,
            supplier: null,
            total_cost: 0,
            total_buy_cost: 100,
            total_sale_cost: 0,
            total_qty: 1,
            total_discount: 0,
            percentage_discount: 0,
            products: [],
            notes: "",
          })
        : commit("ADD_NEW_Returned_PURCHASES", {
            inventory_id: 1,
            paied: 0,
            VAT: 0,
            supplier: null,
            total_cost: 0,
            total_buy_cost: 100,
            total_sale_cost: 0,
            total_qty: 1,
            total_discount: 0,
            percentage_discount: 0,
            products: [],
            notes: "",
          });
    },
    setSelectedPurchaseIndex({ commit }, data) {
      commit("SET_SELECTED_PURCHASE_INDEX", data);
    },
    resetProduct({ dispatch, state }, data) {
      dispatch("INVOICE_DATA", { ...state.invoiceData, ...data });
      setTimeout(() => {
        document.querySelector(".productQuery input").focus();
      }, 200);
    },
    clearInvoiceData({ commit, state }) {
      let invoiceData = {
        qty: null,
        buyPrice: "-",
        discount: 0,
        product: null,
        priceStrategyId: null,
        selectedProductBuyCost: "-",
        supplier: state.invoiceData.supplier,
        lastPurchaseCost: "-",
        showQtyToolTip: false,
        showPriceToolTip: false,
        showBuyCostToolTip: false,
      };
      commit("INVOICE_DATA", invoiceData);
    },
  },
};
