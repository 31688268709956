<template>
  <b-row style="    max-height: 70vh;
    overflow-y: scroll;">
    <!-- <b-col md="12" lg="6" class="pb-0">
      <div class="d-flex">
        <b-col md="7"></b-col>
        <b-col md="5" style="display: flex; place-content: flex-end">
          <div class="size">S</div>
          <div class="size">M</div>
          <div class="size">L</div>
          <div class="size" v-if="sizes.length > 3">XL</div>
        </b-col>
      </div>
    </b-col>
    <b-col md="12" lg="6" class="d-lg-block d-none pb-0">
      <div class="d-flex">
        <b-col md="7"></b-col>
        <b-col md="5" style="display: flex; place-content: flex-end">
          <div class="size">S</div>
          <div class="size">M</div>
          <div class="size">L</div>
          <div class="size" v-if="sizes.length > 3">XL</div>
        </b-col>
      </div>
    </b-col> -->

    <b-col
      md="12"
      lg="6"
      v-for="(product, index) in products"
      :key="index"
      class="pt-0"
    >
      <div class="d-flex meunItem">
        <b-col md="6">
          {{ product.name }}
        </b-col>

        <div
          class="prices"
          v-if="product && product.prices && product.prices.length > 0"
        >
          <div
            v-if="
              product.prices.find((ele) => ele.product_size_id == sizes[0].id)
            "
            @click="
              addItem(
                product,
                product.prices.find((ele) => ele.product_size_id == sizes[0].id)
              )
            "
            class="price"
          >
          <span v-if="selectedOrderType.id==3">
  {{
              product.prices.find((ele) => ele.product_size_id == sizes[0].id)
                .sala_price
            }}
          </span>
          <span v-else>
              {{
              product.prices.find((ele) => ele.product_size_id == sizes[0].id)
                .price
            }}
          </span>
          
          </div>
          <div v-else class="price null-price">-</div>

          <div
            v-if="
              product.prices.find((ele) => ele.product_size_id == sizes[1].id)
            "
            @click="
              addItem(
                product,
                product.prices.find((ele) => ele.product_size_id == sizes[1].id)
              )
            "
            class="price"
          >
            {{
              product.prices.find((ele) => ele.product_size_id == sizes[1].id)
                .price
            }}
          </div>
          <div v-else class="price null-price">-</div>

          <div
            v-if="
              product.prices.find((ele) => ele.product_size_id == sizes[2].id)
            "
            @click="
              addItem(
                product,
                product.prices.find((ele) => ele.product_size_id == sizes[2].id)
              )
            "
            class="price"
          >
            {{
              product.prices.find((ele) => ele.product_size_id == sizes[2].id)
                .price
            }}
          </div>
          <div v-else class="price null-price">-</div>
          <template v-if="sizes.length > 3">
            <div
              v-if="
                product.prices.find((ele) => ele.product_size_id == sizes[3].id)
              "
              @click="
                addItem(
                  product,
                  product.prices.find(
                    (ele) => ele.product_size_id == sizes[3].id
                  )
                )
              "
              class="price"
            >
              {{
                product.prices.find((ele) => ele.product_size_id == sizes[3].id)
                  .price
              }}
            </div>
            <div v-else class="price null-price">-</div>
          </template>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    selectedOrderType:Object,
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sizes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    addItem(item, price) {
      this.$emit("addItem", item, price);
    },
  },
};
</script>
