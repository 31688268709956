import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const axiosApi = axios.create({
    // baseURL: `http://167.172.157.191:2090/api/`,
    // baseURL: `http://haven-production.test.shout-out.website/api/`,
    // baseURL: "http://haven-staging.test.shout-out.website/api/",
    // baseURL: `https://haven-test.mongz-cashier.com/api/`,
    // baseURL: "https://elbesh.mongz-cashier.com/api/",

    // baseURL: 'http://167.172.157.191:2090/api/',
    // baseURL: 'https://haven-pos.herokuapp.com/api/',
    // baseURL: `http://127.0.0.1:5050/api/`,
    baseURL: `https://rest-inventory.mongz-cashier.com/api/`,
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem('heavenDashboardToken')}`,
    }
})

Vue.use(VueAxios, axios)
export default axiosApi;