<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="8" :md="7" :xs="20" class="title">
          <h2>تحويل الخامات</h2>
        </el-col>
        <el-col :lg="8" :md="9">
          <div class="buttons-container">
            <el-button
              icon="el-icon-plus"
              class="plus"
              @click="handleAddPurchases"
            ></el-button>
            <el-button
              v-for="(invoice, index) in purchases"
              :key="index"
              :class="{
                'tab-btn': true,
                'active-tab': selectedIndex == index,
              }"
              @click="handleSetSelectedPurchaseIndex(index)"
              >{{ index + 1 }}</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" :gutter="10">
      <el-col :span="6">
        <div class="search-container">
          <el-select
            v-model="fromInventory"
            placeholder="تحويل من"
            @change="handleSelectFromInventory"
            id="fromInventory"
            :disabled="purchases[selectedIndex].materials.length > 0"
            clearable
          >
            <el-option
              v-for="branch in branchesFrom"
              :key="branch.id"
              :value="branch.id"
              :label="branch.name"
            ></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="search-container">
          <el-select
            v-model="toInventory"
            placeholder="تحويل الي"
            @change="handleSelectToInventory"
            id="toInventory"
            clearable
            :disabled="purchases[selectedIndex].materials.length > 0"
          >
            <el-option
              v-for="branch in branchesTo"
              :key="branch.id"
              :value="branch.id"
              :label="branch.name"
            ></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="8">
        <ProductSearch
          :allowCreateNewProduct="false"
          @addNewProduct="showAddModel"
          :showStockZero="false"
          ref="searchProductsComponent"
          :fromInventory="purchases[selectedIndex].fromInventory"
          :toInventory="purchases[selectedIndex].toInventory"
          @selectedProduct="selectedProduct"
          @productQueryChange="productQueryChange"
        />
      </el-col>
      <el-col :span="4">
        <div class="search-container">
          <el-popover
            :ref="`new-product-qty}`"
            placement="top"
            trigger="manual"
            :visible-arrow="true"
            content="الكمية غير صحيحة"
            v-model="invoiceData.showQtyToolTip"
            :offset="5"
            popper-class="bg-danger"
          >
          </el-popover>
          <input
            :class="invoiceData.showQtyToolTip ? 'error' : ''"
            class="form-control qty"
            type="number"
            @focus="$event.target.select()"
            v-model="invoiceData.qty"
            placeholder=" الكمية"
            min="0"
            v-popover="`new-product-qty}`"
            @keyup.enter="addNewItem"
            ref="searchFaturaComponent"
          />
        </div>
      </el-col>
    </el-row>
    <el-row class="table mt-2 px-3">
      <div
        class="el-table el-table--fit el-table--border el-table--scrollable-y el-table--enable-row-hover el-table--enable-row-transition invoice-table"
      >
        <div class="el-table__header-wrapper">
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
            class="el-table__header"
            style="width: 100%"
          >
            <colgroup>
              <template v-for="(column, index) in columns">
                <col
                  :key="index"
                  :name="`el-table_3_column_1${index + 1}`"
                  :width="column.width"
                  v-if="!column.hasCondition"
                />
                <col
                  :key="index"
                  :name="`el-table_3_column_1${index + 1}`"
                  :width="column.width"
                  v-if="column.hasCondition && column.condition"
                />
              </template>
            </colgroup>
            <thead class="has-gutter">
              <tr class="">
                <template v-for="(column, index) in columns">
                  <th
                    :key="index"
                    colspan="1"
                    rowspan="1"
                    :class="`el-table_3_column_1${index + 1} is-leaf`"
                    :width="column.width"
                    v-if="!column.hasCondition"
                  >
                    <div class="cell center">{{ column.name }}</div>
                  </th>
                  <th
                    :key="index"
                    colspan="1"
                    rowspan="1"
                    :class="`el-table_3_column_1${index + 1} is-leaf`"
                    :width="column.width"
                    v-if="column.hasCondition && column.condition"
                  >
                    <div class="cell center">{{ column.name }}</div>
                  </th>
                </template>
              </tr>
            </thead>
          </table>
        </div>
        <div
          class="el-table__body-wrapper"
          style="height: auto; max-height: 37vh"
          ref="products_table"
        >
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
            class="el-table__body"
            style="width: 100%"
          >
            <colgroup>
              <template v-for="(column, index) in columns">
                <col
                  :key="index"
                  :name="`el-table_3_column_1${index + 1}`"
                  :width="column.width"
                  v-if="!column.hasCondition"
                />
                <col
                  :key="index"
                  :name="`el-table_3_column_1${index + 1}`"
                  :width="column.width"
                  v-if="column.hasCondition && column.condition"
                />
              </template>
            </colgroup>
            <tbody class="bodytable">
              <tr
                class="el-table__row"
                v-for="(item, index) in purchases[selectedIndex].materials"
                :key="index + '2'"
                :class="item.stock < 0 ? 'bg-danger' : ''"
              >
                <td
                  rowspan="1"
                  colspan="1"
                  class="el-table_3_column_11"
                  data-label="#"
                >
                  <div class="cell num">{{ index + 1 }}</div>
                </td>
                <td
                  rowspan="1"
                  colspan="1"
                  class="el-table_3_column_12"
                  data-label="المنتج"
                >
                  <div class="cell">
                    {{ item.name }}
                  </div>
                </td>
                <td
                  rowspan="1"
                  colspan="1"
                  class="el-table_3_column_15"
                  data-label="الكمية"
                >
                  <div class="cell">
                    <el-popover
                      :ref="`${item.code}-${item.id}-qty`"
                      placement="left"
                      trigger="manual"
                      :content="item.toolTipMessage"
                      v-model="item.isValid"
                      popper-class="bg-danger"
                    >
                    </el-popover>
                    <input
                      class="form-control"
                      type="number"
                      @change="calcTotal"
                      @focus="$event.target.select()"
                      v-model="item.qty"
                      placeholder="الكمية"
                      min="0"
                      v-popover="`${item.code}-${item.id}-qty`"
                    />
                  </div>
                </td>
                <td
                  rowspan="1"
                  colspan="1"
                  class="el-table_3_column_12"
                  data-label="المنتج"
                >
                  <div class="cell">
                    {{ item.stock - item.qty }}
                  </div>
                </td>
                <td rowspan="1" colspan="1" class="el-table_3_column_23">
                  <div class="cell">
                    <el-popconfirm
                      confirm-button-text="موافق"
                      cancel-button-text="إلغاء"
                      icon="el-icon-info"
                      icon-color="red"
                      :title="'هل تريد حذف ' + ' ' + item.name + ' ؟'"
                      @confirm="deleteItem(item, index)"
                    >
                      <el-button
                        type="danger"
                        size="mini"
                        icon="el-icon-delete"
                        slot="reference"
                      >
                        <!-- <i class="fas fa-trash-alt"></i> -->
                      </el-button>
                    </el-popconfirm>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <a id="goToBottom" href="#scrollToMe"></a>
          <div id="scrollToMe"></div>
        </div>
      </div>
    </el-row>
    <el-row type="flex" :gutter="10" class="footer">
      <el-col :xl="7" :lg="7" :md="8" :sm="24">
        <div
          class="bg-White alignment p-3 mt-2"
          v-if="purchases[selectedIndex].materials.length > 0"
        >
          <p>
            الكمية
            <span class="num">{{ purchases[selectedIndex].total_qty }}</span>
          </p>
        </div>
      </el-col>
      <el-col :xl="9" :lg="9" :md="9" :sm="24">
        <div class="bg-White mt-2">
          <el-button
            v-show="purchases[selectedIndex].materials.length > 0"
            class="save"
            :loading="isLoading"
            @click="showPaymentModal"
            :disabled="disabled"
            >حفظ</el-button
          >
          <span
            :class="[
              purchases[selectedIndex].materials.length > 0 ? '' : 'mr-auto',
            ]"
            class="pl-3 d-mob-none"
          >
            <el-popconfirm
              confirm-button-text="موافق"
              cancel-button-text="إلغاء"
              icon="el-icon-info"
              icon-color="red"
              :title="`هل تريد مسح التحويل؟`"
              @confirm="resetInvoiceData"
            >
              <div slot="reference">
                <el-button class="exit mr-3">مسح التحويل</el-button>
              </div>
            </el-popconfirm></span
          >
        </div>
      </el-col>
    </el-row>
    <div v-if="confirmSave == true" class="dialog">
      <div class="bodySec">
        <p @click="() => (confirmSave = false)">
          <span><i class="far fa-times-circle"></i></span> اغلاق
        </p>
      </div>
      <div class="content">
        <el-button
          class="mt-3 btn-green1 btn-save"
          type="success"
          @click="addPurchase"
          >حفظ</el-button
        >
        <el-button
          type="danger"
          @click="() => (confirmSave = false)"
          class="exit mr-2"
          >الغاء</el-button
        >
      </div>

      <br />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axiosApi from "@/plugins/axios.js";
import ProductSearch from "@/components/Products/SearchMovement.vue";
import { movementsColumns as columns } from "@/components/invoices/types.js";

export default {
  name: "MaterialMovements",
  components: { ProductSearch },
  computed: {
    ...mapGetters(["invoiceData"]),
    branchesFrom() {
      return this.branchesList.filter(
        (branch) => branch.id !== this.toInventory
      );
    },
    branchesTo() {
      return this.branchesList.filter(
        (branch) => branch.id !== this.fromInventory
      );
    },
  },
  data() {
    return {
      purchases: localStorage.getItem("MaterialMovements")
        ? JSON.parse(localStorage.getItem("MaterialMovements"))
        : [
            {
              fromInventory: null,
              toInventory: null,
              materials: [],
            },
          ],
      selectedIndex: 0,
      product: null,
      columns,
      fromInventory: null,
      toInventory: null,
      productQuery: "",
      addProductFormVisible: false,
      isLoading: false,
      addFormVisible: false,
      productForm: {
        name: "",
        material_category_id: "",
        type: "",
      },
      rules: {
        name: [{ required: true, message: "الاسم مطلوب", trigger: "blur" }],
        material_category_id: [
          { required: true, message: "التصنيف مطلوب", trigger: "blur" },
        ],
        type: [{ required: true, message: "النوع مطلوب", trigger: "blur" }],
      },
      confirmSave: false,
      materialCategories: [],
      branchesList: [],
      disabled: false,
    };
  },
  mounted() {
    this.getMaterialCategories();
    this.getBranchesList();
  },
  methods: {
    ...mapActions([
      "addNewPurchases",
      "setSelectedPurchaseIndex",
      "resetProduct",
    ]),
    getBranchesList() {
      axiosApi.get("inventories").then((res) => {
        this.branchesList = res.data;
      });
    },
    handleSelectFromInventory(e) {
      const fromInventory = this.branchesList.filter(
        (branch) => branch.id === e
      )[0];
      this.purchases[this.selectedIndex].fromInventory = {
        id: fromInventory.id,
        name: fromInventory.name,
      };
    },
    handleSelectToInventory(e) {
      const toInventory = this.branchesList.filter(
        (branch) => branch.id === e
      )[0];
      this.purchases[this.selectedIndex].toInventory = {
        id: toInventory.id,
        name: toInventory.name,
      };
    },
    getMaterialCategories() {
      axiosApi.get("material_categories").then((res) => {
        this.materialCategories = res.data;
      });
    },
    showPaymentModal() {
      this.confirmSave = true;
    },
    handleAddPurchases() {
      this.purchases.push({
        fromInventory: {},
        toInventory: {},
        materials: [],
      });
      this.selectedIndex = this.purchases.length - 1;
    },
    handleSetSelectedPurchaseIndex(index) {
      this.selectedIndex = index;
    },
    selectedProduct(e) {
      this.product = e.product;
      this.invoiceData.product = e.product;
      this.productQuery = e.query;
      this.setProduct();
    },
    setProduct() {
      if (this.product != null) {
        setTimeout(() => {
          document.querySelector(".qty").focus();
        }, 200);
      }
    },
    showAddModel() {
      this.addProductFormVisible = true;
      setTimeout(() => {
        this.productForm.name = this.productQuery;
        // this.$refs.searchFaturaComponent.setValue(null, this.productQuery);
      }, 100);
    },
    productQueryChange(e) {
      this.productQuery = e.productQuery;
    },
    setLocalStorage() {
      localStorage.setItem("MaterialMovements", JSON.stringify(this.purchases));
    },
    addNewItem() {
      if (this.invoiceData.product != null && !this.isLoading) {
        if (this.invoiceData.qty > 0) {
          let id = this.invoiceData.product.id;
          var product = this.purchases[this.selectedIndex].materials.filter(
            function (elem) {
              if (elem.id == id) return elem;
            }
          );

          if (product.length > 0) {
            this.purchases[this.selectedIndex].materials.splice(
              this.purchases[this.selectedIndex].materials.findIndex(
                (e) => e.id == id
              ),
              1
            );
            this.purchases[this.selectedIndex].materials.push({
              id: product[0].id,
              name: product[0].name,
              qty: Number(product[0].qty) + Number(this.invoiceData.qty),
              stock: product[0].qty,
            });
            let invoiceData = {
              product: null,
              qty: null,
            };
            this.productQuery = "";
            this.resetProduct(invoiceData);
            this.$refs.searchProductsComponent.setValue(null);
            this.$forceUpdate();

            this.setLocalStorage();
            setTimeout(() => {
              this.calcTotal();
            }, 100);
          } else {
            this.purchases[this.selectedIndex].materials.push({
              id: this.invoiceData.product.id,
              name: this.invoiceData.product.name,
              qty: Number(this.invoiceData.qty),
              stock: this.product.qty,
            });
            this.setLocalStorage();
            setTimeout(() => {
              this.calcTotal();
            }, 100);
            this.invoiceData.qty = null;
            this.productQuery = "";
            this.resetProduct(this.invoiceData);
            this.$refs.searchProductsComponent.setValue(null);
            this.$forceUpdate();
          }
        }
      }
    },
    calcTotal() {
      var qty = 0;
      for (
        var i = 0;
        i < this.purchases[this.selectedIndex].materials.length;
        i++
      ) {
        this.$forceUpdate();
        if (this.purchases[this.selectedIndex].materials[i].qty == "") {
          this.purchases[this.selectedIndex].materials[i].qty = 1;
        }
        qty = qty + Number(this.purchases[this.selectedIndex].materials[i].qty);
      }
      this.checkQty();
      this.purchases[this.selectedIndex].total_qty = Number(qty);
      this.setLocalStorage();
    },
    checkQty() {
      for (
        var i = 0;
        i < this.purchases[this.selectedIndex].materials.length;
        i++
      ) {
        const material = this.purchases[this.selectedIndex].materials[i];
        if (Number(material.qty) == 0) {
          this.disabled = true;
          return 1;
        } else {
          this.disabled = false;
        }

        if (material.stock - material.qty < 0) {
          material.isValid = true;
          material.toolTipMessage = "الكمية المطلوبه اكبر من المخزون";
        } else {
          material.isValid = false;
        }

        if (material.qty == "") {
          material.qty = 1;
        }
      }
      this.disabled = this.purchases[this.selectedIndex].materials.some((material) => {
        console.log("🚀 ~ this.disabled=this.purchases[this.selectedIndex].materials.every ~ +material.stock - +material.qty < 0:", +material.stock - +material.qty < 0)
        return (+material.stock - +material.qty < 0);
      })
    },
    handleAddNewProduct() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: false,
            spinner: "el-icon-loading",
            background: "rgba(255,255,255,.7)",
            customClass: "fullscreen-loading",
          });
          axiosApi
            .post("materials", this.productForm)
            .then((res) => {
              this.$notify({
                title: "تمت العملية بنجاح",
                message: "تم حفظ بيانات العميل بنجاح",
                type: "success",
                duration: 1500,
              });
              this.selectedProduct({
                product: res.data,
              });
              this.invoiceData.product = res.data;
              this.addProductFormVisible = false;
            })
            .finally(() => {
              loading.close();
            });
        }
      });
    },
    deleteItem(product, index) {
      this.purchases[this.selectedIndex].materials.splice(index, 1);
      this.calcTotal();
      this.$forceUpdate();
    },
    addPurchase() {
      if (!this.isLoading) {
        if (this.purchases[this.selectedIndex].materials.length > 0) {
          this.isLoading = true;
          const loading = this.$loading({
            lock: true,
            text: "جاري تحميل البيانات",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          const purchasesData = {
            fromInventory: this.purchases[this.selectedIndex].fromInventory,
            toInventory: this.purchases[this.selectedIndex].toInventory,
            materials: this.purchases[this.selectedIndex].materials.map(
              (material) => {
                return {
                  id: material.id,
                  qty: material.qty,
                };
              }
            ),
          };
          axiosApi
            .post("inventory-movements", purchasesData)
            .then(() => {
              this.$notify.success({
                title: "تمت العملية بنجاح",
                message: "تم حفظ بيانات الفاتورة بنجاح",
                type: "success",
                duration: 1500,
              });
              this.confirmSave = false;
              this.resetInvoiceData();
            })
            .catch((err) => {
              if (err.response.status === 403) {
                this.$notify.error({
                  title: "حدث خطأ",
                  message: `عفوا لا يوجد رصيد || رصيد  ${err.response.data.balance}`,
                  duration: 1500,
                });
              } else {
                this.$notify.error({
                  title: "حدث خطأ",
                  message: "عفوا تأكد من بيانات الفاتورة",
                  duration: 1500,
                });
              }
            })
            .finally(() => {
              loading.close();
              this.isLoading = false;
            });
        }
      }
    },
    resetInvoiceData() {
      this.fromInventory = null;
      this.toInventory = null;
      if (this.purchases.length > 1) {
        this.purchases.splice(this.selectedIndex, 1);
        this.selectedIndex = 0;
      } else {
        this.purchases[this.selectedIndex].materials = [];
        this.purchases[this.selectedIndex].fromInventory = null;
        this.purchases[this.selectedIndex].toInventory = null;
        this.selectedIndex = 0;
      }
      setTimeout(() => {
        document.querySelector("#fromInventory").value = "";
        document.querySelector("#fromInventory").focus();
        document.querySelector("#toInventory").value = "";
        document.querySelector("#toInventory").focus();
      }, 100);
      this.setLocalStorage();
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #ebf9fc;
  border-radius: 6px;
  padding: 20px;
  padding-right: 13px;
  h2 {
    margin: 0;
  }
}
.buttons-container {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  .plus {
    border: none;
    margin-left: 10px;
    top: -25px;
    right: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 3px 3px #dddddd;
    color: #707070;
    font-size: 25px;
    padding: 0;
  }
  .tab-btn {
    background: #ebf9fc;
    padding: 0;
    border: none;
    color: #333;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin: 0 1px;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    outline: none;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
    }
    &.active-tab {
      background-color: #fe5634;
      color: #fff;
    }
  }
}
.table {
  background-color: #ebf9fc;
  padding: 10px;
  border-radius: 6px;
  .el-table {
    margin-top: 0px;
  }
}
.header-action {
  display: flex;
  .result {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #011547;
    font-family: Cairo;
    font-size: 16px;

    p {
      text-align: center;
      margin: 0;

      span {
        color: #011547;
        font-family: "Open Sans", sans-serif;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}
.el-table {
  margin-top: 20px;
  height: 56vh;
}
.d-mob,
.add-item-title {
  display: none;
}
.last-product-sale-table {
  width: 100%;
}
@media screen and (max-width: 600px) {
  * {
    font-size: 14px;
  }
  .el-table {
    height: auto;
  }
  .el-table__body-wrapper {
    margin-top: 10px;
  }
  .el-table__header-wrapper {
    display: none;
  }
  .el-table__footer,
  .el-table__body {
    display: block;
    tr {
      display: block;
      td {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: #fff !important;
        .cell {
          width: 100%;
        }
        &[data-label]::before {
          content: attr(data-label);
          width: 100%;
        }
        &[data-label="الكمية"],
        &[data-label="السعر"] {
          display: inline-block;
          width: 50%;
        }
        &[data-label="اخر سعر"],
        &[data-label="الاجمالي"],
        &[data-label="المحجوز"],
        &[data-label="المتاح"] {
          display: inline-block;
          width: calc(100% / 4);
        }
      }
    }
  }
  .el-table__body {
    tr {
      counter-increment: section;
      td {
        &[data-label="#"] {
          display: none;
        }
        &[data-label="المنتج"] {
          flex-wrap: nowrap;
        }
        &[data-label="المنتج"]::before {
          content: counter(section);
          width: fit-content;
        }
      }
    }
  }
  .d-mob-none {
    display: none !important;
  }
  .d-mob {
    display: block;
  }
  .add-item-title {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: bold;
    }
    button {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
  .item-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .last-product-sale-table {
    display: block;
    border: none;
    thead {
      display: none;
    }
    tbody {
      display: block;
      tr {
        display: block;
        td {
          display: flex;
          border: none;
          &[data-label]::before {
            content: attr(data-label);
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
