<template>
  <div>
    <div class="page-header">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :lg="6" :md="7" :xs="20" class="title">
          <h2>كارت التشغيله</h2>
        </el-col>
        <el-col :span="4">
          <el-button plain type="primary" @click="handleSubmitMaterial"
            >حفظ</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" :gutter="15">
      <el-col :span="8">
        <el-row type="flex" :gutter="15">
          <el-col :span="24">
            <div class="search-container">
              <el-select
                v-model="materialForm.material_id"
                placeholder="كارت التشغيله"
                value-key="value"
                :filterable="true"
                @change="handleChangeMaterial"
                clearable
                :remote="true"
                :remote-method="(e) => handleSearch(e, 2)"
              >
                <el-option
                  v-for="(item, index) in nativeMaterialList"
                  :key="`${item.id}-${index}`"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
                <el-option
                  v-if="nativeMaterialList.length === 0"
                  value="0"
                  label="اضافة خامة جديدة"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="search-container">
              <el-select
                v-model="materialName"
                placeholder="بحث الخامة"
                value-key="value"
                :filterable="true"
                @change="focusQty"
                ref="materialName"
                clearable
                :remote="true"
                :remote-method="(e) => handleSearch(e, 1)"
              >
                <el-option
                  v-for="(item, index) in compoundMaterialList"
                  :key="`${item.id}-${index}`"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
                <el-option
                  v-if="compoundMaterialList.length === 0"
                  value="0"
                  label="اضافة خامة جديدة"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="search-container">
              <el-input
                placeholder="الكمية"
                class="product-name"
                type="number"
                v-model="materialQty"
                ref="materialQty"
                @keypress.enter.native="addMaterial"
              />
            </div>
          </el-col>
          <el-col :span="4">
            <div class="search-container">
              <el-button @click="addMaterial" icon="el-icon-plus" type="primary" style="width: 100%;"></el-button>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="16">
        <el-table
          class="mt-2"
          align="right"
          :data="MaterialsList"
          border
          style="width: 100%"
          height="80vh"
        >
          <el-table-column label="#" type="index" width="50" />
          <el-table-column prop="name" label="اسم الخامة"> </el-table-column>
          <el-table-column prop="qty" label="الكمية" width="150">
            <template slot-scope="scope">
              <el-input type="number" v-model="scope.row.qty" />
            </template>
          </el-table-column>
          <el-table-column label="اجراءات" width="100">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="() => deleteMaterial(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
      title="تسجيل خامة جديد"
      :visible.sync="addProductFormVisible"
      v-if="productForm"
    >
      <el-form :model="productForm" :rules="rules" ref="form">
        <el-row type="flex" style="flex-wrap: wrap" :gutter="10">
          <el-col :xl="12" :lg="12" :md="6" :sm="12">
            <el-form-item label="الاسم " prop="name">
              <el-input
                class="formInput1"
                autofocus
                v-model="productForm.name"
                autocomplete="off"
                @keyup.enter.native="focusNext"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="التصنيف" prop="material_category_id">
              <el-select v-model="productForm.material_category_id">
                <el-option
                  v-for="category in materialCategories"
                  :key="category.id"
                  :value="category.id"
                  :label="category.name"
                  >{{ category.name }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="6" :md="12" :sm="12">
            <el-form-item label="النوع" prop="type">
              <el-select v-model="productForm.type">
                <el-option value="1" label="تامه">تامه</el-option>
                <el-option value="2" label="مصنعه">مصنعه</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="handleAddNewProduct"
          >تسجيل خامة
        </el-button>
        <el-button class="mr-3" @click="addProductFormVisible = false"
          >إلغاء</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "@/plugins/axios";
export default {
  name: "MaterialIngredients",
  data: () => {
    return {
      materialName: "",
      materialQty: "",
      materialList: [],
      MaterialsList: [],
      materialForm: {
        material_id: "",
        ingredients: [],
      },
      selectedMaterial: null,
      addProductFormVisible: false,
      productForm: {
        name: "",
        material_category_id: "",
        type: "",
      },
      rules: {
        name: [{ required: true, message: "الاسم مطلوب", trigger: "blur" }],
        material_category_id: [
          { required: true, message: "التصنيف مطلوب", trigger: "blur" },
        ],
        type: [{ required: true, message: "النوع مطلوب", trigger: "blur" }],
      },
      materialCategories: [],
      nativeMaterialList: [],
      compoundMaterialList: [],
    };
  },
  mounted() {
    this.getMaterialList();
    this.getMaterialCategories();
  },
  computed: {
    // nativeMaterialList() {
    //   return this.materialList.filter(material => material.type === 2)
    // },
    // compoundMaterialList() {
    //   return this.materialList.filter(material => material.type === 1)
    // }
  },
  methods: {
    handleSearch(e, type) {
      this.searchValue = e;
      if (e !== "") {
        axiosApi
          .get("materials/search", {
            params: { name: e, type, inventory_id: 1 },
          })
          .then((res) => {
            this.materialList = res.data;
            if (type == 2) {
              this.nativeMaterialList = res.data;
            } else {
              this.compoundMaterialList = res.data;
            }
          });
      } else {
        this.materialList = [];
        this.compoundMaterialList = [];
        this.nativeMaterialList = [];
      }
    },
    getMaterialList() {
      axiosApi.get("materials").then((res) => {
        this.materialList = res.data;
      });
    },
    getMaterialCategories() {
      axiosApi.get("material_categories").then((res) => {
        this.materialCategories = res.data;
      });
    },
    handleChangeMaterial(e) {
      if (e === "0") {
        this.addProductFormVisible = true;
        this.productForm.name = e;
      } else {
        if (e === "") {
          this.MaterialsList = []
        }
        const selectedMaterial = this.nativeMaterialList.filter((material) => {
          return material.id === this.materialForm.material_id;
        })[0];
        this.MaterialsList = selectedMaterial.ingredients.map((ingredient) => {
          return {
            id: ingredient.child_id,
            name: ingredient.child.name,
            qty: ingredient.qty,
          };
        });
      }
    },
    focusQty() {
      this.$refs.materialQty.focus();
      this.selectedMaterial = this.materialList.filter(
        (material) => material.id == this.materialName
      )[0];
    },
    addMaterial() {
      const materialIndex = this.MaterialsList.findIndex(
        (material) => material.id == this.materialName
      );
      if (materialIndex === -1) {
        this.MaterialsList.push({
          name: this.selectedMaterial.name,
          id: this.selectedMaterial.id,
          qty: this.materialQty,
        });
      } else {
        const qty = +this.MaterialsList[materialIndex].qty + +this.materialQty;
        this.MaterialsList.splice(materialIndex, 1, {
          ...this.MaterialsList[materialIndex],
          qty,
        });
      }
      this.materialName = "";
      this.materialQty = "";
      this.$refs.materialName.focus();
    },
    deleteMaterial(index) {
      this.MaterialsList.splice(index, 1);
    },
    handleSubmitMaterial() {
      this.materialForm.ingredients = this.MaterialsList.map((material) => {
        return {
          material_id: material.id,
          qty: material.qty,
        };
      });
      if (
        this.materialForm.material_id &&
        this.materialForm.ingredients.length > 0
      ) {
        const loading = this.$loading({
          lock: true,
          text: false,
          spinner: "el-icon-loading",
          background: "rgba(255,255,255,.7)",
          customClass: "fullscreen-loading",
        });

        axiosApi
          .post("material-ingredients", this.materialForm)
          .then(() => {
            this.materialForm.material_id = "";
            this.materialForm.ingredients = [];
            this.MaterialsList = [];
            this.$notify({
              title: "تمت العملية بنجاح",
              message: "تم حفظ بيانات العميل بنجاح",
              type: "success",
              duration: 1500,
            });
          })
          .finally(() => {
            loading.close();
          });
      }
    },
    handleAddNewProduct() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: false,
            spinner: "el-icon-loading",
            background: "rgba(255,255,255,.7)",
            customClass: "fullscreen-loading",
          });
          axiosApi
            .post("materials", this.productForm)
            .then((res) => {
              this.$notify({
                title: "تمت العملية بنجاح",
                message: "تم حفظ بيانات العميل بنجاح",
                type: "success",
                duration: 1500,
              });
              this.addProductFormVisible = false;
              this.materialList.push(res.data);
              this.selectedMaterial = res.data;
              this.materialForm.material_id = res.data.id;
            })
            .finally(() => {
              loading.close();
            });
        }
      });
    },
  },
};
</script>
